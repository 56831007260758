const SUPPORT_TYPE = {
  LEAD: {
    code: '1',
    desc: '데이터 경제 기반의 디지털 전환 생태계 조성',
  },
  COLLABO: {
    code: '2',
    desc: '공공 민간 데이터 활용 활성화 지원',
  },
  PRACTICE: {
    code: '3',
    desc: '실무 중심의 융합형 데이터 인재 양성',
  },
  INNOVATION: {
    code: '4',
    desc: '데이터 산업 혁신성장 기반 강화',
  },
};

export default SUPPORT_TYPE;
