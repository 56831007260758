/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-extra-boolean-cast */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  modalType: '',
  modalTitle: '',
  modalBody: '',
  data: {},
  closeEvent: undefined,
  confirmEvent: undefined,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, actions) => {
      const {
        modalType,
        modalTitle,
        modalBody,
        data,
        closeEvent,
        confirmEvent,
      } = actions.payload;

      state.modalType = modalType;
      state.modalTitle = modalTitle;
      state.modalBody = modalBody;
      state.data = data;
      state.closeEvent = closeEvent;
      state.confirmEvent = confirmEvent;
      state.isOpen = true;
    },
    closeModal: (state, actions) => {
      // Event 실행
      if (actions.payload?.closeEvent) {
        actions.payload.closeEvent(); // 추가 설정
      } else {
        state?.closeEvent && state.closeEvent(); // 기본 설정
      }

      state.isOpen = state.data?.hasNext || false; // 다음 모달이 있을 경우
      state.modalType = initialState.modalType;
      state.modalTitle = initialState.modalTitle;
      state.modalBody = initialState.modalBody;
      state.data = initialState.data;
      state.closeEvent = initialState.closeEvent; // Event 초기화
      state.confirmEvent = initialState.confirmEvent;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
