import api from '../utils/Api';

// API Prefix
const baseURL = process.env.REACT_APP_API_URL;
const API_VERSION = 'api/v1';
const API_PREFIX = `${baseURL}${API_VERSION}`;

// API Type
const API_AUTH = `${API_PREFIX}/auth`;
const API_USER = `${API_PREFIX}/user`;
const API_HOME = `${API_PREFIX}/home`;

const API_COMMON = `${API_PREFIX}/common`;
const API_BIGDATA = `${API_PREFIX}/bigdata`;
const API_BOARD_PHOTO = `${API_PREFIX}/board-photo`;
const API_BOARD_SUPPORT = `${API_VERSION}/board-support`;
const API_BOARD_NOTICE = `${API_PREFIX}/board-notice`;
const API_BOARD_BUSINESS = `${API_PREFIX}/board-business`;
const API_BOARD_NEWSLETTER = `${API_PREFIX}/board-news`;
const API_BOARD_QNA = `${API_PREFIX}/board-qna`;
const API_BOARD_LIBRARY = `${API_PREFIX}/board-library`;
const API_BOARD_CASE = `${API_PREFIX}/board-case`;
const API_BOARD_REPORT = `${API_VERSION}/board-report`;

const API_APPLY_NEWSLETTER = `${API_VERSION}/apply-newsletter`;
const API_APPLY_BUSINESS = `${API_VERSION}/apply-business`;

const API_PAGE_POPUP = `${API_VERSION}/page-popup`;
const API_PAGE_CHART = `${API_VERSION}/page-chart`;
const API_PAGE_SOCIAL = `${API_VERSION}/page-social`;

// Request > Headers > multipart/form-data
export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// ----------------------------------------------------------
//  공통 / 코드 조회
// ----------------------------------------------------------

// 파일 다운로드(로컬)
export const getDownloadFile = data => {
  return api.get(`${API_COMMON}/files/download`, {
    params: data,
    responseType: 'blob',
  });
};
export const apiGetFloatList = () => {
  return api.get(`${API_COMMON}/client-float`);
};

// ----------------------------------------------------------
// [ AUTH ] 회원인증
//  - 00* : 로그인, 회원가입 - SNS 연동 ( GOOGLE, KAKAO )
//  - 01* : 토큰
// ----------------------------------------------------------

// GDHC_IF_001	로그인
export const apiLoginGoogleCallback = code => {
  return api.get(`${API_AUTH}/google-callback`, { params: { code } });
};

// GDHC_IF_002	로그인
export const apiLoginKakaoCallback = code => {
  return api.get(`${API_AUTH}/kakao-callback`, { params: { code } });
};

// GDHC_IF_010	Access Token 재발급
export const apiUpdateAccessToken = data => {
  return api.get(`${API_AUTH}/access-token`);
};

// USER_IF_001 유저 로그인
export const apiUserLogin = data => {
  return api.post(`${API_USER}/login`, data);
};

// USER_IF_002 아이디 중복체크
export const apiDuplicateUserId = data => {
  return api.post(`${API_USER}/duplicate`, data);
};
// USER_IF_003 인증메일 보내기
export const apiSendCertEmail = data => {
  return api.post(`${API_USER}/certificate-request`, data);
};

// USER_IF_004 회원가입
export const apiUserRegister = data => {
  return api.post(`${API_USER}/register`, data);
};

// USER_IF_005 비밀번호 변경 (로그인 중)
export const apiChangePassword = data => {
  return api.patch(`${API_USER}/password`, data);
};

// USER_IF_006 비밀번호 찾기 재설정 (인증받은 계정)
export const apiResetPassword = data => {
  return api.patch(`${API_USER}/reset-password`, data);
};

// USER_IF_007 이메일 인증번호 인증하기
export const apiConfirmCertEmail = data => {
  return api.post(`${API_USER}/certificate-confirm`, data);
};

// USER_IF_008 이메일 인증번호 인증하기
export const apiCallFindId = data => {
  return api.get(`${API_USER}/find-id`, { params: data });
};

// USER_IF_009 유저 내 정보
export const apiGetUserMyInfo = () => {
  return api.get(`${API_USER}/my-info`);
};

// USER_IF_010 유저 내 정보 수정
export const apiModifyUserMyInfo = data => {
  return api.put(`${API_USER}/my-info`, data);
};

// USER_IF_013 유저 이용신청 내역 리스트
export const apiGetUserApplyList = data => {
  return api.get(`${API_USER}/apply-list`, { params: data });
};

// USER_IF_014 사업공고 지원신청내역
export const apiGetBusinessyList = data => {
  return api.get(`${API_USER}/business-list`, { params: data });
};
// ----------------------------------------------------------
// [ HOME ] 홈
//  - 1** : 메인 페이지 관련 정보 불러오기
// ----------------------------------------------------------

// COMMON_IF_093 - 타입 리스트
export const apiGetBigdataTypesInfo = () => {
  return api.get(`${API_COMMON}/bigdata-types-info`);
};

// COMMON_IF_094 - 상위 뷰 빅데이터 클래스 Top5 리스트
export const apiGetFavoriteBigdataClass = () => {
  return api.get(`${API_COMMON}/favorite-bigdata-class`);
};

// COMMON_IF_095 - 메인페이지 정보
export const apiGetMainPageObject = () => {
  return api.get(`${API_COMMON}/client-main`);
};

// BIGDATA_IF_100 - 기초 정보
export const apiGetBigdataList = data => {
  return api.get(`${API_BIGDATA}/list`, { params: data });
};

// NOTICE_IF_100 - 공지사항 리스트
export const apiGetNoticeList = data => {
  return api.get(`${API_BOARD_NOTICE}/list`, { params: data });
};

// ----------------------------------------------------------
// [ BIGDATA ] 빅데이터 관련 api
// ----------------------------------------------------------

// BIGDATA_IF_111 - 빅데이타 샘플 리스트 CSV 파일 다운로드
export const apiDownloadBigDataSampleList = data => {
  return api.post(`${API_BIGDATA}/view/sample-download`, data, {
    responseType: 'blob',
  });
};

// BIGDATA_IF_110 - 빅데이타 샘플 리스트 조회
export const apiGetBigDataSampleList = data => {
  return api.post(`${API_BIGDATA}/view/sample`, data);
};

// USER_IF_011 빅데이터 이용 신청하기
export const apiUserApplyUseBigdata = data => {
  return api.post(`${API_USER}/apply-bigdata`, data);
};

// 공휴일 리스트 조회 - COMMON_IF_097
export const getHolidayList = () => {
  return api.get(`${API_COMMON}/holidays`);
};

// USER_IF_012 QnA 작성하기
export const apiUserWriteQna = data => {
  return api.post(`${API_USER}/write-qna`, data);
};

// 빅데이터 유형 조회
export const getBigdataTypeList = () => {
  return api.get(`${API_COMMON}/bigdata-types`);
};

// BIGDATA_IF_100 - 빅데이타 리스트 조회
export const getBigDataList = data => {
  return api.get(`${API_BIGDATA}/list`, { params: data });
};
// ----------------------------------------------------------
// [ BOARD ] 게시판 관련 api
// ----------------------------------------------------------

// QnA_IF_100 - Q&A 리스트 조회
export const apiGetQnaList = data => {
  return api.get(`${API_BOARD_QNA}/list`, { params: data });
};

// QnA_IF_100 - Q&A 리스트 조회
export const apiGetQnaDetail = (qnaIdx, data) => {
  return api.get(`${API_BOARD_QNA}/view/${qnaIdx}`, { params: data });
};

// ==========================================================

// NOTICE_IF_101 - 공지사항 상세 조회
export const apiGetNoticeDetail = noticeIdx => {
  return api.get(`${API_BOARD_NOTICE}/view/${noticeIdx}`);
};

// NOTICE_IF_107 - 공지사항 조회수 올리기
export const apiCountUpNotice = libraryIdx => {
  return api.patch(`${API_BOARD_NOTICE}/count/${libraryIdx}`);
};

// ==========================================================

// LIBRARY_IF_100 - 자료실 리스트
export const apiGetLibraryList = data => {
  return api.get(`${API_BOARD_LIBRARY}/list`, { params: data });
};

// LIBRARY_IF_101 - 자료실 상세 조회
export const apiGetLibraryDetail = libraryIdx => {
  return api.get(`${API_BOARD_LIBRARY}/view/${libraryIdx}`);
};

// LIBRARY_IF_107 - 자료실 조회수 올리기
export const apiCountUpLibrary = libraryIdx => {
  return api.patch(`${API_BOARD_LIBRARY}/count/${libraryIdx}`);
};

// ==========================================================

// SUPPORT_IF_100 - 지원사업 리스트 조회
export const apiGetSupportList = data => {
  return api.get(`${API_BOARD_SUPPORT}/list`, { params: data });
};

// SUPPORT_IF_101 - 지원사업 상세 조회
export const apiGetSupportDetail = supportIdx => {
  return api.get(`${API_BOARD_SUPPORT}/view/${supportIdx}`);
};

// SUPPORT_IF_107 - 지원사업 조회수 올리기
export const apiSupportViewCount = supportIdx => {
  return api.patch(`${API_BOARD_SUPPORT}/count/${supportIdx}`);
};

// ==========================================================

// REPORT_IF_100 - 보도 자료 리스트
export const apiGetReportList = data => {
  return api.get(`${API_BOARD_REPORT}/list`, { params: data });
};

// REPORT_IF_101 - 보도 자료 상세 조회
export const apiGetReportDetail = boardIdx => {
  return api.get(`${API_BOARD_REPORT}/view/${boardIdx}`);
};

// REPORT_IF_105 - 보도 자료 조회수 올리기
export const apiReportViewCount = boardIdx => {
  return api.patch(`${API_BOARD_REPORT}/count/${boardIdx}`);
};

// ==========================================================

// NEWS_IF_100 - 뉴스레터 리스트
export const apiGetNewsLetterList = data => {
  return api.get(`${API_BOARD_NEWSLETTER}/list`, { params: data });
};

// NEWS_IF_101 - 뉴스레터 상세 조회
export const apiGetNewsLetterDetail = newsIdx => {
  return api.get(`${API_BOARD_NEWSLETTER}/view/${newsIdx}`);
};

// NEWS_IF_105 - 뉴스레터 조회수 올리기
export const apiNewsLetterViewCount = newsIdx => {
  return api.patch(`${API_BOARD_NEWSLETTER}/count/${newsIdx}`);
};

// ==========================================================

// PHOTO_IF_100 - 포토 뉴스 리스트
export const apiGetPhotoNewsList = data => {
  return api.get(`${API_BOARD_PHOTO}/list`, { params: data });
};

// PHOTO_IF_101 - 포토 뉴스 상세조회
export const apiGetPhotoNewsDetail = photoIdx => {
  return api.get(`${API_BOARD_PHOTO}/view/${photoIdx}`);
};

// PHOTO_IF_105 - 포토 뉴스 조회수 올리기
export const apiPhotoViewCount = photoIdx => {
  return api.patch(`${API_BOARD_PHOTO}/count/${photoIdx}`);
};

// ==========================================================

// BUSINESS_IF_100 - 사업공고 리스트
export const apiGetBusinessList = data => {
  return api.get(`${API_BOARD_BUSINESS}/list`, { params: data });
};

// BUSINESS_IF_101 - 사업공고 상세 조회
export const apiGetBusinessDetail = boardIdx => {
  return api.get(`${API_BOARD_BUSINESS}/view/${boardIdx}`);
};

// BUSINESS_IF_107 - 사업공고 조회수 올리기
export const apiBusinessViewCount = boardIdx => {
  return api.patch(`${API_BOARD_BUSINESS}/count/${boardIdx}`);
};
// ==========================================================

// APPLY_IF_403 - 사업공고 지원 신청
export const apiSaveBusinessApply = data => {
  return api.post(`${API_APPLY_BUSINESS}`, data, formDataConfig);
};

// APPLY_IF_403 - 사업공고 지원 신청
export const apiCheckBusinessApply = businessIdx => {
  return api.get(`${API_APPLY_BUSINESS}/check/${businessIdx}`);
};

// ==========================================================
// USECASE_IF_100 - 활용사례 리스트 조회
export const apiGetUseCaseList = data => {
  return api.get(`${API_BOARD_CASE}/list`, { params: data });
};

// USECASE_IF_101 - 활용사례 상세 조회
export const apiGetUseCaseDetail = useCaseIdx => {
  return api.get(`${API_BOARD_CASE}/view/${useCaseIdx}`);
};

// USECASE_IF_107 - 활용사례 조회수 올리기
export const apiUseCaseViewCount = useCaseIdx => {
  return api.patch(`${API_BOARD_CASE}/count/${useCaseIdx}`);
};

// ==========================================================
// APPLY_IF_302 - 뉴스레터 구독신청
export const apiSaveNewsLetterApply = data => {
  return api.post(`${API_APPLY_NEWSLETTER}/sub/apply`, data);
};

// APPLY_IF_303 - 뉴스레터 수신거부
export const apiRemoveNewsLetterApply = data => {
  return api.post(`${API_APPLY_NEWSLETTER}/sub/deny`, data);
};
// ==========================================================
// PAGE_IF_101 - 팝업 리스트 조회
export const apiGetPopupList = data => {
  return api.get(`${API_PAGE_POPUP}/list`, { params: data });
};

// ==========================================================
// PAGE_IF_201 - 조직도 리스트 조회
export const apiGetChartList = data => {
  return api.get(`${API_PAGE_CHART}/list`, { params: data });
};

// PAGE_IF_202 - 조직도 상세조회
export const apiGetChartDetail = chartIdx => {
  return api.get(`${API_PAGE_CHART}/view/${chartIdx}`);
};
// ==========================================================
// PAGE_IF_501 - 소셜패널 리스트 조회
export const apiGetSocialList = () => {
  return api.get(`${API_PAGE_SOCIAL}/list`);
};
