/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = 'ko';

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      return action.payload;
    },
    removeAuth: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const languageAction = languageSlice.actions;

export default languageSlice.reducer;
