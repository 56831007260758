// eslint-disable jsx-a11y/alt-text

import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper/bundle';
import { useNavigate } from 'react-router-dom';
import { images } from '../assets';
import { RouterPath } from '../common/constants';

function BannerSlider02({ list }) {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [listState, setListState] = useState(null);

  useEffect(() => {
    if (listState && sliderRef?.current) {
      const swiper = new Swiper(sliderRef.current, {
        autoplay: false,
        slidesPerView: 7,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next02',
          prevEl: '.swiper-button-prev02',
        },
        breakpoints: {
          1024: {
            slidesPerView: 7,
          },
          765: {
            slidesPerView: 4,
          },
          640: {
            slidesPerView: 4,
          },
          568: {
            slidesPerView: 4,
          },
          465: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 2,
          },
        },
      });
    }
  }, [list]);

  useEffect(() => {
    if (list) {
      setListState(list);
    }
  }, [list]);

  return (
    <div className="slide_type02">
      <div ref={sliderRef} className="slider_item02 swiper-container">
        <div className="swiper-wrapper">
          {/* Slides */}
          {/* Note: You can map through your slide data here if it's dynamic */}
          {list?.map((item, index) => (
            <div
              key={`bigdata_types_info_slide_${index}`}
              className="swiper-slide"
            >
              <div className="item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  onClick={() =>
                    navigate(RouterPath.bigdataList, {
                      state: {
                        dataClass: item?.typeName,
                        page: 1,
                      },
                    })
                  }
                >
                  <div className="f01">
                    <div className="p01">
                      <img
                        src={
                          (item?.typeName === '교통' && images.mQuickIco04) ||
                          (item?.typeName === '인구' && images.mQuickIco01) ||
                          (item?.typeName === '부동산' && images.estate) ||
                          (item?.typeName === '상권' && images.mQuickIco06) ||
                          (item?.typeName === '관광' && images.mQuickIco07) ||
                          (item?.typeName === '지역화폐' &&
                            images.mQuickIco03) ||
                          (item?.typeName === '차량등록' && images.taxi) ||
                          (item?.typeName === '건축물' && images.mQuickIco05) ||
                          (item?.typeName === '유료도로' && images.road) ||
                          (item?.typeName === '유동인구' && images.chart) ||
                          (item?.typeName === '소비' && images.salesSlide) ||
                          (item?.typeName === '개인신용' &&
                            images.creditCard) ||
                          (item?.typeName === '기업신용' && images.enterprise)
                        }
                        alt={`Icon ${index}`}
                      />
                    </div>
                  </div>
                  <div className="tg01">
                    <div className="t01">{item?.typeName}</div>
                    <div className="t02">{item?.count} (건)</div>
                  </div>
                </a>
              </div>
            </div>
          ))}
          {/* Add more slides here */}
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className="ar_w02">
        <div className="swiper-button-next swiper-button-next02" />
        <div className="swiper-button-prev swiper-button-prev02" />
      </div>
    </div>
  );
}

export default BannerSlider02;
