import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { images } from '../../assets';
import { RouterPath } from '../../common/constants';
import { CustomSwal } from '../../components';

function Application() {
  const navigate = useNavigate();
  const storageToken = JSON.parse(localStorage.getItem('auth'));
  const [width, setWidth] = React.useState(window.innerWidth);

  const applyHandle = () => {
    // console.log('storage token :::: ', storageToken);
    if (
      storageToken &&
      storageToken.accessToken &&
      storageToken.grade === 'USER'
    )
      navigate(RouterPath.dataApply, { state: false });
    else
      CustomSwal.fire({
        title: '안내',
        html: `데이터 이용신청은 로그인 후에 이용하실 수 있습니다. <br> 로그인 페이지로 이동합니다.`,
        then: () => {
          navigate(RouterPath.login);
        },
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      renderUI(window.innerWidth);
    };

    // Call handleResize directly so we can check the initial window size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // eslint-disable-next-line no-shadow
  const renderUI = width => {
    if (width < 768) {
      return (
        <div className="box_application">
          <div className="step_box">
            <img src={images.application} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 01
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">이용신청</div>
              <div>
                회원가입
                <br />
                데이터 검색
                <br />
                이용 신청서 작성
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.booking} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 02
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">이용예약</div>
              <div>
                방문하고자 하는 일정
                <br />
                데이터 활용목적 기재
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.building} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 03
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">혁신센터 방문</div>
              <div>
                데이터와 솔루션을 사용하여
                <br />
                데이터 분석 및 처리 후 반출신청
              </div>
              <div>
                데이터 분석자원
                <br />
                (태블로, 파이썬, R, QGIS)
                <br />
                이용가능
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.audit} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 04
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">검토 및 심사</div>
              <div>
                미개방데이터 제공기관
                <br />
                (부산시 등)에 <br />
                심의의뢰 후 반출여부 결정
              </div>
            </div>
          </div>

          <div className="progress_step_column" />
        </div>
      );
    }
    return (
      <div className="box_application">
        <div className="step_box">
          <img src={images.application} alt="application" />
          <div className="step_txt_001">STEP 01</div>
          <div className="step_txt_002">이용신청</div>
          <div>
            회원가입
            <br />
            데이터 검색
            <br />
            이용 신청서 작성
          </div>
        </div>
        <div className="step_box">
          <img src={images.booking} alt="application" />
          <div className="step_txt_001">STEP 02</div>
          <div className="step_txt_002">이용예약</div>
          <div>
            방문하고자 하는 일정
            <br />
            데이터 활용목적 기재
          </div>
        </div>
        <div className="step_box">
          <img src={images.building} alt="application" />
          <div className="step_txt_001">STEP 03</div>
          <div className="step_txt_002">혁신센터 방문</div>
          <div>
            데이터와 솔루션을 사용하여
            <br />
            데이터 분석 및 처리 후 반출신청
          </div>
          <br />
          <div>
            데이터 분석자원
            <br />
            (태블로, 파이썬, R, QGIS)
            <br />
            이용가능
          </div>
        </div>
        <div className="step_box">
          <img src={images.audit} alt="application" />
          <div className="step_txt_001">STEP 04</div>
          <div className="step_txt_002">검토 및 심사</div>
          <div>
            미개방데이터 제공기관
            <br />
            (부산시 등)에 <br />
            심의의뢰 후 반출여부 결정
          </div>
        </div>
        <div className="progress_step" />
      </div>
    );
  };

  return (
    <>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.application}
        // isSearch
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>이용 신청</p>
            <div className="divider_title" />
          </div>

          {renderUI(width)}

          <button
            onClick={() => applyHandle()}
            className="btn_operation"
            type="button"
          >
            이용신청
          </button>
        </div>
      </section>
    </>
  );
}

export default Application;
