import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import { getDownloadFile } from '../api/RestAPI';

const IMAGE_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'tiff',
  'bmp',
  'webp',
  'svg',
  'ico',
  'tif',
  'raw',
];
const VIDEO_EXTENSIONS = ['mp4', 'mov', 'avi', 'wmv', 'mkv', 'flv', 'webm'];

function BannerSlider({ detail }) {
  const sliderRef = useRef(null);
  const [fileURLs, setFileURLs] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [youtubeUrls, setYoutubeUrls] = useState([]);

  const [youtubeId, setYoutubeId] = useState('');

  const restartVideo = index => {
    const videoElement = document.getElementById(`videoTag${index}`);
    if (videoElement) {
      videoElement.currentTime = 0;
      videoElement.play();
    }
  };

  useEffect(() => {
    if (detail && detail.files) {
      const tempFileInfos = [...detail.files].reverse(); // Reverse the array to maintain the order
      setFileInfos(tempFileInfos);

      const fetchPromises = tempFileInfos.map(fileInfo => {
        return fetchFile(
          `${fileInfo.uploadedFilePath}${fileInfo.storageFileName}`,
        );
      });

      Promise.all(fetchPromises).then(urls => {
        setFileURLs(urls);
      });
    }

    if (detail && detail.youtubeUrls) {
      const urls = detail.youtubeUrls.split(',').map(url => {
        const videoId = url.split('v=')[1];
        setYoutubeId(videoId);
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
          return `https://www.youtube.com/embed/${videoId.substring(
            0,
            ampersandPosition,
          )}`;
        }
        return `https://www.youtube.com/embed/${videoId}`;
      });
      setYoutubeUrls(urls);
    }
  }, [detail]);

  const fetchFile = async fileUrl => {
    if (!fileUrl) {
      return null;
    }

    const params = { filePath: fileUrl };

    try {
      const response = await getDownloadFile(params);
      const objectURL = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers['content-type'] }),
      );
      return objectURL;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    let swiper;
    if (sliderRef.current && (fileURLs.length || youtubeUrls.length)) {
      swiper = new Swiper(sliderRef.current, {
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next01',
          prevEl: '.swiper-button-prev01',
        },
      });
    }

    return () => {
      if (swiper) {
        swiper.destroy();
      }
    };
  }, [fileURLs, youtubeUrls, detail]);

  // useEffect(() => {
  //   console.log(youtubeUrls);
  // }, [youtubeUrls]);
  return (
    <div className="bn_box">
      <div className="slide_type01">
        <div ref={sliderRef} className="slider_item01 swiper-container">
          <div className="swiper-wrapper">
            {youtubeUrls.map((url, index) => (
              <div className="swiper-slide" key={`youtube-${index}`}>
                <div className="item">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="#none">
                    <div className="f01">
                      <div className="p01">
                        <iframe
                          width="100%"
                          height="100%"
                          src={`${url}?autoplay=1&mute=1&loop=1&playlist=${youtubeId}`}
                          title={`YouTube video player ${url}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
            {fileURLs.map((fileUrl, index) => {
              const fileExtension =
                fileInfos[index] && fileInfos[index].uploadedFileExtension;
              const isImage = IMAGE_EXTENSIONS.includes(fileExtension);
              const isVideo = VIDEO_EXTENSIONS.includes(fileExtension);

              return (
                <div className="swiper-slide" key={`file-${index}`}>
                  {isImage && (
                    <div className="item">
                      <a href="#none">
                        <div className="f01">
                          <div className="p01">
                            <img src={fileUrl} alt={fileExtension} />
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                  {isVideo && (
                    <div className="item">
                      <a href="#none">
                        <div className="f01">
                          <div className="p01">
                            <video
                              muted
                              autoPlay
                              loop
                              controls
                              id={`videoTag${index}`}
                              onEnded={() => restartVideo(index)}
                            >
                              <source
                                src={fileUrl}
                                type={`video/${fileExtension}`}
                              />
                              <strong>
                                Your browser does not support the video tag.
                              </strong>
                            </video>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="ar_w01">
          <div className="swiper-button-next swiper-button-next01" />
          <div className="swiper-button-prev swiper-button-prev01" />
        </div>
      </div>
    </div>
  );
}

export default BannerSlider;
