import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiDuplicateUserId, apiUserRegister } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import CustomSwal from '../../components/CustomSwal';
import NullCheckUtils from '../../utils/NullCheckUtils';
import { RouterPath, Utils } from '../../common';

function SignUp() {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [dept, setDept] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const inputIdChange = e => {
    const inputText = e.target.value;

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '아이디는 100자까지만 입력할 수 있습니다.',
        size: 100,
      })
    ) {
      setId(inputText);
      setDuplicate(false);
    }
  };

  const duplicateId = e => {
    const data = {
      loginId: id,
    };

    apiDuplicateUserId(data)
      .then(response => {
        setDuplicate(true);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const inputPasswordChange = e => {
    const inputText = e.target.value;

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '비밀번호는 15자까지만 입력할 수 있습니다.',
        size: 15,
      })
    ) {
      setPassword(inputText);
    }
  };

  const inputPasswordConfChange = e => {
    const inputText = e.target.value;

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '비밀번호는 15자까지만 입력할 수 있습니다.',
        size: 15,
      })
    ) {
      setPasswordConf(inputText);
    }
  };

  const inputDeptChange = e => {
    const inputText = e.target.value;

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '회사명은 100자까지만 입력할 수 있습니다.',
        size: 100,
      })
    ) {
      setDept(inputText);
    }
  };

  const inputNameChange = e => {
    const inputText = e.target.value;

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '이름은 100자까지만 입력할 수 있습니다.',
        size: 100,
      })
    ) {
      setName(inputText);
    }
  };

  const inputEmailChange = e => {
    const inputText = e.target.value;

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '인증용 이메일은 100자까지만 입력할 수 있습니다.',
        size: 100,
      })
    ) {
      setEmail(inputText);
    }
  };

  const inputPhoneChange = e => {
    const inputText = e.target.value;
    if (inputText && !Utils.isNumber(inputText)) {
      CustomSwal.fire({
        title: '확인',
        text: '숫자만 입력하실 수 있습니다.',
      });
      return;
    }

    if (
      !NullCheckUtils.isLimitAndDisplaySwal({
        state: inputText,
        message: '전화번호 11자까지만 입력할 수 있습니다.',
        size: 11,
      })
    ) {
      setPhoneNo(inputText);
    }
  };

  const registerUser = () => {
    if (password !== passwordConf) {
      CustomSwal.fire({
        title: '확인',
        text: '비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      });
      return;
    }
    const data = {
      userLoginId: id,
      password: passwordConf,
      userLoginType: 'EMAIL',
      userDept: dept,
      userName: name,
      userEmail: email,
      userTel: phoneNo,
    };
    const dataField = [
      { state: data.userLoginId, message: '로그인 ID를 입력해 주세요.' },
      { state: data.password, message: '비밀번호를 입력해 주세요.' },
      { state: data.userDept, message: '회사를 입력해 주세요.' },
      { state: data.userName, message: '이름을 입력해 주세요.' },
      { state: data.userEmail, message: '인증용 e-mail을 입력해 주세요.' },
      { state: data.userTel, message: '전화번호를 입력해 주세요.' },
    ];
    if (NullCheckUtils.isNullAndDisplaySwal(dataField)) {
      return;
    }

    if (Utils.validEmail(data.userEmail)) {
      CustomSwal.fire({
        title: '확인',
        text: '인증용 이메일의 형식이 올바르지 않습니다.',
      });
    }

    // console.log('register user data :::: ', data);

    apiUserRegister(data)
      .then(response => {
        CustomSwal.fire({
          title: '회원 가입',
          text: '회원 가입 되었습니다.',
        });
        // navigate(-2);
        navigate(RouterPath.emailCert, {
          state: {
            id: data.userLoginId,
            email: data.userEmail,
            name: data.userName,
          },
          replace: true,
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg_login"
        style={{
          background: '#F8FBFF',
        }}
      >
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>회원가입</p>
            <div className="divider_title" />
          </div>

          <div className="box_login">
            <div className="flex align-center">
              <input
                type="text"
                placeholder="아이디(이메일 형식)"
                className="inp_id"
                value={id}
                onChange={e => inputIdChange(e)}
              />

              <button
                type="button"
                className={!duplicate ? 'btn_dark' : 'btn_dark_disabled'}
                disabled={duplicate}
                onClick={() => duplicateId()}
              >
                중복확인
              </button>
            </div>
            {duplicate && (
              <div className="txt_error">사용할 수 있는 이메일입니다.</div>
            )}
            {!duplicate && (
              <div className="txt_error">중복확인이 필요합니다.</div>
            )}
            <input
              style={{ marginBottom: 10 }}
              type="password"
              placeholder="비밀번호"
              className="inp_pw"
              value={password}
              onChange={inputPasswordChange}
            />
            <input
              style={{ marginBottom: 10, marginTop: 0 }}
              type="password"
              placeholder="비밀번호 확인"
              className="inp_pw"
              value={passwordConf}
              onChange={inputPasswordConfChange}
            />
            <div className="txt_error">
              8자 이상 15자 이하의 대소문자, 특수 문자를 혼합하여 입력해주세요.
            </div>
            <input
              style={{ margin: '10px 0' }}
              type="text"
              placeholder="회사명"
              className="inp_id"
              value={dept}
              onChange={inputDeptChange}
            />
            <input
              type="text"
              placeholder="이름"
              className="inp_id"
              value={name}
              onChange={inputNameChange}
            />

            <div style={{ margin: '10px 0' }} className="flex align-center">
              <input
                type="text"
                placeholder="인증용 이메일"
                className="inp_id"
                value={email}
                onChange={inputEmailChange}
              />
            </div>
            <input
              type="text"
              placeholder="전화번호"
              className="inp_id"
              value={phoneNo}
              onChange={inputPhoneChange}
            />
            <div className="txt_error">-없이 숫자만 입력해주세요.</div>
            <button
              type="button"
              style={{ margin: '10px 0' }}
              className={duplicate ? 'btn_login' : 'btn_login_disabled'}
              disabled={!duplicate}
              onClick={() => registerUser()}
            >
              회원가입
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
