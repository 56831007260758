import React from 'react';
import PropTypes from 'prop-types';

function Modal(props) {
  const handleCloseModal = () => {
    props?.setShowModal(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={handleCloseModal}>
          &times;
        </span>
        <h2 className="title_modal">개인정보 수집 및 이용 동의</h2>
        <table className="table_modal">
          <tr className="title_preview">
            <th>수집목적</th>
            <th>수집항목</th>
            <th>이용기간</th>
          </tr>
          <tr>
            <td>뉴스레터 구독신청</td>
            <td>[필수] 이메일주소</td>
            <td>구독 취소시 까지</td>
          </tr>
        </table>
        <p className="note_modal">
          ※ 고객님은 수집 및 이용을 거부할 수 있으며, 거부 시 서비스 이용이
          제한됩니다.
          <br />※ 수집된 개인정보는 위 목적 이외의 용도로는 이용되지 않으며,
          제3자에게 제공되지 않습니다.
        </p>

        <form className="check_modal">
          <input
            type="checkbox"
            id="check_box"
            className="checkbox_create"
            value={props?.isCheck}
            checked={props?.isCheck}
            onClick={e => {
              props?.setIsChecked(!props?.isCheck);
              props?.setShowModal(false);
            }}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="check_box" className="label_checkbox_create">
            [필수] 개인정보 수집 및 이용 동의
          </label>
        </form>
      </div>
    </div>
  );
}

Modal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  setIsChecked: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  setShowModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  isCheck: PropTypes.bool,
};

export default Modal;
