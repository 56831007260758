import React from 'react';

/* eslint-disable */
function PrivacyPolicy() {
  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>개인정보 보호정책</p>
              <p className="copy_search_txt_001">Privacy Policy</p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg_login"
        style={{ background: '#F8FBFF', paddingBottom: 100 }}
      >
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>개인정보 처리방침</p>
            <div className="divider_title" />
          </div>

          <div className="box_policy">
            <div>개인정보 처리방침</div>
            <div>
              재단법인 부산테크노파크(이하 “회사”)는 관련 법령에 따라 부산 빅
              데이터 혁신센터 웹서비스(이하 “서비스”)를 이용하는 이용자의
              개인정보를 보호하고, 이와 관련한 고충을 신속하고 원활하게 처리하기
              위하여 <br />
              다음과 같이 개인정보 처리방침을 수립·공개합니다.
            </div>
          </div>

          <div className="traffic_map">제1조 (개인정보의 처리 목적)</div>

          <div className="policy_txt_01">
            회사는 다음의 목적을 위하여 개인정보를 처리합니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 회원 가입 및 관리: 회원가입의사 확인,
            본인 식별, 회원자격 유지 및 관리, 서비스 부정이용 방지, 각종
            고지·통지
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 서비스 제공: 서비스 이용신청 확인, 예약
            및 이용내역 관리
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. 고충처리: 신원 확인, 고충사항 확인,
            사실조사를 위한 연락·통지, 처리결과 통보
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;4. 가명정보의 활용: 통계작성, 과학적 연구,
            공익적 기록보존 등을 위한 가명처리 및 가명정보의 활용
          </div>
          <div className="traffic_map">제2조 (처리하는 개인정보 항목)</div>
          <div className="policy_txt_01">
            ① 회사는 서비스 이용자에 대하여 다음의 개인정보항목을 수집하여
            처리하고 있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 회원 가입 시 기본수집사항(필수항목):
            아이디, 이름, 이메일, 비밀번호
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 고충처리 시: 이용자로부터 위 각 정보 중
            필요한 항목 및 해당 고충처리에 필요한 별개 항목을 수집 및 처리
          </div>

          <div className="traffic_map">제3조 (개인정보의 처리 및 보유기간)</div>

          <div className="policy_txt_01">
            ① 회사는 이용자가 서비스를 탈퇴 또는 이용자격을 상실할 경우에는
            별도의 요청이 없더라도 수집된 이용자의 정보를 지체없이 삭제 및
            파기합니다.
            <br />
            다만, 회원 탈퇴 또는 이용자격 상실에도 불구하고 다음 각 호의
            경우에는 아래에 기재된 기간 동안 보존합니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 관계 법령 위반에 따른 수사·조사 등이 진행
            중인 경우에는 해당 수사·조사 종료 시까지 보존 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 서비스 이용에 따른 채권·채무관계 잔존
            시에는 해당 채권·채무관계 정산 시까지 보존
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. 회사가 이용약관에 따라 서비스 이용계약을
            해지한 경우 부정한 재가입 및 서비스 이용을 방지하기 위하여 서비스
            부정이용 기록을 해지 후 1년간 보존
          </div>
          <div className="policy_txt_01">
            ② 전항에도 불구하고 회사는 다음의 사유에 해당하는 경우에는 해당 기간
            종료 시까지 보존합니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 서비스 이용 관련 개인정보 (로그기록):
            「통신비밀보호법」상 보존기간인 3개월
          </div>

          <div className="traffic_map">제4조 (개인정보의 제3자 제공)</div>
          <div className="policy_txt_01">
            회사는 이용자의 동의를 받거나 개인정보 보호법 또는 다른 법률의
            특별한 규정이 있는 경우에만 개인정보를 제3자에게 제공합니다.
          </div>

          <div className="traffic_map">제5조 (개인정보처리의 위탁)</div>
          <div className="policy_txt_01">
            회사는 개인정보 처리업무를 제3자에게 위탁하고 있지 않습니다.
          </div>

          <div className="traffic_map">
            제6조 (수집목적과 합리적으로 관련된 범위 내의 개인정보 이용 및 제공)
          </div>
          <div className="policy_txt_01">
            회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을
            고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할
            수 있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 당초 수집 목적과 관련성이 있는지 여부:
            당초 수집 목적과 추가적 이용·제공 목적이 성질이나 경향에 있어 연관이
            있는지 등을 고려하여 따라 판단 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 개인정보를 수집한 정황 또는 처리 관행에
            비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이
            있는지 여부: 개인정보처리자와 이용자 간의 관계, 기술 수준 및 발전
            속도, 상당한 기간동안 정립된 일반적인 사정(관행) 등을 고려하여 판단{' '}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. 이용자의 이익을 부당하게 침해하는지 여부:
            추가적인 이용 목적과의 관계에서 이용자의 이익이 실질적으로
            침해되는지 및 해당 이익 침해가 부당한지 등을 고려하여 판단 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;4. 가명처리 또는 암호화 등 안전성 확보에
            필요한 조치를 하였는지 여부: 침해 가능성을 고려한 안전 조치가
            취해지는지 등을 고려하여 판단
          </div>

          <div className="traffic_map">제7조 (아동의 개인정보 처리)</div>
          <div className="policy_txt_01">
            회사는 만14세 미만 아동의 개인정보를 처리하고 있지 않습니다.
          </div>

          <div className="traffic_map">
            제8조 (이용자와 법정대리인의 권리·의무 및 행사방법)
          </div>
          <div className="policy_txt_01">
            ① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
            요구 등의 권리를 행사할 수 있습니다.
            <br />
            ② 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등
            대리인을 통하여 하실 수 있습니다. 이 경우 수임인에 대한 위임사실을
            확인할 수 있는 위임장을 제출하셔야 합니다.
            <br />③ 제1항에 따른 권리 행사는 서면, 전자우편 등을 통하여 하실 수
            있으며, 회사는 이에 대해 지체 없이 조치하겠습니다. 단, 개인정보
            보호법 등 관련 법령에 따라 일부 권리 행사가 제한될 수 있습니다.
          </div>

          <div className="traffic_map">제9조 (개인정보의 파기)</div>
          <div className="policy_txt_01">
            ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
            <br />
            ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 제3조 제2항에 기재된 법령에 따라 개인정보를
            계속 보존하여야 하는 경우에는 해당 개인정보를 별도의
            데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 파기절차: 회사는 파기 사유가 발생한
            개인정보를 선정하고, 회사의 개인정보 보호책임자또는 그 권한을
            위임받은 분야별 관리책임자의 승인을 받아 개인정보를 파기합니다.{' '}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 파기방법: 회사는 전자적 파일 형태로
            기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을
            이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
            분쇄하거나 소각하여 파기합니다.
          </div>

          <div className="traffic_map">제10조 (개인정보의 안전성 확보조치)</div>
          <div className="policy_txt_01">
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
            있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 관리적 조치: 내부관리계획 수립·시행,
            정기적 직원 교육 등 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 기술적 조치: 해킹 등에 대비한 기술적
            대책, 개인정보의 암호화, 개인정보처리시스템의 접근권한 관리,
            접속기록의 보관 및 위·변조 방지 등 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. 물리적 조치: 서버실, 자료보관실 등의
            접근통제 <br />
          </div>

          <div className="traffic_map">
            제11조 (개인정보 자동 수집 장치의 설치ㆍ운영 및 거부에 관한 사항)
          </div>
          <div className="policy_txt_01">
            회사는 쿠키(cookie) 등 개인정보를 자동으로 수집하는 장치를 설치
            운영하지 않습니다.
          </div>

          <div className="traffic_map">
            제12조 (행태정보의 수집·이용 및 거부 등에 관한 사항)
          </div>
          <div className="policy_txt_01">
            회사는 이용자의 행태정보를 수집하지 않습니다.
          </div>

          <div className="traffic_map">제13조 (개인정보 보호책임자)</div>
          <div className="policy_txt_01">
            ① 회사는 개인정보 및 위치정보 처리에 관한 업무를 총괄해서 책임지고,
            이와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이
            개인정보/위치정보 보호책임자를 지정하고 있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;▶ 개인정보 보호책임자 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 성명: 백이현
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 직책: 경영지원실장
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 연락처:
            051-747-6352
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;▶ 분야별 관리책임자 <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 성명: 김덕중
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 직책:
            DX데이터산업팀장
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 연락처:
            051-747-6352
          </div>
          <div className="policy_txt_01">
            ② 이용자는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
            개인정보/위치정보 보호 관련 문의, 불만처리, 피해구제 등에 관한
            사항을 개인정보/위치정보 보호책임자 및 아래 담당부서로 문의하실 수
            있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;- 부서명: DX데이터산업팀
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- 연락처: 051-747-6352
          </div>
          <div className="policy_txt_01">
            ③ 이용자는 개인정보침해로 인한 구제, 기타 개인정보침해의 신고∙상담에
            대하여는 아래의 기관에 문의하실 수 있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;- 개인정보분쟁조정위원회: (국번없이)
            1833-6972 (www.kopico.go.kr)
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- 개인정보침해신고센터: (국번없이) 118
            (privacy.kisa.or.kr)
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- 대검찰청: (국번없이) 1301 (www.spo.go.kr)
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- 경찰청: (국번없이) 182 (ecrm.cyber.go.kr)
          </div>

          <div className="traffic_map">제14조 (영상정보처리기기 설치·운영)</div>
          <div className="policy_txt_01">
            회사는 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1. 영상정보처리기기 설치근거·목적: 회사의
            시설안전·화재예방
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. 설치 대수, 설치 위치, 촬영 범위: 사옥 5층
            데이터오픈랩 내부 1대, 데이터오픈랩 내부 4개 방실에 각 1대씩, 총 5대
            설치 운영 중, 촬영범위는 데이터오픈랩 내부 모든 공간
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. 관리책임자, 담당부서 및 영상정보에 대한
            접근권한자: DX데이터산업팀 조은애 대리
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;4. 영상정보 촬영시간, 보관기간, 보관장소,
            처리방법
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 촬영시간: 24시간
            촬영
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 보관기간:
            촬영시부터 30일
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 보관장소 및
            처리방법: DX데이터산업팀 영상정보처리기기 통제실에 보관·처리
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;5. 영상정보 확인 방법 및 장소: 관리책임자에
            요구
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;6. 이용자의 영상정보 열람 등 요구에 대한
            조치: 개인영상정보 열람·존재확인 청구서로 신청하여야 하며, 이용자
            자신이 촬영된 경우 또는 명백히 이용자의 생명.신체.재산 이익을 위해
            필요한 경우에 한해 열람을 허용함
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;7. 영상정보 보호를 위한 기술적.관리적.물리적
            조치: 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의
            안전한 저장·전송기술 적용, 처리기록보관 및 위·변조 방지조치,
            보관시설 마련 및 잠금장치 설치 등
          </div>

          <div className="traffic_map">제15조 (가명정보의 처리)</div>
          <div className="policy_txt_01">
            회사는 수집한 개인정보를 가명처리하고 있지 않습니다.
          </div>

          <div className="traffic_map">제16조 (개인정보 처리방침의 변경)</div>
          <div className="policy_txt_01">
            회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로
            개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이 변경되는
            경우 회사는 변경 사항 최소한 효력발생일 7일 이전에 회사의 홈페이지
            등에 게시하며, 변경된 개인정보처리방침은 기재된 효력발생일에 그
            효력이 발생합니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경
            등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에
            미리 알려드리겠습니다.
            <br />
            <br />이 개인정보 처리방침은 2023. 12. 22.부터 적용됩니다.
          </div>
          <div className="policy_txt_01">
            <a
              className="text-decoration-none fw-bold text-secondary"
              href="/notice/detail?id=401935"
            >
              [이전 개인정보처리방침 보기]
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
