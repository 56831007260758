import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from '../common/constants';
import { images } from '../assets';
import HeaderMobile from './HeaderMobile';
import WaveLogo from '../assets/images/data_wave_logo.svg';

function Header() {
  const [isHeaderOn, setIsHeaderOn] = useState(false);
  const [isMenuDepthOn, setIsMenuDepthOn] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const handleMenuItemMouseEnter = item => {
    item.parentNode.classList.add('on');
  };

  const handleMenuItemMouseLeave = item => {
    item.parentNode.classList.remove('on');
  };

  const handleMenuMouseEnter = () => {
    setIsHeaderOn(true);
    setIsMenuDepthOn(true);
  };

  const handleLogOut = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('access_token');
    navigate(0);
  };

  const closeTab = () => {
    setIsHeaderOn(false);
    setIsMenuDepthOn(false);
  };

  const handleMenuMouseLeave = () => {
    closeTab();
    const listItem = document.querySelectorAll('.menu_item');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < listItem.length; i++) {
      if (listItem[i].classList.contains('on')) {
        listItem[i].classList.remove('on');
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const top = window.scrollY;
      if (top > 85) {
        document.getElementById('header').classList.add('fixed');
      } else {
        document.getElementById('header').classList.remove('fixed');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('auth')) {
      setIsLogin(true);
      setUserInfo(JSON.parse(localStorage.getItem('auth')));
    } else {
      setIsLogin(false);
      setUserInfo(null);
    }
  }, [localStorage.getItem('auth'), isLogin]);

  return (
    <div id="header" className={isHeaderOn ? 'on' : ''}>
      <div className="header_top mobile-hide-980">
        <div className="container">
          <ul className="member">
            {!isLogin && (
              <>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => navigate(RouterPath.login)}
                    className="login"
                  >
                    로그인
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() =>
                      navigate(RouterPath.register, { state: false })
                    }
                    className="join"
                  >
                    회원가입
                  </a>
                </li>
              </>
            )}
            {isLogin && (
              <>
                <p>{userInfo.userNm} 님 반갑습니다.</p>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => handleLogOut()} className="logout">
                    로그아웃
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() =>
                      navigate(RouterPath.userModify, { state: false })
                    }
                    className="join"
                  >
                    마이페이지
                  </a>
                </li>
              </>
            )}
          </ul>
          <ul className="btn_w">
            <li>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-is-valid */}
              <a
                href="https://www.dxchallenge.co.kr"
                target="_blank"
                className="btn_dx"
                rel="noreferrer"
              >
                <img src={images.dxLogoBack} alt="Logo dx" />
              </a>
            </li>
          </ul>
          <ul className="btn_w">
            <li>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-is-valid */}
              <a
                href="https://data.busan.go.kr/"
                target="_blank"
                className="btn_wave"
                rel="noreferrer"
              >
                <WaveLogo />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="header_in">
        <h1>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
          <a
            onClick={() => {
              navigate(RouterPath.slash);
              setShowMenu(false);
            }}
          />
        </h1>
        {!showMenu ? (
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
          <a
            onClick={() => {
              document.getElementById('foot').style.display = 'none';
              setShowMenu(true);
            }}
            className="btnMenu_m"
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
          <a
            onClick={() => {
              document.getElementById('foot').style.display = 'block';
              setShowMenu(false);
            }}
            className="btnMenuClose_m"
          />
        )}
      </div>
      {showMenu && (
        <HeaderMobile
          isLogin={isLogin}
          handleShowMenu={setShowMenu}
          userInfo={userInfo}
        />
      )}
      <div id="gnb">
        <div id="menu">
          <ul
            className="menu"
            onMouseEnter={handleMenuMouseEnter}
            onMouseLeave={handleMenuMouseLeave}
          >
            <li
              className="menu_item"
              onMouseEnter={e => handleMenuItemMouseEnter(e.target)}
              onMouseLeave={e => handleMenuItemMouseLeave(e.target)}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
              <a onClick={() => null}>센터소개</a>
              <div className={isMenuDepthOn ? 'menu_depth on' : 'menu_depth'}>
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.centerGuide);
                        closeTab();
                      }}
                    >
                      센터소개
                    </a>
                  </li>
                  {/* <li>
                     eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url 
                    <a
                      onClick={() => {
                        navigate(RouterPath.spaceGuide);
                        closeTab();
                      }}
                    >
                      공간안내
                    </a>
                  </li> */}
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.facilityGuide);
                        closeTab();
                      }}
                    >
                      인프라안내
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.chartGuide);
                        closeTab();
                      }}
                    >
                      조직도
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.mapGuide);
                        closeTab();
                      }}
                    >
                      오시는 길
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li
              className="menu_item"
              onMouseEnter={e => handleMenuItemMouseEnter(e.target)}
              onMouseLeave={e => handleMenuItemMouseLeave(e.target)}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
              <a onClick={() => null}>지원사업</a>
              <div className={isMenuDepthOn ? 'menu_depth on' : 'menu_depth'}>
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.supportLead);
                        closeTab();
                      }}
                    >
                      데이터 경제 기반의
                      <br />
                      디지털 전환 생태계 조성
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.supportCollabo);
                        closeTab();
                      }}
                    >
                      공공 민간 데이터
                      <br />
                      활용 활성화 지원
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.supportPractice);
                        closeTab();
                      }}
                    >
                      실무 중심의 융합형
                      <br />
                      데이터 인재 양성
                    </a>
                  </li>
                  {/* <li>
                     eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url 
                    <a
                      onClick={() => {
                        navigate(RouterPath.supportInnovation);
                        closeTab();
                      }}
                    >
                      데이터 산업 혁신
                      <br />
                      성장 기반 강화
                    </a>
                  </li> */}
                </ul>
              </div>
            </li>

            <li
              className="menu_item"
              onMouseEnter={e => handleMenuItemMouseEnter(e.target)}
              onMouseLeave={e => handleMenuItemMouseLeave(e.target)}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
              <a onClick={() => null}>데이터 오픈랩</a>
              <div className={isMenuDepthOn ? 'menu_depth on' : 'menu_depth'}>
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.introDataLab);
                        closeTab();
                      }}
                    >
                      데이터 오픈랩 소개
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.bigdataList);
                        closeTab();
                      }}
                    >
                      데이터 리스트
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.operation);
                        closeTab();
                      }}
                    >
                      이용 및 운영안내
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.analysis);
                        closeTab();
                      }}
                    >
                      분석환경 안내
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.application);
                        closeTab();
                      }}
                    >
                      이용신청
                    </a>
                  </li>
                  {/* {isLogin && (
                    <li>
                       eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url 
                      <a
                        onClick={() => {
                          navigate(RouterPath.appuselist);
                          closeTab();
                        }}
                      >
                        이용신청내역
                      </a>
                    </li>
                  )} */}
                </ul>
              </div>
            </li>

            <li
              className="menu_item"
              onMouseEnter={e => handleMenuItemMouseEnter(e.target)}
              onMouseLeave={e => handleMenuItemMouseLeave(e.target)}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
              <a onClick={() => null}>활용사례</a>
              <div className={isMenuDepthOn ? 'menu_depth on' : 'menu_depth'}>
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.useCase);
                        closeTab();
                      }}
                    >
                      활용사례
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.referenceList);
                        closeTab();
                      }}
                    >
                      자료실
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li
              className="menu_item"
              onMouseEnter={e => handleMenuItemMouseEnter(e.target)}
              onMouseLeave={e => handleMenuItemMouseLeave(e.target)}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
              <a onClick={() => null}>알림마당</a>
              <div className={isMenuDepthOn ? 'menu_depth on' : 'menu_depth'}>
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.noticeList);
                        closeTab();
                      }}
                    >
                      공지사항
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.photoNewsList);
                        closeTab();
                      }}
                    >
                      포토&뉴스
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.newsList);
                        closeTab();
                      }}
                    >
                      뉴스레터
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.qnaList);
                        closeTab();
                      }}
                    >
                      질의응답
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.businessList);
                        closeTab();
                      }}
                    >
                      사업공고
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                    <a
                      onClick={() => {
                        navigate(RouterPath.reportList);
                        setShowMenu(false);
                      }}
                    >
                      보도자료
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            {/* <li */}
            {/*  className="menu_item" */}
            {/*  onMouseEnter={e => handleMenuItemMouseEnter(e.target)} */}
            {/*  onMouseLeave={e => handleMenuItemMouseLeave(e.target)} */}
            {/* > */}
            {/*  /!* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url *!/ */}
            {/*  <a href="javascript:alert('페이지 제작중입니다.');">연계센터</a> */}
            {/*  <div className={isMenuDepthOn ? 'menu_depth on' : 'menu_depth'}> */}
            {/*    <ul> */}
            {/*      <li> */}
            {/*        /!* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url *!/ */}
            {/*        <a href="javascript:alert('페이지 제작중입니다.');"> */}
            {/*          연계센터 */}
            {/*        </a> */}
            {/*      </li> */}
            {/*    </ul> */}
            {/*  </div> */}
            {/* </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
