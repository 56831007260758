// eslint-disable react/destructuring-assignment

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { images } from '../assets';
import { RouterPath } from '../common/constants';
import { handleError } from '../utils/HandleError';
import { apiDownloadBigDataSampleList } from '../api/RestAPI';

function ItemLab(props) {
  const navigate = useNavigate();
  const [columnNameList, setColumnNameList] = useState([]);

  const [width, setWidth] = React.useState(window.innerWidth);

  const dataFormat = props?.item.dataFormat;
  const dataFormatArray = dataFormat.split(', ');

  const navigateDetail = item => {
    navigate(`${RouterPath.bigdataDetail}/${item.dataIdx}`, {
      state: props?.item,
    });
  };

  const downloadBigDataSampleListApi = () => {
    // eslint-disable-next-line no-shadow
    const columnNameArray = props?.item.tableColumns.map(
      item => item.colNameEn,
    );
    const columnMapArray = props?.item.tableColumns.map(item => ({
      colNameEn: item.colNameEn,
      colNameKr: item.colNameKr,
    }));
    setColumnNameList(columnNameArray);
    const data = {
      dataIdx: props?.item.dataIdx,
      accessDb: props?.item.accessDb,
      accessTable: props?.item.accessTable,
      size: props?.item.countSample,
      colNameList: columnNameArray,
      fileName: props?.item.fileName,
      colMapList: columnMapArray,
    };
    // console.log('download request data::: ', data);

    apiDownloadBigDataSampleList(data)
      .then(response => {
        // console.log('response data blob type::::', response.data.type);
        if (response.data.type !== 'application/json') {
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = downloadUrl;
          a.download = decodeURIComponent(
            response.headers['content-disposition'].split('filename=')[1],
          );
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
        }
      })
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Call handleResize directly so we can check the initial window size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="item_data">
      <div style={{ display: 'flex' }}>
        <div className="item_img_icon">
          <img
            src={
              (props?.item.dataClass === '교통' && images.mQuickIco04) ||
              (props?.item.dataClass === '인구' && images.mQuickIco01) ||
              (props?.item.dataClass === '부동산' && images.estate) ||
              (props?.item.dataClass === '상권' && images.mQuickIco06) ||
              (props?.item.dataClass === '관광' && images.mQuickIco07) ||
              (props?.item.dataClass === '지역화폐' && images.mQuickIco03) ||
              (props?.item.dataClass === '차량등록' && images.taxi) ||
              (props?.item.dataClass === '건축물' && images.mQuickIco05) ||
              (props?.item.dataClass === '유료도로' && images.road) ||
              (props?.item.dataClass === '유동인구' && images.chart) ||
              (props?.item.dataClass === '소비' && images.salesSlide) ||
              (props?.item.dataClass === '개인신용' && images.creditCard) ||
              (props?.item.dataClass === '기업신용' && images.enterprise) ||
              (props?.item.dataClass === 'test' && images.mQuickIco02)
            }
            alt="office"
          />
          <span>{props?.item.image_content}</span>
        </div>
        <div
          className="item_content"
          onClick={() => navigateDetail(props?.item)}
        >
          <div className="item_txt_001">{props?.item.dataName}</div>
          <div className="item_txt_002">{props?.item.dataDesc}</div>
          <div className="item_content_foot">
            <div className="item_foot">
              <div className="badge_item">수집기간</div>
              <span>
                {props?.item.zdateFrom} ~ {props?.item.zdateTo}
              </span>
            </div>
            <div className="item_foot">
              <div className="badge_item">공간단위</div>
              <span>{props?.item.geoRes}</span>
            </div>
            <div className="item_foot">
              <div className="badge_item">시간단위</div>
              <span>{props?.item.timeRes}</span>
            </div>
            <div className="item_foot">
              <div className="badge_item">적재주기</div>
              <span>{props?.item.timeLoadPeriod}</span>
            </div>
          </div>
        </div>
      </div>
      {width < 768 && (
        <div className="btn_download">
          <div>샘플 데이터</div>
          <img src={images.download} alt="download" />
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <div className="item_menu">
          {dataFormatArray?.map((item, index) => (
            <div key={`bigdata_${props?.item.dataIdx}_${index}`}>{item}</div>
          ))}
        </div>
        <div
          onClick={() => downloadBigDataSampleListApi()}
          className="btn_download mobile-hide"
        >
          <div>
            샘플
            <br />
            데이터
          </div>
          <img src={images.download} alt="download" />
        </div>
      </div>
    </div>
  );
}

ItemLab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  item: PropTypes.object,
};

export default ItemLab;
