import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import Pagination from '../../components/Pagination';
import { RouterPath } from '../../common/constants';
import { apiGetPhotoNewsList, getDownloadFile } from '../../api/RestAPI';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

function PhotoNewsList() {
  const navigate = useNavigate();
  const location = useLocation();

  const pageSize = 3;
  const initPage = location.state ? location.state.currentPage : 1;
  const [page, setPage] = useState(initPage);

  const [list, setList] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const getPhotoNewsList = currentPage => {
    const data = {
      page: currentPage,
      size: pageSize,
    };

    apiGetPhotoNewsList(data)
      .then(response => {
        setList(response.data.data.list);
        setTotalCount(response.data.data.totalCnt);
        setTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    getPhotoNewsList(initPage);
    setPage(initPage);
  }, [location]);

  useEffect(() => {
    if (list.length > 0) {
      list.forEach(item => {
        fetchImage(item.thumbFileUrl, item.boardIdx);
      });
    }
  }, [list]);

  const navigateList = currentPage => {
    navigate(RouterPath.photoNewsList, {
      state: { currentPage },
    });
  };

  const navigateDetail = boardIdx => {
    navigate(`${RouterPath.photoNewsDetail}/${boardIdx}`);
  };
  // eslint-disable-next-line consistent-return
  const fetchImage = (fileUrl, photoIdx) => {
    if (!fileUrl) {
      return Promise.resolve('');
    }

    const parts = fileUrl.split('/files');
    const targetUrl = parts[1];
    const completeUrl = `/images${targetUrl}`;

    setImageUrls(prevState => ({
      ...prevState,
      [photoIdx]: completeUrl,
    }));

    // const params = {
    //   filePath: fileUrl,
    // };
    //
    // return getDownloadFile(params)
    //   .then(response => {
    //     const imageUrl = window.URL.createObjectURL(
    //       new Blob([response.data], {
    //         type: response.headers['content-type'],
    //       }),
    //     );
    //     setImageUrls(prevState => ({
    //       ...prevState,
    //       [photoIdx]: imageUrl,
    //     }));
    //     return imageUrl;
    //   })
    //   .catch(error => {
    //     return null;
    //   });
  };

  return (
    <>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.photoNewsList}
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>포토&뉴스</p>
            <div className="divider_title" />
          </div>

          {list &&
            list.map((item, index) => (
              <div
                className={`${
                  index === 0
                    ? 'news_cards news_cards_1 selector'
                    : index === 2
                    ? 'news_cards news_cards_2 selector'
                    : 'news_cards selector'
                } `}
                key={index}
                onClick={() => navigateDetail(item.boardIdx)}
              >
                <img
                  className="img_cards"
                  src={imageUrls[item?.boardIdx] || ''}
                  alt="placeholder"
                />
                <div>
                  <h1 className="news_card_title">{item?.photoTitle}</h1>
                  <div className="divider_news_card" />
                  <p className="news_card_time">
                    {Utils.formatDate(item?.regDate)}
                  </p>
                  <div
                    className="news_card_content"
                    dangerouslySetInnerHTML={{
                      __html: Utils.shortenText(item.photoBody, 200),
                    }}
                  />
                </div>
              </div>
            ))}

          <Pagination
            pageCount={totalPage}
            pageNum={page}
            setPage={setPage}
            onPageChangeCallback={navigateList}
          />
        </div>
      </section>
    </>
  );
}

export default PhotoNewsList;
