/* eslint-disable no-case-declarations */
import {
  AccessDeniedException,
  CustomException,
  InvalidUserException,
  NetworkException,
} from '../common/exceptions';

// Util
// Constant
import RESULT_CODE from '../common/constants/ResultCode';
import { CustomSwal } from '../components';
import { RouterPath } from '../common';

// eslint-disable-next-line default-param-last
export const handleError = (error, callback) => {
  // console.log('error ::::: ', error);
  switch (true) {
    // CustomException || NetworkException
    case error instanceof CustomException:
    case error instanceof NetworkException:
      CustomSwal.fire({
        title: '오류',
        text: error.message,
      });
      // Utils.openModal({ body: error.message });
      break;
    // InvalidUserException
    case error instanceof InvalidUserException:
      CustomSwal.fire({
        title: '오류',
        text: error.message,
        then: () => {
          // window.location.href = RouterPath.slash;
          callback(RouterPath.slash, { replace: true });
        },
      });
      // Utils.openModal({
      //   body: error.message,
      //   closeEvent: () => {
      //     window.location.href = RouterPath.Home;
      //   },
      // });
      break;
    // AccessDeniedException
    case error instanceof AccessDeniedException:
      CustomSwal.fire({
        title: '오류',
        text: error.message,
        then: () => {
          // window.location.go(-1);
          callback(-1);
        },
      });
      // Utils.openModal({
      //   body: error.message,
      //   closeEvent: () => {
      //     window.location.go(-1);
      //   },
      // });
      // go back
      break;
    // default
    default:
      const message = error?.message || RESULT_CODE.CODE_500;
      CustomSwal.fire({
        title: '오류',
        text: message,
      });
    // Utils.openModal({ body: message });
  }
};
