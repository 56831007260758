import { useEffect } from 'react';
// import { authAction } from '@reducers/authSlice';
import { useSelector } from 'react-redux';
import { RouterPath } from '../common/constants';
import EfficacyException from '../common/exceptions/EfficacyException';
import { store } from '../store';

export default function ErrorBoundary({ children }) {
  const action = process.env.REACT_APP_ENV_NAME;
  // const role = useSelector(state => state.role);
  const captureReject = e => {
    try {
      e.preventDefault();
      if (action !== 'local') {
        if (e.reason instanceof EfficacyException) {
          // store.dispatch(authAction.removeAuth());
          window.location.href = `${RouterPath.slash}`;
          return;
        }

        // if (e.reason instanceof Error) {
        //   window.location.href = RouterPath.Errors;
        //   return;
        // }
      }
      return;
    } catch (error) {
      console.log('error :: ', error);
    }
  };

  useEffect(() => {
    window.addEventListener('unhandledrejection', captureReject);
    return () => {
      window.removeEventListener('unhandledrejection', captureReject);
    };
  }, []);

  return children;
}
