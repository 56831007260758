import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';
import Select from 'react-select';
import Filter from '../../components/Filter';
import { images } from '../../assets';
import { RouterPath } from '../../common/constants';
import NullCheckUtils from '../../utils/NullCheckUtils';
import CustomSwal from '../../components/CustomSwal';
import {
  apiUserApplyUseBigdata,
  getBigDataList,
  getBigdataTypeList,
  getHolidayList,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import { MyCustomDatePicker } from '../../components';
import { RESULT_CODE } from '../../common';

function AppUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB
  const allowedExtensions = ['pdf', 'xls', 'xlsx', 'png', 'jpg', 'jpeg', 'bmp'];
  let applyState = false;
  const navigate = useNavigate();
  const [am, setAm] = useState(true);
  const [pm, setPm] = useState(false);
  const [selectDate, setSelectDate] = useState('');
  const [activeStep, setActiveStep] = useState(3);
  const [fileUpload, setFileUpload] = useState([]);
  const [numUploads, setNumUploads] = useState(1);

  const [fileList, setFileList] = useState([]);

  const [useDataListModify, setUseDataListModify] = useState([]);

  const [bigdataList, setBigdataList] = useState(null);
  const [bigdataClassList, setBigdataClassList] = useState(null);
  const [holidayList, setHolidayList] = useState([]);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const todayParsed = `${year}-${month}-${day}`;

  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // 오늘 날짜에 하루를 더함
  const tomorrowYear = tomorrow.getFullYear();
  const tomorrowMonth = String(tomorrow.getMonth() + 1).padStart(2, '0');
  const tomorrowDay = String(tomorrow.getDate()).padStart(2, '0');
  const tommorowParsed = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;
  // const handleChangeUpload = id => e => {
  //   const file = e.target.files[0];
  //
  //   if (!file) return;
  //
  //   const { name, size } = file;
  //   const fileExtension = name.split('.').pop().toLowerCase();
  //
  //   if (size > MAX_FILE_SIZE) {
  //     CustomSwal.fire({
  //       title: '확인',
  //       text: '파일 크기는 최대 30MB까지 허용됩니다.',
  //     });
  //     return;
  //   }
  //
  //   if (!allowedExtensions.includes(fileExtension)) {
  //     CustomSwal.fire({
  //       title: '확인',
  //       html: `허용되지 않은 파일 형식입니다.<br/>(허용된 형식: xls, xlsx, pdf, 이미지 파일)`,
  //     });
  //     return;
  //   }
  //
  //   const fileElement = document.querySelector(`.file_upload_${id}`);
  //   const inputFileElement = document.querySelector(`.inp_upload_${id}`);
  //   const spanElement = document.querySelector(`.file_upload_${id} span`);
  //
  //   inputFileElement.style.display = 'none';
  //   fileElement.style.display = 'flex';
  //   spanElement.innerHTML = name;
  //
  //   setFileList(prev => [...prev, { id, file }]);
  //   setNumUploads(prev => prev + 1);
  // };
  const addUseBigdataRow = () => {
    // 입력된 값
    const tempTableInfoList = [...useDataListModify];
    tempTableInfoList.push({
      dataClass: { value: '', label: '선택 필요' },
      data: {
        value: '',
        label: '데이터 분야 먼저 선택하세요',
        zdateFrom: null,
        zdateTo: null,
      },
      dataSelectList: null,
      startDate: new Date(),
      endDate: new Date(),
      dataSelect: false,
    });
    setUseDataListModify([...tempTableInfoList]);
    // console.log('data::::', useDataListModify);
  };
  const deleteUseBigdataRow = rowIndex => {
    // 입력된 값
    const tempTableInfoList = [...useDataListModify];
    const removedTableInfo = tempTableInfoList.splice(rowIndex, 1);
    setUseDataListModify([...tempTableInfoList]);
  };
  const changeStartDate = (arrayKey, event) => {
    // 입력된 값
    const tempTableInfoList = [...useDataListModify];
    tempTableInfoList[arrayKey].startDate = event;
    setUseDataListModify([...tempTableInfoList]);
  };

  const changeEndDate = (arrayKey, event) => {
    // 입력된 값
    const tempTableInfoList = [...useDataListModify];
    tempTableInfoList[arrayKey].endDate = event;
    setUseDataListModify([...tempTableInfoList]);
  };
  const handleDataClassChange = (arrayKey, event) => {
    // 입력된 값
    const tempTableInfoList = [...useDataListModify];
    tempTableInfoList[arrayKey].dataClass = event;
    tempTableInfoList[arrayKey].data = { value: '', label: '선택하세요' };
    tempTableInfoList[arrayKey].dataSelect = true;

    const selectedBigdataList = bigdataList
      .filter(bigdataItem => event.label === bigdataItem.dataClass)
      .map(bigdataItem => ({
        value: bigdataItem.dataCode,
        label: bigdataItem.dataName,
        zdateFrom: bigdataItem.zdateFrom,
        zdateTo: bigdataItem.zdateTo,
      }));

    tempTableInfoList[arrayKey].minDate = null;
    tempTableInfoList[arrayKey].maxDate = null;
    tempTableInfoList[arrayKey].startDate = null;
    tempTableInfoList[arrayKey].endDate = null;

    tempTableInfoList[arrayKey].dataSelectList = selectedBigdataList;
    setUseDataListModify([...tempTableInfoList]);
  };
  const handleDataChange = (arrayKey, event) => {
    // 입력된 값
    const tempTableInfoList = [...useDataListModify];
    tempTableInfoList[arrayKey].data = event;
    if (event.zdateFrom && event.zdateTo) {
      const minDate = new Date(
        `${event.zdateFrom.slice(0, 4)}-${event.zdateFrom.slice(4, 6)}-01`,
      );
      const maxDate = new Date(
        `${event.zdateTo.slice(0, 4)}-${event.zdateTo.slice(4, 6)}-01`,
      );
      tempTableInfoList[arrayKey].minDate = minDate;
      tempTableInfoList[arrayKey].maxDate = maxDate;
      tempTableInfoList[arrayKey].startDate = minDate;
      tempTableInfoList[arrayKey].endDate = maxDate;
    } else {
      tempTableInfoList[arrayKey].minDate = null;
      tempTableInfoList[arrayKey].maxDate = null;
      tempTableInfoList[arrayKey].startDate = null;
      tempTableInfoList[arrayKey].endDate = null;
    }
    setUseDataListModify([...tempTableInfoList]);
  };

  const handleChangeUpload = e => {
    const { files } = e.target;
    if (!files) return;

    const newFileList = [...fileList];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      if (newFileList?.length > 2) {
        CustomSwal.fire({
          title: '확인',
          html: `파일 갯수는 3개까지 허용됩니다.<br/>3개 파일만 리스트에 등록합니다.`,
        });
        break;
      }
      const file = files[i];
      const { name, size } = file;
      const fileExtension = name.split('.').pop().toLowerCase();

      if (size > MAX_FILE_SIZE) {
        CustomSwal.fire({
          title: '확인',
          text: '파일 크기는 최대 30MB까지 허용됩니다.',
        });
        return;
      }

      if (!allowedExtensions.includes(fileExtension)) {
        CustomSwal.fire({
          title: '확인',
          html: `허용되지 않은 파일 형식입니다.<br/>(허용된 형식: xls, xlsx, pdf, 이미지 파일)`,
        });
        return;
      }
      newFileList.push(file);
    }

    setFileList([...newFileList]);
    e.target.value = null;
  };

  const handleCloseClick = file => {
    setFileList(prev => prev.filter(item => item !== file));
  };

  const handleFileButtonClick = id => {
    document.getElementById(`inp_file_${id}`).click();
  };
  const convertSubCodeToDate = subCode => {
    const convertMonth = subCode.slice(0, 2);
    const convertDay = subCode.slice(2, 4);
    return `${parseInt(convertMonth, 10)}월 ${parseInt(convertDay, 10)}일`;
  };

  const handleDateChange = event => {
    const date = new Date(event.target.value);
    const targetDay = date.getDay();

    const selectedHoliday = holidayList.find(
      holiday =>
        holiday.date.getDate() === date.getDate() &&
        holiday.date.getMonth() === date.getMonth() &&
        holiday.date.getFullYear() === date.getFullYear(),
    );

    if (selectedHoliday) {
      CustomSwal.fire({
        title: '안내',
        text: `${convertSubCodeToDate(selectedHoliday.subCode)}은 ${
          selectedHoliday.desc
        }입니다.`,
      });
      setSelectDate(null);
    } else if (targetDay === 0 || targetDay === 6) {
      CustomSwal.fire({
        title: '안내',
        text: '선택한 날짜는 주말입니다.',
      });
      setSelectDate(null);
    } else {
      setSelectDate(event.target.value);
    }
  };

  const applyUseBigdata = () => {
    if (applyState) {
      return;
    }
    const completeUseDataList = useDataListModify.map(item => ({
      dataCode: item.data.value,
      downRange:
        (item.startDate &&
          item.endDate &&
          `${item.startDate.getFullYear()}/${String(
            item.startDate.getMonth() + 1,
          ).padStart(2, '0')}~${item.endDate.getFullYear()}/${String(
            item.endDate.getMonth() + 1,
          ).padStart(2, '0')}`) ||
        null,
    }));
    applyState = true;
    const now = new Date();
    const target = new Date();
    target.setHours(18, 0, 0, 0); // 18:00:00.000

    // if (now.getTime() > target.getTime()) {
    //   CustomSwal.fire({
    //     title: '확인',
    //     text: '18시 이후에는 이용신청이 불가능 합니다.',
    //   });
    //   return;
    // }
    const data = {
      useRoom: activeStep,
      useDate: selectDate,
      useTime: am && pm ? 'am,pm' : am && !pm ? 'am' : !am && pm ? 'pm' : null,
      useDataList: completeUseDataList,
    };
    const applyUseTime = data.useTime;

    const dataField = [
      { state: data.useDate, message: '이용날짜를 입력해 주세요.' },
      { state: applyUseTime, message: '이용시간을 선택해 주세요.' },
      {
        state: data.useRoom,
        message: '이용하실 오픈랩 분석실을 선택해 주세요.',
      },
    ];
    if (NullCheckUtils.isNullAndDisplaySwal(dataField)) {
      return;
    }
    // console.log('passed ::::: ', data);
    // console.log('files ? ::::: ', fileUpload);
    // console.log('what ? ::::: ', numUploads);
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob([JSON.stringify(data)], { type: 'application/json' }),
    );

    if (fileList.length > 0)
      fileList.forEach((file, index) => {
        formData.append(`atchFiles`, file);
      });

    // api 호출부분
    apiUserApplyUseBigdata(formData)
      .then(response => {
        CustomSwal.fire({
          title: '신청 완료',
          text: '데이터 이용신청이 완료되었습니다.',
        });
        applyState = false;
        navigate(-1);
      })
      .catch(error => {
        handleError(error);
      });
  };
  const bigDataTypeListApi = () => {
    try {
      getBigdataTypeList().then(response => {
        if (response.data.message === RESULT_CODE.CODE_SUCCESS) {
          const bigdataClassArray = response.data.data.map(item => ({
            value: item.subCode,
            label: item.codeName,
          }));
          setBigdataClassList(bigdataClassArray);
        }
      });
    } catch (error) {
      handleError(error);
    }
  };

  const bigDataListApi = () => {
    const bigdata = {
      page: 1,
      size: 10000,
      total: 0,
    };
    try {
      getBigDataList(bigdata).then(response => {
        if (response.data.message === RESULT_CODE.CODE_SUCCESS) {
          setBigdataList(response.data.data.list);
          bigDataTypeListApi();
        }
      });
    } catch (error) {
      handleError(error);
    }
  };
  const holidayListApi = () => {
    const data = {
      page: 1,
      size: 10000,
    };
    try {
      getHolidayList(data).then(response => {
        if (response.data.message === RESULT_CODE.CODE_SUCCESS) {
          console.log('response data :::: ', response.data.data);
          const responseData = response.data.data;
          const holidays = responseData
            .filter(item => item.useYn === 'Y')
            .map(item => ({
              date: new Date(
                new Date().getFullYear(),
                parseInt(item.subCode.slice(0, 2), 10) - 1,
                parseInt(item.subCode.slice(2, 4), 10),
              ),
              desc: item.codeName,
              subCode: item.subCode,
            }));
          setHolidayList(holidays);
        }
      });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    bigDataListApi();
    holidayListApi();
  }, []);

  // useEffect(() => {
  //   console.log('useDataListModify:::: ', useDataListModify);
  // }, [useDataListModify]);

  useEffect(() => {
    console.log('holidayList:::: ', holidayList);
  }, [holidayList]);

  return (
    <div>
      <Filter
        title="Notice Board"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.application}
        // isSearch
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>이용 신청</p>
            <div className="divider_title" />
          </div>

          <div className="form_box">
            <div className="content_form_">
              <div
                className="flex align-center"
                style={{ margin: 0, columnGap: 24 }}
              >
                <div className="title_form">이용날짜</div>
                <input
                  type="date"
                  className="ipt_time"
                  value={selectDate || ''}
                  min={todayParsed}
                  onChange={e => handleDateChange(e)}
                />
              </div>

              <div
                className="flex align-center"
                style={{ margin: 0, columnGap: 24, marginTop: 40 }}
              >
                <div className="title_form">이용시간</div>

                <button
                  type="button"
                  onClick={() => setAm(!am)}
                  className={am ? 'btn_weather active_btn' : 'btn_weather'}
                >
                  <img src={images.sunny} alt="sunny" />
                  <span>오전</span>
                </button>

                <button
                  type="button"
                  style={{ marginLeft: 16 }}
                  onClick={() => setPm(!pm)}
                  className={pm ? 'btn_weather active_btn' : 'btn_weather'}
                >
                  <img src={images.moon} alt="moon" />
                  <span>오후</span>
                </button>
              </div>

              <div
                className="flex align-center"
                style={{ margin: 0, columnGap: 14, marginTop: 40 }}
              >
                <div className="title_form">오픈랩 분석실</div>
                <div className="box_step_form">
                  <span
                    onClick={() => setActiveStep(1)}
                    className={activeStep === 1 ? 'active_btn' : ''}
                  >
                    1
                  </span>
                  <span
                    onClick={() => setActiveStep(2)}
                    className={activeStep === 2 ? 'active_btn' : ''}
                  >
                    2
                  </span>
                  <span
                    onClick={() => setActiveStep(3)}
                    className={activeStep === 3 ? 'active_btn' : ''}
                  >
                    3
                  </span>
                  <span
                    onClick={() => setActiveStep(4)}
                    className={activeStep === 4 ? 'active_btn' : ''}
                  >
                    4
                  </span>
                  <span
                    onClick={() => setActiveStep(5)}
                    className={activeStep === 5 ? 'active_btn' : ''}
                  >
                    5
                  </span>
                </div>
              </div>

              <div
                className="flex"
                style={{
                  margin: 0,
                  columnGap: 14,
                  alignItems: 'baseline',
                  marginTop: 40,
                }}
              >
                <span className="label_inp">반입 파일</span>
                <div className="flex-column">
                  {/* {[...Array(numUploads)].map((_, i) => renderUpload(i + 1))} */}
                  <div
                    className="box_clone box_clone_2500"
                    style={{ margin: 0 }}
                  >
                    {fileList &&
                      fileList.length > 0 &&
                      fileList?.map((file, index) => (
                        <div
                          key={`upload_file_${file.name}_${file.lastModified}_${index}`}
                          className="file_upload"
                        >
                          <img src={images.file} alt="file" />
                          {file.name}
                          <img
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCloseClick(file)}
                            src={images.iconClose}
                            alt="close"
                          />
                        </div>
                      ))}
                    <div
                      className="flex align-center inp_upload_2500"
                      style={{ columnGap: '1rem', margin: 0 }}
                    >
                      <div
                        className="inp_upload selector"
                        onClick={() =>
                          document.getElementById('inp_file_2500').click()
                        }
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="inp_file_2500">파일선택</label>
                        <input
                          onChange={e => handleChangeUpload(e)}
                          id="inp_file_2500"
                          hidden
                          type="file"
                          multiple
                        />
                      </div>

                      <span className="pre_001">
                        확장자 xls, xlsx, pdf 파일 및 이미지 파일을 업로드
                        해주세요. <br /> 최대 파일 3개까지 첨부 가능합니다.{' '}
                        <br /> 최대 30MB
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex"
                style={{
                  margin: 0,
                  columnGap: 14,
                  alignItems: 'baseline',
                  marginTop: 40,
                }}
              >
                <span className="label_inp">데이터선택</span>
                <div
                  className="flex-column"
                  style={{ width: '100%', paddingLeft: 10 }}
                >
                  {/* {[...Array(numUploads)].map((_, i) => renderUpload(i + 1))} */}
                  <Button
                    className="data_btn"
                    onClick={() => {
                      addUseBigdataRow();
                    }}
                  >
                    데이터 추가
                  </Button>

                  <table
                    className="list-table responsive-table"
                    style={{ marginTop: 10 }}
                  >
                    <thead>
                      <tr>
                        <th className="col-auto">데이터 분야</th>
                        <th className="col-auto">데이터 이름</th>
                        <th className="col-auto">조사 시간 범위</th>
                        <th className="col-auto">신청 시간 범위</th>
                        <th className="col-auto">삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {useDataListModify.map((item, index) => (
                        <tr key={`table_info_${index}`}>
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <td data-label="데이터 분야">
                            <div
                              style={{
                                paddingBottom: '5px',
                                paddingTop: '5px',
                              }}
                            >
                              <Select
                                options={bigdataClassList}
                                value={item.dataClass}
                                onChange={e => handleDataClassChange(index, e)}
                              />
                            </div>
                          </td>
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <td data-label="데이터 이름">
                            <div
                              style={{
                                paddingBottom: '5px',
                                paddingTop: '5px',
                              }}
                            >
                              <Select
                                options={item.dataSelectList}
                                value={item.data}
                                isDisabled={!item.dataSelect}
                                onChange={e => handleDataChange(index, e)}
                              />
                            </div>
                          </td>
                          <td data-label="조사 시간 범위">
                            <div
                              style={{
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {item.data.zdateFrom && item.data.zdateTo && (
                                <>
                                  {item.data.zdateFrom}
                                  <div>{' ~ '}</div>
                                  {item.data.zdateTo}
                                </>
                              )}
                            </div>
                          </td>
                          <td data-label="신청 시간 범위">
                            {item.data.zdateFrom && item.data.zdateTo && (
                              <div
                                style={{
                                  paddingBottom: '5px',
                                  paddingTop: '5px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <MyCustomDatePicker
                                  value={item.startDate}
                                  onChange={e => changeStartDate(index, e)}
                                  maxWidth="100px"
                                  dateFormat="yyyy.MM"
                                  showMonthYearPicker
                                  minDate={item.minDate}
                                  maxDate={item.endDate}
                                />
                                <div>{' ~ '}</div>
                                <MyCustomDatePicker
                                  value={item.endDate}
                                  onChange={e => changeEndDate(index, e)}
                                  maxWidth="100px"
                                  dateFormat="yyyy.MM"
                                  showMonthYearPicker
                                  minDate={item.startDate}
                                  maxDate={item.maxDate}
                                />
                              </div>
                            )}
                          </td>
                          <td data-label="삭제">
                            <div
                              className="each-row center"
                              style={{
                                paddingBottom: '5px',
                                paddingTop: '5px',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                className="delete_btn"
                                onClick={() => deleteUseBigdataRow(index)}
                              >
                                줄 삭제
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: 233 }} className="edit_btn">
            <button
              type="button"
              style={{ width: 117 }}
              className="btn_login"
              onClick={() => applyUseBigdata()}
            >
              신청
            </button>

            <button
              type="button"
              className="btn_login"
              onClick={() => navigate(-1)}
            >
              취소
            </button>
          </div>
        </div>
      </section>
      <style jsx>{`
        @media (max-width: 1080px) {
          .responsive-table thead {
            display: none;
          }

          .responsive-table tbody,
          .responsive-table tr,
          .responsive-table td {
            display: block;
            width: 100%;
          }

          .responsive-table tr {
            margin-bottom: 15px;
            border: 1px solid #ccc; /* 경계선 추가 */
          }

          .responsive-table td {
            text-align: left;
            padding: 10px;
            position: relative;
            box-sizing: border-box; /* 패딩을 포함한 전체 너비 계산 */
          }

          .responsive-table td::before {
            content: attr(data-label);
            display: block;
            font-weight: bold;
            margin-bottom: 5px; /* 레이블과 콘텐츠 사이의 간격 */
          }

          .responsive-table td:nth-child(1)::before {
            content: '데이터 분야';
          }

          .responsive-table td:nth-child(2)::before {
            content: '데이터 이름';
          }

          .responsive-table td:nth-child(3)::before {
            content: '조사 시간 범위';
          }

          .responsive-table td:nth-child(4)::before {
            content: '신청 시간 범위';
          }

          .responsive-table td:nth-child(5)::before {
            content: '삭제';
          }

          .responsive-table select,
          .responsive-table input {
            width: 100%; /* 선택 요소와 입력 필드가 전체 너비를 사용하도록 설정 */
            box-sizing: border-box; /* 패딩을 포함한 전체 너비 계산 */
          }
        }
      `}</style>
    </div>
  );
}

export default AppUse;
