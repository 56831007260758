const RouterPath = {
  slash: '/',
  signin: '/auth/signin',
  // signin2: '/auth/signin2',
  // pwdchange: '/auth/pwdchange',
  // memberList: '/member/list',
  LoginCallbackGoogle: '/google-callback',
  LoginCallbackKakao: '/kakao-callback',

  // 에러
  Errors: '/errors',

  Landing: '/landing',

  // 메인페이지
  Home: '/home',

  login: '/login',
  findId: '/user/find-id',
  register: '/user/register',
  emailCert: '/user/email-certificate',
  changePassword: '/user/change-password',
  userModify: '/user/modify',

  termCondition: '/term-condition',
  privacyPolicy: '/privacy-policy',
  termUse: '/term-use',

  centerGuide: '/center',
  spaceGuide: '/space',
  facilityGuide: '/facility',
  chartGuide: '/chart',
  mapGuide: '/map',

  bigdataList: '/bigdata/list',
  bigdataDetail: '/bigdata/detail',
  introDataLab: '/intro-data-lab',
  operation: '/data-lab-operation',
  analysis: '/data-lab-analysis',
  application: '/data-lab-application',
  pledge: '/pledge',
  dataApply: '/data-apply',
  appuselist: '/data-apply-use-list',

  supportLead: '/support-lead',
  supportCollabo: '/support-collabo',
  supportPractice: '/support-practice',
  supportInnovation: '/support-innovation',
  supportDetail: '/support-detail',

  boardDetail: '/board-detail',
  newsList: '/newsletter/list',
  newsDetail: '/newsletter/detail',
  noticeList: '/notice/list',
  noticeDetail: '/notice/detail',
  qnaList: '/qna/list',
  qnaDetail: '/qna/detail',
  qnaWrite: '/qna/write',
  businessList: '/business/list',
  businessDetail: '/business/detail',
  businessApply: '/business/apply',
  photoNewsList: '/photo-news/list',
  photoNewsDetail: '/photo-news/detail',
  useCase: '/use-case',
  useCaseDetail: '/use-case/detail',
  referenceList: '/reference/list',
  referenceDetail: '/reference/detail',
  subscribeNewsletter: '/newsletter/subscribe',
  reportList: '/report/list',
  reportDetail: '/report/detail',
};

export default RouterPath;
