import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import { RouterPath } from '../../common';
import { apiUserLogin } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

function Login() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pwd, setPwd] = useState('');
  const userLogin = () => {
    const loginData = {
      loginId: id,
      password: pwd,
    };
    // console.log('login data:::: ', loginData);

    apiUserLogin(loginData)
      .then(response => {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            accessToken: response.data.data.accessToken,
            refreshToken: response.data.data.refreshToken,
            grade: response.data.data.grade,
            userId: response.data.data.userId,
            userNm: response.data.data.userNm,
            emailCert: response.data.data.registerPw,
          }),
        );
        navigate(RouterPath.slash, { replace: true });
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg_login">
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>로그인</p>
            <div className="divider_title" />
          </div>

          <div className="box_login">
            <input
              type="text"
              placeholder="아이디(이메일 형식)를 입력하세요. "
              className="inp_id"
              value={id}
              onChange={e => setId(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  userLogin();
                }
              }}
            />
            <input
              type="password"
              placeholder="비밀번호를 입력하세요."
              className="inp_pw"
              value={pwd}
              onChange={e => setPwd(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  userLogin();
                }
              }}
            />

            <button
              type="button"
              onClick={() => userLogin()}
              className="btn_login"
            >
              로그인
            </button>
            <div
              className="find_id"
              onClick={() => navigate(RouterPath.findId)}
            >
              아이디/비밀번호 찾기
            </div>
            <div className="center_data">
              <img src={images.alertCircle} alt="alert_circle" />
              <span>빅데이터센터에 처음 오셨나요?</span>
            </div>

            <button
              type="button"
              className="btn_join"
              onClick={() => navigate(RouterPath.termCondition)}
            >
              회원가입
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
