import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common';
import CustomSwal from '../../components/CustomSwal';
import NullCheckUtils from '../../utils/NullCheckUtils';
import { apiUserWriteQna } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

function QAWrite(props) {
  const navigate = useNavigate();
  let applyState = false;

  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [publicYn, setPublicYn] = useState('N');

  const saveUserQna = () => {
    if (applyState) {
      return;
    }
    applyState = true;

    const data = {
      qnaTitle: title && title !== '' ? title : null,
      qnaBody: contents && contents !== '' ? contents : null,
      publicity: publicYn,
    };
    // console.log('request data :::: ', data);
    const dataField = [
      { state: data.qnaTitle, message: '제목을 입력해 주세요.' },
      { state: data.qnaBody, message: '내용을 입력해 주세요.' },
      {
        state: data.publicity,
        message: '공개 여부를 선택해 주세요.',
      },
    ];
    applyState = false;
    if (NullCheckUtils.isNullAndDisplaySwal(dataField)) {
      return;
    }

    // api 호출부분
    apiUserWriteQna(data)
      .then(response => {
        CustomSwal.fire({
          title: '등록 완료',
          text: 'Q&A 등록 완료되었습니다.',
        });
        applyState = false;
        navigate(-1);
      })
      .catch(error => {
        handleError(error, navigate);
      });
  };

  const checkHandle = e => {
    // console.log('checked :::: ', e);
    if (e.target.checked) setPublicYn('Y');
    else setPublicYn('N');
  };

  return (
    <div>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.qnaList}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>질의 응답</p>
            <div className="divider_title" />
          </div>
          <form>
            <div className="create_qa_content">
              <div className="section_create_1">
                <div className="th1">
                  <p className="td1">제목</p>
                  <div className="td1_right">
                    <input
                      type="text"
                      placeholder=""
                      className="input_create"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                    <input
                      type="checkbox"
                      className="checkbox_create"
                      id="check_box"
                      value={publicYn}
                      checked={publicYn === 'Y'}
                      onChange={checkHandle}
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label
                      htmlFor="check_box"
                      className="label_checkbox_create"
                    >
                      공개
                    </label>
                  </div>
                </div>
                <div className="th2">질의 내용</div>
              </div>
              <textarea
                className="input_field"
                value={contents}
                onChange={e => setContents(e.target.value)}
              />
            </div>
            <div className="box_btn_notice">
              <button
                className="btn_save"
                type="button"
                onClick={() => saveUserQna()}
              >
                작성완료
              </button>

              <button
                className="btn_cancel"
                type="button"
                onClick={() => navigate(-1)}
              >
                취소
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default QAWrite;
