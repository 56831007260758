import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import Pagination from '../../components/Pagination';
import { RouterPath } from '../../common/constants';
import { apiGetNoticeList } from '../../api/RestAPI';
import Utils from '../../utils/Utils';
import { handleError } from '../../utils/HandleError';

function NoticeList() {
  const navigate = useNavigate();
  const location = useLocation();
  const today = new Date();

  const pageSize = 10;
  const initPage = location.state ? location.state.currentPage : 1;

  const [page, setPage] = useState(initPage);

  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const getNoticeList = currentPage => {
    const data = {
      page: currentPage,
      size: pageSize,
    };

    apiGetNoticeList(data)
      .then(response => {
        setList(response.data.data.list);
        setTotalCount(response.data.data.totalCnt);
        setTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };
  const navigateList = currentPage => {
    navigate(RouterPath.noticeList, {
      state: { currentPage },
    });
  };

  const navigateDetail = boardIdx => {
    navigate(`${RouterPath.noticeDetail}/${boardIdx}`);
  };

  useEffect(() => {
    getNoticeList(initPage);
    setPage(initPage);
  }, [location]);

  return (
    <>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.noticeList}
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>공지사항</p>
            <div className="divider_title" />
          </div>

          <table className="table_notice mobile-hide">
            <thead>
              <tr className="title_preview">
                <th className="c-1">번호</th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="c-1" />
                <th className="c-auto">제목</th>
                <th className="c-2">등록일</th>
                <th className="c-4">공고일</th>
                <th className="c-1">조회수</th>
              </tr>
            </thead>

            {list?.map((item, index) => (
              <tr
                style={{ cursor: 'pointer' }}
                key={`notice_item_${index}`}
                onClick={() => navigateDetail(item.boardIdx)}
                className="selector"
              >
                <td>{item.rowNum}</td>
                <td>
                  {Utils.isWithinAWeek(item.regDate) && (
                    <button type="button" className="btn_table_blue">
                      new
                    </button>
                  )}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {new Date(item.startDate) > today ? (
                    <button type="button" className="btn_table_gray">
                      예정
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={
                        Utils.isWithinDateRange(item.startDate, item.endDate)
                          ? 'btn_table_red'
                          : 'btn_table_gray'
                      }
                    >
                      {Utils.isWithinDateRange(item.startDate, item.endDate)
                        ? '진행'
                        : '완료'}
                    </button>
                  )}

                  <span>{item.noticeTitle}</span>
                </td>
                <td>{Utils.formatDate(item.regDate)}</td>
                <td>
                  {Utils.formatDate(item.startDate)} ~
                  {Utils.formatDate(item.endDate)}
                </td>
                <td>{item.countView}</td>
              </tr>
            ))}
          </table>
          {list?.map((item, index) => (
            <div className="list_data_notice mobile-only">
              <div
                className="item_notice selector"
                onClick={() => navigateDetail(item.boardIdx)}
              >
                {new Date(item.startDate) > today ? (
                  <button type="button" className="btn_table_gray">
                    예정
                  </button>
                ) : (
                  <button
                    type="button"
                    className={
                      Utils.isWithinDateRange(item.startDate, item.endDate)
                        ? 'btn_table_red'
                        : 'btn_table_gray'
                    }
                  >
                    {Utils.isWithinDateRange(item.startDate, item.endDate)
                      ? '진행'
                      : '완료'}
                  </button>
                )}
                {Utils.isWithinAWeek(item.regDate) && (
                  <button type="button" className="btn_table_blue">
                    new
                  </button>
                )}

                <div
                  className="f_pre_400_18 text_overflow_ellipsis_2"
                  style={{ margin: '8px 0 16px 0' }}
                >
                  {item.noticeTitle}
                </div>
                <div className="flex" style={{ gap: 12 }}>
                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">등록일</span>
                    <span className="f_pre_400_18">
                      {Utils.formatDate(item.regDate)}
                    </span>
                  </div>
                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">공고기간</span>
                    <span className="f_pre_400_18">
                      {Utils.formatDate(item.startDate)} ~
                      {Utils.formatDate(item.endDate)}
                    </span>
                  </div>
                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">조회수</span>
                    <span className="f_pre_400_18">{item.countView}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Pagination
            pageCount={totalPage}
            pageNum={page}
            setPage={setPage}
            onPageChangeCallback={navigateList}
          />
        </div>
      </section>
    </>
  );
}

export default NoticeList;
