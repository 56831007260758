/* eslint-disable no-param-reassign */
import { CustomException } from '../common/exceptions';
import CustomSwal from '../components/CustomSwal';

const NullCheckUtils = {
  isNullWithField: (exceptionFields, object, zeroIsTrue) => {
    exceptionFields.forEach(v => {
      if (!object[v.key]) {
        if (v.defaultValue) {
          object[v.key] = v.defaultValue;
        } else {
          if (zeroIsTrue && object[v.key] === 0) return;
          throw new CustomException(v.msg);
        }
      }
    });
  },
  isNullAndMaxLengthWithField: (exceptionFields, object, zeroIsTrue) => {
    const checkLength = (value, maxLength, name, isNotNull) => {
      if (value && value.length > maxLength) {
        throw new CustomException(errLengthMsg(name, maxLength));
      }
    };
    const errLengthMsg = (name, maxLength) => {
      return `${name} 길이가 초과했습니다. 최대 ${maxLength}자 이내로 작성해 주세요. `;
    };
    exceptionFields.forEach(v => {
      if (!object[v.key]) {
        if (v.defaultValue) {
          object[v.key] = v.defaultValue;
        } else {
          if (zeroIsTrue && object[v.key] === 0) return;
          throw new CustomException(v.msg);
        }
      } else if (v.maxLength) {
        checkLength(object[v.key], v.maxLength, v.name);
      }
    });
  },

  isNullAndDisplaySwal: checkFields => {
    // eslint-disable-next-line no-restricted-syntax
    for (const { state, message } of checkFields) {
      const checkCondition = item =>
        item === null ||
        item === undefined ||
        (typeof item === 'string' && !item.trim()) ||
        (Array.isArray(item) && item.length === 0);

      if (checkCondition(state)) {
        CustomSwal.fire({
          title: '확인',
          text: message,
        });
        return true;
      }
    }
    return false;
  },
  isLimitAndDisplaySwal: checkField => {
    let checkMessage = '';

    if (checkField.state.length > checkField.size) {
      checkMessage = checkField.message;
    }

    if (checkMessage) {
      CustomSwal.fire({
        title: '확인',
        text: checkMessage,
      });
      return true;
    }
    return false;
  },
};

export default NullCheckUtils;
