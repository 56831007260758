import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath, Utils } from '../../common';
import { apiCountUpLibrary, apiGetLibraryDetail } from '../../api/RestAPI';
import FileDownloadLocalText from '../../components/utilComponents/FileDownloadLocalText';
import { CustomSwal } from '../../components';
import { handleError } from '../../utils/HandleError';

function ReferenceRoomDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const [detail, setDetail] = useState([]);
  const { boardIdx } = useParams();

  const getLibraryDetail = libraryIdx => {
    apiGetLibraryDetail(libraryIdx)
      .then(response => {
        setDetail(response.data.data);
        libraryViewCount(libraryIdx);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const libraryViewCount = () => {
    apiCountUpLibrary(boardIdx).catch(error => {
      CustomSwal.fire({
        title: '알림',
        text: error.message,
      });
    });
  };

  useEffect(() => {
    getLibraryDetail(boardIdx);
  }, []);

  return (
    <div>
      <Filter
        title="Data Room"
        mainMenu="활용사례"
        subMenu={RouterPath.referenceList}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>자료실</p>
            <div className="divider_title" />
          </div>
          <div className="detail_notice_content mobile-hide">
            <div className="section1">
              <div className="th1">
                <p className="content1">{detail.libraryTitle}</p>
                <div className="content1_right">
                  <div className="item_right">
                    <div className="label">등록일</div>
                    <div className="value">
                      {Utils.formatDate(detail.regDate)}
                    </div>
                  </div>
                  <div className="item_right">
                    <div className="label">조회수</div>
                    <div className="value">{detail.countView}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section2">
              <div
                dangerouslySetInnerHTML={{
                  __html: detail.libraryBody,
                }}
              />
            </div>
            <div className="section3">
              {detail?.atchFiles?.map((item, index) => (
                <div key={`library_attach_files_${index}`} className="th">
                  <div className="label">첨부파일</div>
                  <div className="content2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19.903 8.586C19.8556 8.47747 19.7892 8.37825 19.707 8.293L13.707 2.293C13.6217 2.21078 13.5225 2.14441 13.414 2.097C13.384 2.083 13.352 2.075 13.32 2.064C13.2363 2.03553 13.1492 2.01837 13.061 2.013C13.04 2.011 13.021 2 13 2H6C4.897 2 4 2.897 4 4V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V9C20 8.979 19.989 8.96 19.987 8.938C19.9821 8.84972 19.9649 8.76255 19.936 8.679C19.926 8.647 19.917 8.616 19.903 8.586ZM16.586 8H14V5.414L16.586 8ZM6 20V4H12V9C12 9.26522 12.1054 9.51957 12.2929 9.70711C12.4804 9.89464 12.7348 10 13 10H18L18.002 20H6Z"
                        fill="#777777"
                      />
                      <path
                        d="M8 12H16V14H8V12ZM8 16H16V18H8V16ZM8 8H10V10H8V8Z"
                        fill="#777777"
                      />
                    </svg>

                    <FileDownloadLocalText
                      fileNm={item.originalFileName}
                      fileUrl={`${item.uploadedFilePath}${item.storageFileName}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* mobile screen */}
          <div className="detail_notice_content mobile-only">
            <div className="section1">
              <div className="th1">
                <p className="content1">{detail.libraryTitle}</p>
              </div>
              <div
                className="flex-column"
                style={{ borderTop: '1px solid #CCCCCC' }}
              >
                <div
                  className="item_right flex align-center"
                  style={{ columnGap: 8, padding: 16 }}
                >
                  <div className="label f_20">등록일</div>
                  <div className="value f_400_20">
                    {Utils.formatDate(detail.regDate)}
                  </div>
                </div>
                <div
                  className="item_right flex align-center"
                  style={{
                    columnGap: 8,
                    borderTop: '1px solid #CCCCCC',
                    padding: 16,
                  }}
                >
                  <div className="label f_20">조회수</div>
                  <div className="value f_400_20">{detail.countView}</div>
                </div>
              </div>
            </div>
            <div className="section2">
              <div className="content1">
                <div
                  className="value1"
                  dangerouslySetInnerHTML={{
                    __html: Utils.shortenText(detail.libraryBody),
                  }}
                />
              </div>
            </div>
            <div className="section3">
              {detail?.atchFiles?.map((item, index) => (
                <div key={`library_attach_files_${index}`} className="th">
                  <div className="label">첨부파일</div>
                  <div className="content2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19.903 8.586C19.8556 8.47747 19.7892 8.37825 19.707 8.293L13.707 2.293C13.6217 2.21078 13.5225 2.14441 13.414 2.097C13.384 2.083 13.352 2.075 13.32 2.064C13.2363 2.03553 13.1492 2.01837 13.061 2.013C13.04 2.011 13.021 2 13 2H6C4.897 2 4 2.897 4 4V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V9C20 8.979 19.989 8.96 19.987 8.938C19.9821 8.84972 19.9649 8.76255 19.936 8.679C19.926 8.647 19.917 8.616 19.903 8.586ZM16.586 8H14V5.414L16.586 8ZM6 20V4H12V9C12 9.26522 12.1054 9.51957 12.2929 9.70711C12.4804 9.89464 12.7348 10 13 10H18L18.002 20H6Z"
                        fill="#777777"
                      />
                      <path
                        d="M8 12H16V14H8V12ZM8 16H16V18H8V16ZM8 8H10V10H8V8Z"
                        fill="#777777"
                      />
                    </svg>

                    <FileDownloadLocalText
                      fileNm={item.originalFileName}
                      fileUrl={`${item.uploadedFilePath}${item.storageFileName}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* mobile screen */}

          <button
            type="button"
            className="btn_preview"
            onClick={() => navigate(-1)}
          >
            목록보기
          </button>
        </div>
      </section>
    </div>
  );
}

export default ReferenceRoomDetail;
