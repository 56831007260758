import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RouterPath from '../../common/constants/RouterPath';
import {
  apiGetUserApplyList,
  apiGetUserMyInfo,
  apiModifyUserMyInfo,
  apiGetBusinessyList,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import CustomSwal from '../../components/CustomSwal';
import CASE_TYPE from '../../common/constants/CaseType';
import Utils from '../../utils/Utils';
import Pagination from '../../components/Pagination';

function EditMember() {
  const navigate = useNavigate();
  const location = useLocation();
  const [myInfoData, setMyInfoData] = useState(null);
  const [myId, setMyId] = useState('');
  const [myDept, setMyDept] = useState('');
  const [myName, setMyName] = useState('');
  const [myEmail, setMyEmail] = useState('');

  const pageSize = 10;
  const initPage = location.state ? location.state.currentPage : 1;
  const initTab = location.state ? location.state.activeTab : 1;
  // 페이지 추가
  const [activeTab, setActiveTab] = useState(initTab);
  const [topYn, setTopYn] = useState('Y');
  const [currentType, setCurrentType] = useState('');
  const [page, setPage] = useState(1);
  const [caseList, setCaseList] = useState([]);

  // 이용신청내역
  const [applylist, setApplylist] = useState([]);
  const [applytotalPage, setApplyTotalPage] = useState(0);
  const [applytotalCount, setApplyTotalCount] = useState(0);
  const [applypage, setApplyPage] = useState(initPage);

  // 사업장신청 목록확인
  const [businesslist, setBusinesslist] = useState([]);
  const [businesstotalPage, setBusinessTotalPage] = useState(0);
  const [businesstotalCount, setBusinessTotalCount] = useState(0);
  const [businesspage, setBusinessPage] = useState(initPage);

  const navigateList = currentPage => {
    navigate(RouterPath.userModify, {
      state: { currentPage, activeTab },
    });
  };

  const handleTabClick = index => {
    navigate(RouterPath.userModify, {
      state: { currentPage: 1, activeTab: index },
    });
  };

  const getUserMyInfo = () => {
    apiGetUserMyInfo()
      .then(response => {
        setMyInfoData(response.data.data);
        setMyId(
          response.data.data?.userLoginId ? response.data.data.userLoginId : '',
        );
        setMyDept(
          response.data.data?.deptName ? response.data.data.deptName : '',
        );
        setMyName(
          response.data.data?.userName ? response.data.data.userName : '',
        );
        setMyEmail(
          response.data.data?.userEmail ? response.data.data.userEmail : '',
        );
      })
      .catch(error => {
        handleError(error);
      });
  };

  const modifyUserMyInfo = () => {
    const data = {
      userLoginId: myId,
      userName: myName,
      userDept: myDept,
      userEmail: myEmail,
    };
    apiModifyUserMyInfo(data)
      .then(response => {
        CustomSwal.fire({
          title: '회원 정보 수정',
          text: '회원 정보가 수정되었습니다.',
        });
        getUserMyInfo();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getApplyList = currentPage => {
    const data = {
      page: currentPage,
      size: pageSize,
    };
    apiGetUserApplyList(data)
      .then(response => {
        setApplylist(response.data.data.list);
        setApplyTotalCount(response.data.data.totalCnt);
        setApplyTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getBusinessList = currentPage => {
    const data = {
      page: currentPage,
      size: pageSize,
    };
    apiGetBusinessyList(data)
      .then(response => {
        setBusinesslist(response.data.data.list);
        setBusinessTotalCount(response.data.data.totalCnt);
        setBusinessTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions,no-sequences
    if (localStorage.getItem('auth')) getUserMyInfo();
    else navigate(RouterPath.slash, { replace: true });
  }, []);
  useEffect(() => {
    setActiveTab(initTab);
    if (activeTab === 1) {
      setTopYn('');
      setCurrentType(CASE_TYPE.MY_PAGE.code);
    } else if (activeTab === 2) {
      setTopYn('');
      setCurrentType(CASE_TYPE.APP_USE_LIST.code);
      getApplyList(initPage);
      setApplyPage(initPage);
    } else if (activeTab === 3) {
      setTopYn('');
      setCurrentType(CASE_TYPE.BUSINESS_APLLY_LIST.code);
      getBusinessList(initPage);
      setBusinessPage(initPage);
    }
  }, [location.state]);

  useEffect(() => {
    if (!localStorage.getItem('auth'))
      navigate(RouterPath.slash, { replace: true });
  }, [localStorage.getItem('auth')]);

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>

      <section className="case-center">
        <div className="container">
          <article id="cont" className="tabList">
            <nav>
              <ul className="tabMenu">
                <li
                  className={activeTab === 1 ? 'on' : ''}
                  onClick={() => handleTabClick(1)}
                >
                  <h2 className="t_tab01">
                    <p>통합 계정 회원 정보</p>
                  </h2>
                </li>
                <li
                  className={activeTab === 2 ? 'on' : ''}
                  onClick={() => handleTabClick(2)}
                >
                  <h2 className="t_tab01">
                    <p>이용신청내역</p>
                  </h2>
                </li>
                <li
                  className={activeTab === 3 ? 'on' : ''}
                  onClick={() => handleTabClick(3)}
                >
                  <h2 className="t_tab01">
                    <p className="none-after">사업 신청 목록 확인</p>
                  </h2>
                </li>
              </ul>
            </nav>
          </article>
          <div className="divider_title position-center " />
          {activeTab === 1 && (
            <>
              <div className="traffic_map" style={{ marginTop: '0' }}>
                회원정보수정
              </div>
              <div className="box_edit_member">
                <div className="flex align-center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>아이디</label>
                  <input
                    type="text"
                    // placeholder="qwertasdfzxcv@naver.com"
                    className="inp_id"
                    value={myId}
                    onChange={e => setMyId(e.target.value)}
                    disabled
                  />
                </div>
                <div className="flex align-center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>회사명</label>
                  <input
                    type="text"
                    // placeholder="삼성전자"
                    className="inp_id"
                    value={myDept}
                    onChange={e => setMyDept(e.target.value)}
                  />
                </div>
                <div className="flex align-center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>이름</label>
                  <input
                    type="text"
                    // placeholder="홍길동"
                    className="inp_id"
                    value={myName}
                    onChange={e => setMyName(e.target.value)}
                  />
                </div>
                <div className="flex align-center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>비밀번호</label>
                  <input id="edit_pw" type="text" disabled className="inp_id" />

                  <button
                    type="button"
                    onClick={() => navigate(RouterPath.changePassword)}
                    className="btn_outline"
                  >
                    비밀번호 변경
                  </button>
                </div>
                <div className="flex align-center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>인증용 이메일</label>
                  <input
                    type="text"
                    // placeholder="아이디(이메일 형식)"
                    className="inp_id"
                    value={myEmail}
                    onChange={e => setMyEmail(e.target.value)}
                  />
                </div>
                <div style={{ marginTop: 27 }} className="flex align-center">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>이메일 인증</label>
                  {myInfoData?.userEmailCrtf === 'Y' && (
                    <span className="badge_edit">인증완료</span>
                  )}
                  {myInfoData?.userEmailCrtf === 'N' && (
                    <span className="badge_edit_no_crtf">인증미완</span>
                  )}
                </div>
              </div>

              <div className="edit_btn" style={{ marginBottom: '57px' }}>
                <button
                  type="button"
                  onClick={() => modifyUserMyInfo()}
                  className="btn_login"
                >
                  회원정보수정
                </button>

                <button type="button" className="btn_login">
                  취소
                </button>
              </div>
            </>
          )}
          {activeTab === 2 && (
            <>
              <table className="table_notice mobile-hide">
                <thead>
                  <tr className="title_preview">
                    <th className="c-auto">번호</th>
                    <th className="c-auto">오픈랩분석실</th>
                    <th className="c-auto">이용신청일</th>
                    <th className="c-auto">이용신청시간</th>
                    <th className="c-auto">승인여부</th>
                  </tr>
                </thead>
                <tbody>
                  {applylist.length > 0 ? (
                    applylist.map((item, index) => (
                      <tr key={index}>
                        <td className="c-auto">{item.applyIdx}</td>
                        <td className="c-auto">{item.useRoom}</td>
                        <td className="c-auto">
                          {Utils.formatDate(item.useDate) || '-'}
                        </td>
                        <td className="c-auto">
                          {item.useTime === 'am'
                            ? '오전'
                            : item.useTime === 'pm'
                            ? '오후'
                            : item.useTime === 'am,pm'
                            ? '종일'
                            : '-'}
                        </td>
                        <td className="c-auto">
                          {item.approveState === 'OK'
                            ? '승인완료'
                            : item.approveState === 'REJ'
                            ? '승인거절'
                            : item.approveState === 'WAIT'
                            ? '승인대기'
                            : item.approveState === 'EXP'
                            ? '기간만료'
                            : '-'}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div>빅데이터 이용 신청 정보가 없습니다.</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                pageCount={applytotalPage}
                pageNum={applypage}
                setPage={setApplyPage}
                onPageChangeCallback={navigateList}
              />
            </>
          )}
          {activeTab === 3 && (
            <>
              <table className="table_notice mobile-hide">
                <thead>
                  <tr className="title_preview">
                    <th className="c-3">공고명</th>
                    <th className="c-2">신청기업명</th>
                    <th className="c-2">신청접수기간</th>
                    <th className="c-1">신청일</th>
                    <th className="c-1">신청상태</th>
                  </tr>
                </thead>
                <tbody>
                  {businesslist.length > 0 ? (
                    businesslist.map((item, index) => (
                      <tr key={index}>
                        <td className="c-3">
                          {Utils.shortenText(item.businessTitle, 100) || '-'}
                        </td>
                        <td className="c-2">{item.companyName || '-'}</td>
                        <td className="c-2">{item.startDate || '-'}</td>
                        <td className="c-1">
                          {Utils.formatDate(item.regDate) || '-'}
                        </td>
                        <td className="c-1">
                          {item.aprvStatus === 'OK'
                            ? '접수완료'
                            : item.aprvStatus === 'REQ'
                            ? '접수중'
                            : '-'}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div>사업 신청 정보가 없습니다.</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                pageCount={businesstotalPage}
                pageNum={businesspage}
                setPage={setBusinessPage}
                onPageChangeCallback={navigateList}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default EditMember;
