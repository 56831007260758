import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { images } from '../assets';

function SwiperDataCenter02() {
  const sliderRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sliderRef.current) {
      const swiper = new Swiper(sliderRef.current, {
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 500,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next-center',
          prevEl: '.swiper-button-prev-center',
        },
        breakpoints: {
          1400: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          765: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          568: {
            slidesPerView: 1,
          },
          465: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        },
      });

      /* Clean up Swiper when component unmounts */
      return () => {
        swiper.destroy();
      };
    }
  }, []);

  return (
    <div className="slide_type03">
      <div ref={sliderRef} className="slider_item03">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img src={images.center9} alt="Busan Logo" />
          </div>
          <div className="swiper-slide">
            <img src={images.center10} alt="Busan Logo" />
          </div>
        </div>
        <div className="ar_w03">
          <div className="swiper-button-next swiper-button-next-center" />
          <div className="swiper-button-prev swiper-button-prev-center" />
        </div>
      </div>
    </div>
  );
}

export default SwiperDataCenter02;
