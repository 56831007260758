import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import { apiGetSupportDetail, apiSupportViewCount } from '../../api/RestAPI';
import { CustomSwal } from '../../components';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

function SupportDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [support, setSupport] = useState({});

  const { boardIdx } = useParams();

  useEffect(() => {
    getSupportDetail();
  }, []);

  const getSupportDetail = () => {
    apiGetSupportDetail(boardIdx)
      .then(response => {
        const supportData = response.data.data;
        setSupport(supportData);
        supportViewCount();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const supportViewCount = () => {
    apiSupportViewCount(boardIdx).catch(error => {
      CustomSwal.fire({
        title: '알림',
        text: error.message,
      });
    });
  };

  return (
    <div>
      <Filter
        title="Support Business"
        mainMenu="지원사업"
        subMenu={RouterPath.supportInnovation}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>지원사업 상세보기</p>
            <div className="divider_title" />
          </div>

          <div className="detail_notice_content">
            <div className="section1">
              <div className="th1">
                <p className="content1">{support.supportTitle}</p>
                <div className="content1_right">
                  <div className="item_right">
                    <div className="label">등록일</div>
                    <div className="value">
                      {Utils.formatDate(support.regDate)}
                    </div>
                  </div>
                  <div className="item_right">
                    <div className="label">조회수</div>
                    <div className="value">{support.countView}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detail_notice_box">
              {support.purpose && (
                <div className="notice_detail">
                  <span>사업목적</span>
                  <div>{support.purpose}</div>
                </div>
              )}
              {support.period && (
                <div className="notice_detail">
                  <span>사업일정</span>
                  <div>{support.period}</div>
                </div>
              )}
              {support.target && (
                <div className="notice_detail">
                  <span>사업대상</span>
                  <div>{support.target}</div>
                </div>
              )}
            </div>
            <div className="section2">
              <div
                className="content1"
                dangerouslySetInnerHTML={{
                  __html: Utils.shortenText(support.supportBody),
                }}
              />
            </div>
            <div className="section3" />
          </div>

          <button
            type="button"
            className="btn_preview"
            onClick={() => navigate(-1)}
          >
            목록보기
          </button>
        </div>
      </section>
    </div>
  );
}

export default SupportDetail;
