import React from 'react';
import Filter from '../components/Filter';
import { RouterPath } from '../common/constants';
import { images } from '../assets';

function SpaceGuide() {
  return (
    <div>
      <Filter
        title="Busan Big Data Innovation Center"
        mainMenu="센터소개"
        subMenu={RouterPath.spaceGuide}
      />
      <section className="center_info">
        <div className="container">
          <div className="center_title">
            <p>공간안내</p>
            <div className="divider_title" />
            <div className="center_title_002">
              부산 빅데이터 혁신센터 공간을 소개 합니다.
            </div>
          </div>
        </div>

        <div className="space-content">
          <div className="container">
            <div className="flex" style={{ columnGap: 20 }}>
              <div className="flex-column">
                <div className="item-space mobile-only">
                  <img
                    style={{ borderRadius: 20 }}
                    src={images.space}
                    alt="조감도"
                  />
                </div>
                <div className="item-space">
                  <img src={images.spaceSm01} alt="data01" />
                </div>
                <div className="item-space">
                  <img src={images.spaceSm02} alt="data01" />
                </div>
                <div className="item-space">
                  <img src={images.spaceSm03} alt="data01" />
                </div>
                <div className="item-space">
                  <img src={images.spaceSm04} alt="data01" />
                </div>
              </div>

              <div className="box-space mobile-hide">
                <img src={images.space} alt="space" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SpaceGuide;
