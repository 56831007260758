/* eslint-disable no-plusplus */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
import { format } from 'date-fns';
import { loadingAction } from '@reducers/lodingSlice';
import { store } from '../store';
import { LOADING_TYPE } from '../reducers/lodingSlice';
import CustomException from '../common/exceptions/CustomException';

const Utils = {
  // 인풋 입력숫자 콤마표기
  changeNumberComma: (str, canLastDot) => {
    // null 또는 undefined의 경우
    if (str === null || str === undefined) {
      return '';
    }

    // 숫자 '0'의 경우
    if (str === '0') {
      return 0;
    }

    // 숫자만 있는 경우 (ex. '1234' 또는 '1234.234')
    if (!canLastDot) {
      if (Utils.isNumber(str)) {
        const [integerPart, fractionPart] = String(str).split('.');
        const integerWithComma = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ',',
        );
        const text = fractionPart
          ? `${integerWithComma}.${fractionPart}`
          : integerWithComma;
        return text;
      }
    } else if (/^[0-9.]+$/.test(str)) {
      const [integerPart, fractionPart] = String(str).split('.');
      const integerWithComma = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      );
      const text =
        str.indexOf('.') > -1
          ? `${integerWithComma}.${fractionPart ?? ''}`
          : integerWithComma;

      return text;
    }

    // 그 외의 경우 (숫자가 아닌 경우)
    return '';
  },
  // 사업자등록번호 '-' 추가 포맷팅
  addHypenToLicenceNumber: value => {
    const licenceNum = value || '';
    // 문자열의 길이가 10인지 확인
    if (licenceNum.length === 10) {
      // 문자열을 세 부분으로 나누고 '-'를 사용하여 연결
      const formattedNumber = `${licenceNum.slice(0, 3)}-${licenceNum.slice(
        3,
        5,
      )}-${licenceNum.slice(5)}`;
      return formattedNumber;
    }
    // 10자리가 아니면 원래 문자열 반환
    return licenceNum;
  },
  // 전화번호 '-' 추가 포멧팅
  addHypenToPhoneNumber: value => {
    const phoneNum = value || '';
    // 문자열의 길이가 11인지 확인
    if (phoneNum.length === 11) {
      // 문자열을 세 부분으로 나누고 '-'를 사용하여 연결
      const formattedNumber = `${phoneNum.slice(0, 3)}-${phoneNum.slice(
        3,
        7,
      )}-${phoneNum.slice(7)}`;
      return formattedNumber;
    }
    // 11자리가 아니면 원래 문자열 반환
    return phoneNum;
  },
  // 숫자 콤마제거, 소수점 3자리까지
  removeComma: str => {
    const result = str.toString().replace(/,/g, '').toLocaleString(undefined, {
      maximumFractionDigits: 3,
    });
    return result;
  },
  calculateRowNumber: (totalCnt, page, size, i) => {
    return totalCnt - (page - 1) * size - i;
  },
  byteCheck: (value, maxByte) => {
    const textVal = value; // 입력한 문자
    const textLen = value.length; // 입력한 문자수
    let totalByte = 0;
    for (let i = 0; i < textLen; i += 1) {
      const eachChar = textVal.charAt(i);
      const uniChar = escape(eachChar); // 유니코드 형식으로 변환
      if (uniChar.length > 4) {
        // 한글 : 2Byte
        totalByte += 2;
      } else {
        // 영문,숫자,특수문자 : 1Byte
        totalByte += 1;
      }
    }
    return maxByte >= totalByte;
  },
  // 날짜 형식 변환 함수
  formatDate: dateString => {
    if (dateString) {
      const date = new Date(dateString);
      return format(date, 'yyyy.MM.dd');
    }
    return '-';
  },

  // 날짜 형식 변환 함수
  formatMonth: dateString => {
    if (dateString) {
      const date = new Date(dateString);
      return format(date, 'yyyy.MM');
    }
    return '-';
  },
  // 만료일자 검사
  isDateExpired: value => {
    const dateToCompare = new Date(value);

    // 오늘 날짜를 Date 객체로 생성
    const today = new Date();

    // 시간을 0으로 설정하여 시간 부분을 제거 (순수 날짜 비교를 위해)
    dateToCompare.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // 두 날짜를 비교
    if (dateToCompare.getTime() < today.getTime()) {
      return true;
    }
    if (dateToCompare.getTime() > today.getTime()) {
      return false;
    }
    return true;
  },
  isInteger: value => {
    if (typeof value === 'number' || typeof value === 'string') {
      if (value) {
        return /^\d+$/.test(value);
      }
      return true;
    }
    return false;
  },
  isNumber: value => {
    const regex = /^\d+(\.\d+)?$/;
    return regex.test(value);
  },

  replaceWithAsterisk(text) {
    if (!text) return '';
    const { length } = text;
    if (length <= 1) {
      return '*';
    }
    if (length === 2) {
      return `${text.charAt(0)}*`;
    }
    const hidden = new Array(length - 2).fill('*');
    return text.charAt(0) + hidden.join('') + text.charAt(length - 1);
  },

  // UTC -> Local Time
  convertUtcToLocalDate(utcTimeStr) {
    if (!utcTimeStr) return '';
    const date = new Date(utcTimeStr);
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    return localDate;
  },
  // UTC -> Local Time -> string
  convertUtcToLocalDateToString(utcTimeStr, formatStr = 'yyyy.MM.dd HH:mm:ss') {
    if (!utcTimeStr) return '';
    const date = new Date(utcTimeStr);
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    return format(localDate, formatStr);
  },
  // Local Time -> UTC
  convertLocalToUtcDate(localTimeStr) {
    if (!localTimeStr) return '';
    const date = new Date(localTimeStr);
    const offset = date.getTimezoneOffset() * 60000;
    const utcDate = new Date(date.getTime() + offset);
    return utcDate;
  },
  // Local Time -> UTC -> string
  convertLocalToUtcDateToString(
    localTimeStr,
    formatStr = 'yyyy.MM.dd HH:mm:ss',
  ) {
    if (!localTimeStr) return '';
    const date = new Date(localTimeStr);
    const offset = date.getTimezoneOffset() * 60000;
    const utcDate = new Date(date.getTime() + offset);
    return format(utcDate, formatStr);
  },
  handleFile: (newFile, isImg) => {
    const file = newFile;
    const preview = URL.createObjectURL(newFile);
    if (
      isImg &&
      !(
        file.type.includes('image/jpeg') ||
        file.type.includes('image/jpg') ||
        file.type.includes('image/png')
      )
    ) {
      throw new CustomException(
        '.jpg, .jpeg, .png 이미지만 등록할 수 있습니다.',
      );
    }
    return { file, preview, name: file.name };
  },
  getFileObj: (fileUrl, fileName) => {
    if (!fileUrl) return null;
    return {
      name: fileName,
      preview: fileUrl,
    };
  },
  fileOpen: fileUrl => {
    window.open(fileUrl);
  },
  validEmail: email => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  },
  validPw: pw => {
    /* eslint-disable no-useless-escape */
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[%^()_+\[\]@$!%*#?&<>'/])[A-Za-z\d%^()_+\[\]@$!%*#?&<>'/]{8,15}$/;
    return !!regex.test(pw);
  },
  validTwoFactPw: twoFactPw => {
    const regex = /^\d{4}$/;
    return regex.test(twoFactPw);
  },
  changeOnlyNumber: str => {
    if (str == null || str === undefined || str === '') return '';
    const regEx = /\D/g;
    return str.replace(regEx, '');
  },
  onlyNumberOrDot(numberIncludeStr) {
    let result = null;
    if (numberIncludeStr) {
      result = numberIncludeStr.replace(/[^0-9.]/g, '');
    }
    return result;
  },
  /**
   * 정수형 문자열을 반환
   */
  changeIntegerString(string) {
    let str = string;
    str += '';
    // 숫자 제외 문자열 제거
    str = str.replace(/[^0-9]/g, '');

    // 시작 부분 연속된 "0" 제거
    if (/[1-9]/.test(str)) {
      str = str.replace(/^0+/, '');
    }
    if (/^0+/.test(str)) {
      str = '0';
    }

    // if (str === '' || str === null) {
    //   str = '0';
    // }

    return str;
  },
  /**
   * 소수를 입력 받는 input을 위해 입력값을 소수 형태로 바꿔준다.
   * @param numberStr :: input 입력 값
   * @param commas :: comma 추가 여부
   */
  changeDecimalForInput(numberStr, fraction) {
    // sell mode 코인으로 선택시 최수 운용 금액 설정 :: 최소 운용 금액 = 평균 매수가 * 코인 수량
    const value = Utils.onlyNumberOrDot(`${numberStr}`);

    // value 설정
    const valueStr = `${value}`;
    if (!valueStr) return '';
    const count = (valueStr.match(/\./g) || []).length;

    if (count === 0) {
      return Utils.changeIntegerString(valueStr);
    }

    if (count === 1) {
      if (valueStr === '.') {
        return '0.';
      }
      const splitValue = valueStr.split('.');

      if (splitValue[1]) {
        const endIndex = fraction ?? splitValue[1].length;
        splitValue[1] = splitValue[1].substring(0, endIndex);
      }

      return `${Utils.changeIntegerString(splitValue[0])}.${splitValue[1]}`;
    }
    if (count > 1) {
      return '';
    }
    return '';
  },

  /**
   * 정수/소수를 파악하여 정수/소수 형태의 문자열을 반환하거나 또는 ','를 붙인 문자열을 반환한다.
   */
  changeDecimal(string, commas, fraction) {
    // 숫자, '.' 제외 제거
    let str = string;
    str += '';
    str = str.replace(/[^0-9.]/g, '');
    if (str.indexOf('.') !== -1) {
      /* 소수 */
      let resultStr = '';

      const dotCount = (str.match(/\./g) || []).length;
      if (dotCount > 1) {
        // 소수점이 2개 이상인 경우 2번째 소수점포함 뒤로 제거
        const firstTwoDotsIndex = str.indexOf('.', str.indexOf('.') + 1);
        str = str.substring(0, firstTwoDotsIndex);
      }

      // 뒷자리에 연속된 "0" 제거
      str = str.replace(/0+$/, '');

      const strSplit = str.split('.');

      // 정수 부분 숫자 제외 && 시작부분 연속된 "0" 문자열 제거
      let intNum = strSplit[0].replace(/[^0-9]/g, '').replace(/^0+/, '');
      if (intNum === '') {
        intNum = '0';
      }
      if (commas) {
        intNum = String(intNum).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      // 소수점 몇째자리까지 보여줄지 설정 :: 버림과 같다
      if (fraction) strSplit[1] = strSplit[1].substring(0, fraction);

      resultStr = intNum;
      if (strSplit[1]) resultStr = `${resultStr}.${strSplit[1]}`;

      return resultStr;
    }
    /* 정수 */
    // 숫자 제외 문자열 제거
    str = str.replace(/[^0-9]/g, '');
    // 시작 부분 연속된 "0" 제거
    str = str.replace(/^0+/, '');
    if (str === '') {
      str = '0';
    }

    // comma 추가
    if (commas) {
      str = String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return str;
  },
  showLoadingSpinner(msg = '', type = LOADING_TYPE.NORMAL) {
    store.dispatch(loadingAction.show({ msg, type }));
  },
  hideLoadingSpinner() {
    setTimeout(() => {
      store.dispatch(loadingAction.hide());
    }, 150);
  },
  getKeyByValue(object, value) {
    // eslint-disable-next-line no-shadow
    return Object.keys(object).find(key => object[key] === value);
  },
  /**
   * 데이터 검증
   */
  isValidPhoneNumber(phoneNumber) {
    const regex = /^\d{3}-\d{4}-\d{4}$/; // 전화번호 형식에 맞는 정규식 패턴
    return regex.test(phoneNumber); // 패턴과 매칭
  },
  shortenText(textString, lengthRequire) {
    const truncatedString =
      textString?.length > lengthRequire
        ? `${textString.substring(0, lengthRequire - 1)}...`
        : textString;
    return truncatedString;
  },

  isWithinDateRange(startDate, endDate) {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    const stripTime = date => {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const strippedToday = stripTime(today);
    const strippedStart = stripTime(start);
    const strippedEnd = stripTime(end);

    return strippedToday >= strippedStart && strippedToday <= strippedEnd;
  },
  strDateToFormatDate(dateString) {
    if (dateString.length === 6) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      return `${year}.${month}`;
    }
    if (dateString.length === 8) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      return `${year}.${month}.${day}`;
    }
    const year = dateString.substring(0, 4);
    return `${year}`;
  },
  stripHtmlTags(str) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str;
    return tempDiv.textContent || tempDiv.innerText || '';
  },
  isWithinAWeek(date) {
    const now = new Date();
    const regDate = new Date(date);

    const oneWeekBefore = new Date(now);
    oneWeekBefore.setDate(now.getDate() - 7);

    return regDate >= oneWeekBefore && regDate <= now;
  },
  sanitizeHTML(html) {
    const tempDiv = document.createElement('div');
    tempDiv.textContent = html;
    return tempDiv.innerHTML;
  },
  anonymizeName(name) {
    if (!name || name.length <= 2) {
      return name || '*';
    }

    const visiblePart =
      name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];

    return visiblePart;
  },
  truncateText(selector, maxLength, maxWidth) {
    document.querySelectorAll(selector).forEach(element => {
      const text = element.textContent;
      if (element.offsetWidth > maxWidth) {
        // eslint-disable-next-line no-param-reassign
        element.textContent = `${text.substring(0, maxLength)}...`;
      }
    });
  },
};

export default Utils;
