import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Filter from '../../components/Filter';
import { RouterPath, Utils } from '../../common';
import { apiGetUseCaseList } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import CASE_TYPE from '../../common/constants/CaseType';
import ApiUtils from '../../utils/ApiUtils';

function CaseItem({ item, caseImageList, index }) {
  const navigate = useNavigate();

  const navigateDetail = boardIdx => {
    navigate(`${RouterPath.useCaseDetail}/${boardIdx}`);
  };

  return (
    <div
      className={`${
        index === 0
          ? 'news_cards news_cards_1 selector'
          : index === 2
          ? 'news_cards news_cards_2 selector'
          : 'news_cards selector'
      } `}
      key={item.boardIdx}
      onClick={() => navigateDetail(item.boardIdx)}
    >
      <img
        className="img_cards"
        src={caseImageList[item.thumbFileUrl]}
        alt="placeholder"
      />
      <div>
        <h1 className="news_card_title">{item.useCaseTitle}</h1>
        <div className="divider_news_card" />
        <p
          className="news_card_time"
          dangerouslySetInnerHTML={{
            __html: Utils.shortenText(
              Utils.stripHtmlTags(item.useCaseBody),
              155,
            ),
          }}
        />
      </div>
    </div>
  );
}

function CasePage() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(1);
  const [caseList, setCaseList] = useState([]);
  const [caseImageList, setCaseImageList] = useState({});

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentType, setCurrentType] = useState('');
  const [topYn, setTopYn] = useState('Y');

  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleTabClick = index => {
    setActiveTab(index);
    if (index === 1) {
      setTopYn('');
      setCurrentType(CASE_TYPE.FOUCS_ON_DATA.code);
    } else if (index === 2) {
      setTopYn('');
      setCurrentType(CASE_TYPE.DATA_CASE.code);
    }
    setPage(1);
    setCaseList([]);
  };

  const handleSearch = () => {
    if (keyword !== searchKeyword) {
      setSearchKeyword(keyword);
      setActiveTab(-1);
      setTopYn('');
      setCurrentType('');
      setPage(1);
      setCaseList([]);
    }
  };

  useEffect(() => {
    const loadMoreData = () => {
      if (!loading && hasMore) {
        const { scrollTop } = document.documentElement;
        const { scrollHeight } = document.documentElement;
        const { clientHeight } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - clientHeight / 8) {
          setPage(prevPage => prevPage + 1);
        }
      }
    };

    window.addEventListener('scroll', loadMoreData);
    return () => window.removeEventListener('scroll', loadMoreData);
  }, [loading, hasMore]);

  useEffect(() => {
    setLoading(true);
    apiGetUseCaseList({
      size: 4,
      page,
      caseType: currentType || null,
      topYn: topYn || null,
      keyword: searchKeyword || null,
    })
      .then(response => {
        const data = response.data.data.list;
        setCaseList(prevList => (page === 1 ? data : [...prevList, ...data]));
        setLoading(false);
        setHasMore(data.length > 0);
      })
      .catch(error => {
        handleError(error);
      });
  }, [page, currentType, topYn, searchKeyword]);

  useEffect(() => {
    if (caseList.length > 0) {
      caseList.forEach(item => {
        if (!(item.thumbFileUrl in caseImageList)) {
          ApiUtils.fetchImageToList(item.thumbFileUrl, setCaseImageList);
        }
      });
    }
  }, [caseList]);

  return (
    <>
      <Filter
        title="Use cases"
        mainMenu="활용사례"
        subMenu={RouterPath.useCase}
        isSearch={false}
      />
      <section className="case-center">
        <div className="container">
          <article id="cont" className="tabList">
            <nav>
              <ul className="tabMenu">
                <li
                  className={activeTab === 1 ? 'on' : ''}
                  onClick={() => handleTabClick(1)}
                >
                  <h2 className="t_tab01">
                    <p>Focus on Data</p>
                  </h2>
                </li>
                <li
                  className={activeTab === 2 ? 'on' : ''}
                  onClick={() => handleTabClick(2)}
                >
                  <h2 className="t_tab01">
                    <p className="none-after">데이터활용사례</p>
                  </h2>
                </li>
              </ul>
            </nav>
          </article>
          <div className="divider_title position-center " />
          {activeTab === 0 && (
            <div className="intro_txt_001">
              부산 빅데이터 혁신센터에서 진행한 대표 분석 사례입니다.
            </div>
          )}
          {activeTab === 1 && (
            <div className="intro_txt_001">
              부산 빅데이터 혁신센터 자체 분석 및 유관기관 협력 분석 사례입니다.
            </div>
          )}
          {activeTab === 2 && (
            <div className="intro_txt_001">
              공공 민간 데이터 활용 활성화 지원사업*을 통한 분석 사례입니다.{' '}
              <br />
              *오픈랩 데이터 활용 Datory Lab 운영, 데이터 기반 우수 행정 서비스
              발굴 컨설팅 지원 사업 등
            </div>
          )}

          <div>
            <div
              style={{
                width: '500px',
                position: 'absolute',
                right: '0%',
                top: '120%',
                display: 'flex',
                gap: '10px',
              }}
              className="mobile-hide"
            >
              <input
                type="search"
                id="keyword"
                name="keyword"
                title="검색어를 입력해 주세요"
                placeholder="찾으시는 데이터를 검색해보세요"
                className="input_sch_center"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                type="button"
                className="btn_sch_center"
                onClick={handleSearch}
              />
            </div>
          </div>
          <div className="mobile-only">
            <div
              style={{
                marginTop: '52px',
                justifyContent: 'flex-end',
              }}
              className="main_sch_box01"
            >
              <input
                type="search"
                id="keyword"
                name="keyword"
                title="검색어를 입력해 주세요"
                placeholder="찾으시는 데이터를 검색해보세요"
                className="input_sch_center"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />

              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                type="button"
                className="btn_sch_center"
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="container_fluid">
          <div className="container">
            {caseList &&
              caseList.map((item, index) => (
                <CaseItem
                  key={item.boardIdx}
                  item={item}
                  caseImageList={caseImageList}
                  index={index}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default CasePage;
