import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from '../../common/constants';
import Filter from '../../components/Filter';
import { apiGetUserApplyList } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import Utils from '../../utils/Utils';

function AppUseList() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const getApplyList = () => {
    apiGetUserApplyList()
      .then(response => {
        setList(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    getApplyList();
  }, []);

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="center_title">
            <p>이용 신청 내역</p>
            <div className="divider_title" />
          </div>
          <table className="table_notice mobile-hide">
            <thead>
              <tr className="title_preview">
                <th className="c-auto">번호</th>
                <th className="c-auto">오픈랩분석실</th>
                <th className="c-auto">이용신청일</th>
                <th className="c-auto">이용신청시간</th>
                <th className="c-auto">승인여부</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((item, index) => (
                  <tr key={index}>
                    <td className="c-auto">{item.applyIdx}</td>
                    <td className="c-auto">{item.useRoom}</td>
                    <td className="c-auto">
                      {Utils.formatDate(item.useDate) || '-'}
                    </td>
                    <td className="c-auto">
                      {item.useTime === 'am'
                        ? '오전'
                        : item.useTime === 'pm'
                        ? '오후'
                        : item.useTime === 'am,pm'
                        ? '종일'
                        : '-'}
                    </td>
                    <td className="c-auto">
                      {item.approveState === 'OK'
                        ? '승인완료'
                        : item.approveState === 'REJ'
                        ? '승인거절'
                        : item.approveState === 'WAIT'
                        ? '승인대기'
                        : item.approveState === 'EXP'
                        ? '기간만료'
                        : '-'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div>빅데이터 이용 신청 정보가 없습니다.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default AppUseList;
