import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath, Utils } from '../../common';
import { apiGetQnaDetail } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

function QADetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const storageToken = JSON.parse(localStorage.getItem('auth'));

  const [qna, setQna] = useState([]);

  const getQnaDetail = idx => {
    const data = {
      token: storageToken?.accessToken,
    };

    apiGetQnaDetail(idx, data)
      .then(response => {
        // console.log('response data :::: ', response.data.data);
        setQna(response.data.data);
      })
      .catch(error => {
        // console.log('error :::: ', error);
        handleError(error, navigate);
      });
  };

  useEffect(() => {
    getQnaDetail(params);
  }, []);

  return (
    <div>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.qnaList}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>질의 응답 상세보기</p>
            <div className="divider_title" />
          </div>

          <div className="qa_content mobile-hide">
            <div className="section_create_1">
              <div className="th1">
                <div className="td1">
                  <span>{qna.qnaTitle}</span>
                </div>
              </div>
              <div className="th2">
                <div
                  className={
                    qna.qnaStatus === 'ANSWERED'
                      ? 'label_status_gray'
                      : 'label_status_blue'
                  }
                >
                  {qna.qnaStatus === 'ANSWERED' ? '답변완료' : '답변대기'}
                </div>
                <div className="info_qa">
                  <div className="info_qa_item">
                    <div className="label">작성자</div>
                    <p className="value">{Utils.anonymizeName(qna.mbName)}</p>
                  </div>
                  <div className="info_qa_item">
                    <div className="label">등록일</div>
                    <p className="value">{Utils.formatDate(qna.regDate)}</p>
                  </div>
                  <div className="info_qa_item">
                    <div className="label">조회수</div>
                    <p className="value">{qna.countView}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="data"
              dangerouslySetInnerHTML={{
                __html: qna.qnaBody,
              }}
            />
            {qna?.answerList &&
              qna.answerList.map((answer, index) => (
                <div key={`answer_${index}`}>
                  <div className="section_create_2">답변</div>
                  <div className="data">
                    <p
                      className="content_qa"
                      dangerouslySetInnerHTML={{
                        __html: answer.qnaBody,
                      }}
                    />
                  </div>
                  <div className="line" />
                </div>
              ))}
          </div>

          {/* mobile screen */}
          <div className="detail_notice_content mobile-only">
            <div className="section1">
              <div className="th1">
                <p className="content1">{qna.qnaTitle}</p>
              </div>
              <div
                className="flex-column"
                style={{ borderTop: '1px solid #CCCCCC' }}
              >
                <div
                  className="item_right flex align-center"
                  style={{ columnGap: 8, padding: 16 }}
                >
                  <div className="label f_20">작성자</div>
                  <div className="value f_400_20">
                    {Utils.anonymizeName(qna.mbName)}
                  </div>
                </div>
                <div
                  className="item_right flex align-center"
                  style={{
                    columnGap: 8,
                    borderTop: '1px solid #CCCCCC',
                    padding: 16,
                  }}
                >
                  <div className="label f_20">등록일</div>
                  <div className="value f_400_20">
                    {Utils.formatDate(qna.regDate)}
                  </div>
                </div>
                <div
                  className="item_right flex align-center"
                  style={{
                    columnGap: 8,
                    borderTop: '1px solid #CCCCCC',
                    padding: 16,
                  }}
                >
                  <div className="label f_20">조회수</div>
                  <div className="value f_400_20">{qna.countView}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="section2">
              <div className="content1">
                <div
                  className="value1"
                  dangerouslySetInnerHTML={{
                    __html: Utils.shortenText(qna.qnaBody),
                  }}
                />
              </div>
            </div>

            <div className="qa_content">
              {qna?.answerList &&
                qna.answerList.map((answer, index) => (
                  <div key={`answer_${index}`}>
                    <div className="section_create_2">답변</div>
                    <div className="data">
                      <p className="title_qa">{answer.qnaTitle}</p>
                      <p
                        className="content_qa"
                        dangerouslySetInnerHTML={{
                          __html: answer.qnaBody,
                        }}
                      />
                    </div>
                    <div className="line" />
                  </div>
                ))}
              {/* mobile screen end */}
            </div>
          </div>

          <div className="box_btn_qa">
            <button
              type="button"
              className="btn_notice_red"
              onClick={() => navigate(-1)}
            >
              목록으로
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default QADetail;
