import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { images } from '../assets';
import WaveLogo from '../assets/images/data_wave_logo.svg';

function SwiperLogo() {
  const sliderRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sliderRef.current) {
      const swiper = new Swiper(sliderRef.current, {
        autoplay: true,
        slidesPerView: 5,
        spaceBetween: 110,
        speed: 500,
        loop: true,
        breakpoints: {
          1400: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 4,
          },
          765: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          568: {
            slidesPerView: 1,
          },
          465: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        },
      });

      /* Clean up Swiper when component unmounts */
      return () => {
        swiper.destroy();
      };
    }
  }, []);

  return (
    <section className="logoBanner">
      <div ref={sliderRef} className="slider_item">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <a href="https://www.busan.go.kr" target="_blank" rel="noreferrer">
              <img src={images.busanLogo} alt="Logo 1" />
            </a>
          </div>
          <div className="swiper-slide">
            <a href="https://www.btp.or.kr" target="_blank" rel="noreferrer">
              <img src={images.logo02} alt="Logo 2" />
            </a>
          </div>
          {/* <div className="swiper-slide"> */}
          {/*  <a href="https://data.busan.go.kr" target="_blank" rel="noreferrer"> */}
          {/*    <img src={images.logo04} alt="Logo 3" /> */}
          {/*  </a> */}
          {/* </div> */}
          <div className="swiper-slide">
            <a
              href="https://busanserviceup.kr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.logo05} alt="Logo 4" />
            </a>
          </div>
          <div className="swiper-slide">
            <a
              href="https://www.bigdata-finance.kr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.logo06} alt="Logo 5" />
            </a>
          </div>
          {/* <div className="swiper-slide"> */}
          {/*  <a */}
          {/*    href="https://bigdata.busan.go.kr" */}
          {/*    target="_blank" */}
          {/*    rel="noreferrer" */}
          {/*  > */}
          {/*    <img src={images.bigdataLogoBack} alt="Logo 6" /> */}
          {/*  </a> */}
          {/* </div> */}
          <div className="swiper-slide">
            <a
              href="https://www.dxchallenge.co.kr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={images.dxLogoBack} alt="Logo 7" />
            </a>
          </div>
          <div className="swiper-slide">
            <a
              href="https://data.busan.go.kr/"
              target="_blank"
              rel="noreferrer"
            >
              {/* <img src={images.waveLogo} alt="Logo 8" /> */}
              <WaveLogo />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwiperLogo;
