import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Layout({ children }) {
  return (
    <Container fluid className="app-container">
      <Header />
      <div className="app-contents">{children}</div>
      <Footer />
    </Container>
  );
}

export default React.memo(Layout);
