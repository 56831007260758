import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import BannerSlider05 from '../../components/BannerSLider05';
import ItemLab from '../../components/ItemLab';
import Pagination from '../../components/Pagination';
import { RouterPath } from '../../common/constants';
import { apiGetBigdataList } from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

function DataList() {
  const location = useLocation();
  const navigate = useNavigate();

  const initKeyword = location?.state ? location.state?.keyword : '';
  const initDataClass = location?.state ? location.state?.dataClass : '';
  const initPage = location?.state ? location.state?.page : 1;

  const [keyword, setKeyword] = useState(initKeyword);
  const [dataClass, setDataClass] = useState(initDataClass);
  const [page, setPage] = useState(initPage);

  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const pageSize = 5;
  const navigateList = (currentPage, currentKeyword, currentDataClass) => {
    navigate(RouterPath.bigdataList, {
      state: {
        page: currentPage,
        keyword: currentKeyword,
        dataClass: currentDataClass,
      },
    });
  };

  useEffect(() => {
    // console.log(page);
    // console.log(keyword);
    // console.log(dataClass);
  }, [page, keyword, dataClass]);
  const getBigdataList = (currentPage, currentKeyword, currentDataClass) => {
    const data = {
      page: currentPage || 1,
      size: pageSize,
      total: 0,
      dataClass: currentDataClass || null,
      keyword: currentKeyword || null,
    };

    apiGetBigdataList(data)
      .then(response => {
        setList(response.data.data.list);
        setTotalCount(response.data.data.totalCnt);
        setTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };
  useEffect(() => {
    getBigdataList(initPage, initKeyword, initDataClass);
    setPage(initPage);
    setDataClass(initDataClass);
    setKeyword(initKeyword);
  }, [location]);

  return (
    <>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.bigdataList}
        // page={page}
        // keyword={keyword}
        // searchDataClass={dataClass}
        // isSearch
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>데이터 리스트</p>
            <div className="divider_title" />
          </div>
          <div
            style={{
              width: '420px',
              position: 'absolute',
              right: '2%',
              top: '300px',
              display: 'flex',
              gap: '10px',
            }}
            className="mobile-hide"
          >
            <input
              type="search"
              id="keyword"
              name="keyword"
              title="검색어를 입력해 주세요"
              placeholder="찾으시는 데이터를 검색해 보세요"
              className="input_sch_center"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  navigate(RouterPath.bigdataList, {
                    state: {
                      keyword,
                      dataClass,
                      page,
                    },
                  });
                }
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              type="button"
              className="btn_sch_center"
              onClick={() =>
                navigate(RouterPath.bigdataList, {
                  state: {
                    keyword,
                    dataClass,
                    page,
                  },
                })
              }
            />
          </div>
          <div className="mobile-only">
            <div className="main_sch_box01">
              <input
                type="search"
                id="keyword"
                name="keyword"
                title="검색어를 입력해 주세요"
                placeholder="찾으시는 데이터를 검색해 보세요"
                className="input_sch_center"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    navigate(RouterPath.bigdataList, {
                      state: {
                        keyword,
                        dataClass,
                        page,
                      },
                    });
                  }
                }}
              />

              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                type="button"
                className="btn_sch_center"
                onClick={() =>
                  navigate(RouterPath.bigdataList, {
                    state: {
                      keyword,
                      dataClass,
                      page,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="banner_box">
            <BannerSlider05
              navigateList={navigateList}
              defaultClass={dataClass}
              page={page}
              keyword={keyword}
            />
          </div>

          <div className="datalab_txt_001">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <span>{dataClass === '' ? '전체' : dataClass}</span>
            <div>{totalCount}건</div>
          </div>

          <div className="datalab_list">
            {list &&
              list.length > 0 &&
              list.map((item, index) => {
                return <ItemLab key={`bigdata_list_${index}`} item={item} />;
              })}
          </div>
        </div>
        <Pagination
          pageCount={totalPage}
          pageNum={page}
          setPage={setPage}
          onPageChangeCallback={navigateList}
          keyword={keyword}
          dataClass={dataClass}
        />
      </section>
    </>
  );
}

export default DataList;
