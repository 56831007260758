/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from '@emotion/react';
import { Form } from 'react-bootstrap';
import ko from 'date-fns/locale/ko';

registerLocale('ko', ko);

const CustomInput = forwardRef(
  ({ value, placeholder, onClick, onChange, disabled, maxWidth }, ref) => {
    const customInput = css`
      padding: 10px 16px;
      max-width: ${maxWidth || '200px'};
      text-align: center;
      font: 700 1rem ONE-Mobile;
      background-position: right 8px center;
      background-repeat: no-repeat;
      background-size: 18px 24px;
      height: 40px;
      width: 120px;
      border: 1px solid #c1c1c1;
      border-radius: 8px;
      transition: border-color 0.3s, box-shadow 0.3s;
      background-color: #ffffff;
      color: #333333;

      &:focus {
        border: 2px solid #327ae7;
        color: #327ae7;
        outline: none;
      }

      @media (max-width: 768px) {
        width: 100px;
        height: 20px;
        font-size: 0.9rem; /* 글꼴 크기 줄이기 */
        padding: 8px 12px; /* 패딩 줄이기 */
      }
    `;

    return (
      <Form.Control
        css={customInput}
        className="form-control"
        onClick={onClick}
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    );
  },
);

const customCalendarStyles = `
  .react-datepicker__month-container {
    background-color: #fff;
  }
  .react-datepicker__header {
    background-color: #f4f8fc;
  }
`;

// Add the styles to the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = customCalendarStyles;
document.head.appendChild(styleSheet);

function MyCustomDatePicker({
  value,
  onChange,
  maxDate,
  dateFormat,
  minDate,
  disabled,
  maxWidth,
  showMonthYearPicker,
  ...rest
}) {
  const localTZ = 'Asia/Seoul'; // 한국 시간대
  return (
    <DatePicker
      selected={value}
      onChange={date => {
        const koreanDate = new Date(
          date.toLocaleDateString('ko-KR', { timeZone: localTZ }),
        );
        onChange(koreanDate);
      }}
      locale="ko"
      customInput={<CustomInput disabled={disabled} maxWidth={maxWidth} />}
      disabled={disabled}
      maxDate={maxDate && maxDate}
      minDate={minDate && minDate}
      disabledKeyboardNavigation
      dateFormat={dateFormat || 'yyyy.MM.dd'}
      dateFormatCalendar="yyyy.MM"
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
      calendarClassName="custom-calendar" // Use the string class name
      showMonthYearPicker={showMonthYearPicker}
      {...rest}
    />
  );
}

export default MyCustomDatePicker;
