import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { images } from '../assets';
import SOCIAL_TYPE from '../common/constants/SocialType';
import { Utils } from '../common';

function SwiperSNS({ socialList, socialImageList }) {
  const sliderRef = useRef(null);

  const handleClick = actionLink => {
    if (actionLink) {
      window.location.href = actionLink;
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socialImageList && sliderRef.current) {
      const swiper = new Swiper(sliderRef.current, {
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 30,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-sns-next',
          prevEl: '.swiper-button-sns-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
          },
          765: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          568: {
            slidesPerView: 1,
          },
          465: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        },
      });
      return () => {
        swiper.destroy();
      };
    }
  }, [socialList]);

  return (
    <div id="swiper-notification" className="slide_type03">
      <div ref={sliderRef} className="slider_item03">
        <div className="swiper-wrapper">
          {socialList?.length > 0
            ? socialList.map((item, index) => (
                <div className="swiper-slide" key={index}>
                  <div className="item">
                    <div
                      className="itemSlide selector"
                      onClick={() => handleClick(item.actionLink)}
                    >
                      <img
                        className="newsImage"
                        src={socialImageList[item.thumbFileUrl]}
                        alt="placeholder"
                        style={{ objectFit: 'fill' }}
                      />
                      <div className="content">
                        <div className="bannerh2">{item.title || '-'}</div>
                        <div className="clockTime">
                          {Utils.formatDate(item.regDate) || '-'}
                          {item.socialType === SOCIAL_TYPE.INSTA.code ? (
                            <img src={images.instagram} alt="clock" />
                          ) : item.socialType === SOCIAL_TYPE.FACEBOOK.code ? (
                            <img src={images.facebook} alt="clock" />
                          ) : item.socialType === SOCIAL_TYPE.YOUTUBE.code ? (
                            <img src={images.ytbCircle} alt="clock" />
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default SwiperSNS;
