/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addressNew: '', // 도로명 주소
  addressOld: '', // 지번 주소
  businessName: '', // 상호명
  businessNumber: '', // 사업자등록번호
  ceoName: '', // 대표 이름
  hp: '', // 대표 전화
  instagramUrl: '', // 인스타그램 URL
  kakaoUrl: '', // 카카오 URL
  naverUrl: '', // 네이버 URL
  youtubeUrl: '', // 유튜브 URL
  pax: '', // 팩스
};

export const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    // 정보 설정
    setInfo: (state, action) => {
      state.addressNew = action.payload.addressNew;
      state.addressOld = action.payload.addressOld;
      state.businessName = action.payload.businessName;
      state.businessNumber = action.payload.businessNumber;
      state.ceoName = action.payload.ceoName;
      state.hp = action.payload.hp;
      state.instagramUrl = action.payload.instagramUrl;
      state.kakaoUrl = action.payload.kakaoUrl;
      state.naverUrl = action.payload.naverUrl;
      state.youtubeUrl = action.payload.youtubeUrl;
      state.pax = action.payload.pax;
    },
    // 정보 조회
    getInfo: (state, action) => {
      action.payload = state;
    },
    // 정보 초기화
    initInfo: state => {
      state.addressNew = initialState.addressNew;
      state.addressOld = initialState.addressOld;
      state.businessName = initialState.businessName;
      state.businessNumber = initialState.businessNumber;
      state.ceoName = initialState.ceoName;
      state.hp = initialState.hp;
      state.instagramUrl = initialState.instagramUrl;
      state.kakaoUrl = initialState.kakaoUrl;
      state.naverUrl = initialState.naverUrl;
      state.youtubeUrl = initialState.youtubeUrl;
      state.pax = initialState.pax;
    },
  },
});

export const infoAction = infoSlice.actions;

export default infoSlice.reducer;
