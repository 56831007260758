const CASE_TYPE = {
  FOUCS_ON_DATA: {
    code: 'FOUCS_ON_DATA',
    desc: 'Focus on Data',
  },
  DATA_CASE: {
    code: 'DATA_CASE',
    desc: '데이터 활용 사례',
  },
  // 마이페이지
  MY_PAGE: {
    code: 'MY_PAGE',
    desc: '통합 계정 회원 정보',
  },
  APP_USE_LIST: {
    code: 'APP_USE_LIST',
    desc: '이용신청내역',
  },
  BUSINESS_APLLY_LIST: {
    code: 'BUSINESS_APLLY_LIST',
    desc: '사업 신청 목록 확인',
  },
};

export default CASE_TYPE;
