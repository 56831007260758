import React from 'react';
import Filter from '../components/Filter';
import { images } from '../assets';
import { RouterPath } from '../common/constants';
import CustomMapComponent from '../components/CustomMapComponent';

function MapGuide() {
  const onChange = e => {
    // console.log(e.target.value);
  };
  const { innerWidth: width, innerHeight: height } = window;
  const onClick = () => {};
  return (
    <div>
      <Filter
        onChange={onChange}
        title="Busan Big Data Innovation Center"
        onClick={onClick}
        mainMenu="센터소개"
        subMenu={RouterPath.mapGuide}
      />
      <section className="center_info">
        <div className="container">
          <div className="center_title">
            <p>오시는 길</p>
            <div className="divider_title" />
          </div>
        </div>
      </section>

      <section className="mapBox">
        <div className="container">
          {/* <img className="map_image" src={images.map} alt="map" /> */}
          <div className="map_image">
            {/* <div */}
            {/*  id="daumRoughmapContainer1719821294754" */}
            {/*  className="root_daum_roughmap root_daum_roughmap_landing" */}
            {/* > */}
            {/*  {width && */}
            {/*    // eslint-disable-next-line no-undef */}
            {/*    new daum.roughmap.Lander({ */}
            {/*      timestamp: '1719821294754', */}
            {/*      key: '2jvhk', */}
            {/*      mapWidth: width - 1, */}
            {/*      mapHeight: height, */}
            {/*    }).render()} */}
            {/* </div> */}

            {/* <script charset="UTF-8"> */}
            {/*  /!* eslint-disable-next-line no-undef *!/ */}
            {/*  {new daum.roughmap.Lander({ */}
            {/*    timestamp: '1719821294754', */}
            {/*    key: '2jvhk', */}
            {/*    mapWidth: '100%', */}
            {/*    mapHeight: '360', */}
            {/*  }).render()} */}
            {/* </script> */}
            {/* <div */}
            {/*  id="daumRoughmapContainer1679644232271" */}
            {/*  class="root_daum_roughmap root_daum_roughmap_landing" */}
            {/*  style={{ width: '100%' }} */}
            {/* /> */}
            <CustomMapComponent />
          </div>
          <div className="maker_map mobile-hide">
            <img src={images.location} alt="location" />
            <div className="content_marker">
              부산광역시 해운대구 센텀동로 45, 센텀기술창업타운 7층 <br />
              <span>TEL.</span>051-747-6350~3
            </div>
          </div>

          <div className="maker_map mobile-only">
            <img src={images.location} alt="location" />
            <div className="content_marker">
              부산광역시 해운대구 센텀동로 45, 센텀기술창업타운 7층 <br />
              <br />
              <span>TEL.</span>051-747-6350~3
            </div>
          </div>

          <div className="traffic_map">교통편 안내</div>

          <div className="traffic_box">
            <div className="traffic_item">
              <div className="flex align-center mobile-only">
                <img src={images.trafficIcon} alt="traffic_icon" />
                <div className="traffic_content_title">지하철 이용시</div>
              </div>
              <img
                src={images.trafficIcon}
                alt="traffic_icon"
                className="mobile-hide"
              />

              <div className="traffic_content_box">
                <div className="traffic_content_title mobile-hide">
                  지하철 이용시
                </div>
                <div className="badge_traffic_001">
                  <span>2호선</span>
                  <div>
                    지하철 2호선 센텀시티역 2번 출구로 나와서 해운대 소방서
                    119안전센터 방향 약 8분 도보 이동
                  </div>
                </div>
                <div className="badge_traffic_002">
                  <span>동해선</span>
                  <div>
                    동해선 벡스코역 1번 출구로 나와서 홈플러스 입구 방향으로 약
                    5분 도보 이동
                  </div>
                </div>
              </div>
            </div>
            <div className="traffic_item">
              <div className="flex align-center mobile-only">
                <img src={images.trafficIcon002} alt="traffic_icon" />
                <div className="traffic_content_title">버스 이용시</div>
              </div>
              <img
                src={images.trafficIcon002}
                alt="traffic_icon"
                className="mobile-hide"
              />
              <div className="traffic_content_box">
                <div className="traffic_content_title mobile-hide">
                  버스 이용시
                </div>
                <div className="badge_traffic_001">
                  <span>벡스코 정류장</span>
                  <div>
                    5-1, 39, 40, 63, 115, 139, 141, 155, 181, 1001, 1006 해운대
                    소방서 119안전센터 방향 약 8분 도보 이동
                  </div>
                </div>
                <div className="badge_traffic_002">
                  <span>우체국 정류장</span>
                  <div>107 센탑 건물 도보 2분 이동</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MapGuide;
