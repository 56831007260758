import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { images } from '../assets';

function SwiperDataCenter({ selectedTab }) {
  const sliderRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // console.log('selectedTab ::::: ', selectedTab);
    if (sliderRef.current) {
      const swiper = new Swiper(sliderRef.current, {
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 500,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next-center',
          prevEl: '.swiper-button-prev-center',
        },
        breakpoints: {
          1400: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          765: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          568: {
            slidesPerView: 1,
          },
          465: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        },
      });

      /* Clean up Swiper when component unmounts */
      return () => {
        swiper.destroy();
      };
    }
  }, [selectedTab]);

  return (
    <div className="slide_type03">
      <div ref={sliderRef} className="slider_item03">
        <div className="swiper-wrapper">
          {selectedTab === 0 && (
            <div className="swiper-slide">
              <img src={images.space} alt="Busan Logo" />
            </div>
          )}
          {selectedTab && selectedTab === 1 && (
            <>
              <div className="swiper-slide">
                <img src={images.center1} alt="Busan Logo" />
              </div>
              <div className="swiper-slide">
                <img src={images.center2} alt="Busan Logo" />
              </div>
              <div className="swiper-slide">
                <img src={images.center3} alt="Busan Logo" />
              </div>
            </>
          )}
          {selectedTab && selectedTab === 2 && (
            <>
              <div className="swiper-slide">
                <img src={images.center4} alt="Busan Logo" />
              </div>
              <div className="swiper-slide">
                <img src={images.center5} alt="Busan Logo" />
              </div>
            </>
          )}
          {selectedTab && selectedTab === 3 && (
            <>
              <div className="swiper-slide">
                <img src={images.center6} alt="Busan Logo" />
              </div>
              <div className="swiper-slide">
                <img src={images.center7} alt="Busan Logo" />
              </div>
            </>
          )}
          {selectedTab && selectedTab === 4 && (
            <div className="swiper-slide">
              <img src={images.center8} alt="Busan Logo" />
            </div>
          )}
        </div>
        <div className="ar_w03">
          <div className="swiper-button-next swiper-button-next-center" />
          <div className="swiper-button-prev swiper-button-prev-center" />
        </div>
      </div>
    </div>
  );
}

export default SwiperDataCenter;
