import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper/bundle';
import { images } from '../assets';

function BannerSlider05({ navigateList, defaultClass = '', page, keyword }) {
  const sliderRef = useRef(null);
  const [selectedClasses, setSelectedClasses] = useState(defaultClass);

  const clickFunc = (item, index) => {
    if (selectedClasses === item) {
      setSelectedClasses('');
      navigateList(1, keyword, '');
    } else {
      setSelectedClasses(item);
      navigateList(1, keyword, item);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sliderRef.current) {
      const swiper = new Swiper(sliderRef.current, {
        slidesPerView: 9,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next05',
          prevEl: '.swiper-button-prev05',
        },
        breakpoints: {
          1024: {
            slidesPerView: 9,
          },
          765: {
            slidesPerView: 4,
          },
          640: {
            slidesPerView: 4,
          },
          568: {
            slidesPerView: 4,
          },
          465: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 2,
          },
        },
      });

      /* Clean up Swiper when component unmounts */
      return () => {
        swiper.destroy();
      };
    }
  }, []);

  return (
    <div className="slide_type02">
      <div ref={sliderRef} className="slider_item02 swiper-container">
        <div className="swiper-wrapper">
          {/* Slides */}
          {/* Note: You can map through your slide data here if it's dynamic */}
          <div
            className={
              (selectedClasses === '교통' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('교통', 0)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.mQuickIco04} alt="교통" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">교통</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '인구' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('인구', 1)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.mQuickIco01} alt="인구" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">인구</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '부동산' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('부동산', 2)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.estate} alt="부동산" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">부동산</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '상권' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('상권', 3)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.mQuickIco06} alt="상권" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">상권</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '관광' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('관광', 4)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.mQuickIco07} alt="관광" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">관광</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '지역화폐' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('지역화폐', 5)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.mQuickIco03} alt="지역화폐" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">지역화폐</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '차량등록' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('차량등록', 6)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.taxi} alt="차량등록" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">차량등록</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '건축물' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('건축물', 7)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.mQuickIco05} alt="건축물" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">건축물</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '유료도로' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('유료도로', 8)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.road} alt="유료도로" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">유료도로</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '유동인구' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('유동인구', 9)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.chart} alt="유동인구" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">유동인구</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '소비' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('소비', 10)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.salesSlide} alt="소비" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">소비</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '개인신용' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('개인신용', 11)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.creditCard} alt="개인신용" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">개인신용</div>
                </div>
              </a>
            </div>
          </div>
          <div
            className={
              (selectedClasses === '기업신용' &&
                'swiper-slide box_active swiper-slide-active') ||
              'swiper-slide'
            }
          >
            <div className="item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => clickFunc('기업신용', 12)}>
                <div className="f01">
                  <div className="p01">
                    <img src={images.enterprise} alt="기업신용" />
                  </div>
                </div>
                <div className="tg01">
                  <div className="t01">기업신용</div>
                </div>
              </a>
            </div>
          </div>
          {/* 금융 탭은 테스트용으로 만든것, 현재 데이터코드에 금융 없는 상태 */}
          {/* <div */}
          {/*  className={ */}
          {/*    (selectedClasses === 'test' && */}
          {/*      'swiper-slide box_active swiper-slide-active') || */}
          {/*    'swiper-slide' */}
          {/*  } */}
          {/* > */}
          {/*  <div className="item"> */}
          {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
          {/*    <a onClick={() => clickFunc('test', 13)}> */}
          {/*      <div className="f01"> */}
          {/*        <div className="p01"> */}
          {/*          <img src={images.mQuickIco02} alt="test" /> */}
          {/*        </div> */}
          {/*      </div> */}
          {/*      <div className="tg01"> */}
          {/*        <div className="t01">금융</div> */}
          {/*      </div> */}
          {/*    </a> */}
          {/*  </div> */}
          {/* </div> */}

          {/* Add more slides here */}
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className="ar_w05">
        <div className="swiper-button-next swiper-btn-next-custom swiper-button-next05" />
        <div className="swiper-button-prev swiper-btn-prev-custom swiper-button-prev05" />
      </div>
    </div>
  );
}

export default BannerSlider05;
