import React, { useEffect, useState } from 'react';

// eslint-disable react/button-has-type

function Pagination({
  pageCount,
  pageNum,
  setPage,
  onPageChangeCallback,
  keyword,
  dataClass,
  ...rest
}) {
  const [currentPage, setCurrentPage] = useState(pageNum || 1);

  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);

  const handlePageChange = page => {
    if (page >= 1 && page <= pageCount) {
      setCurrentPage(page);
      setPage(page);
      if (typeof onPageChangeCallback === 'function') {
        onPageChangeCallback(page, keyword, dataClass);
      }
    }
  };

  return pageCount > 1 ? (
    <div className="pagination" {...rest}>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        type="button"
      >
        &lt;
      </button>
      {currentPage > 3 && (
        <button onClick={() => handlePageChange(1)} type="button">
          1
        </button>
      )}
      {currentPage > 4 && <span className="hide">...</span>}
      {Array.from({ length: 5 }, (_, i) => currentPage + i - 2).map(
        page =>
          page >= 1 &&
          page <= pageCount && (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? 'active' : ''}
              type="button"
            >
              {page}
            </button>
          ),
      )}
      {currentPage < pageCount - 3 && <span className="hide">...</span>}
      {currentPage < pageCount - 2 && (
        <button onClick={() => handlePageChange(pageCount)} type="button">
          {pageCount}
        </button>
      )}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
        type="button"
      >
        &gt;
      </button>
    </div>
  ) : null;
}

export default Pagination;
