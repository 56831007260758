import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import { images } from '../../assets';
import { RouterPath } from '../../common/constants';
import {
  apiDownloadBigDataSampleList,
  apiGetBigDataSampleList,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';

function Detail() {
  const location = useLocation();
  const navigate = useNavigate();
  const bigdataDetail = location?.state;
  const { dataFormat } = bigdataDetail;
  const dataFormatArray = dataFormat.split(', ');
  const { dataIdx } = useParams();

  const [tableColumnList, setTableColumnList] = useState(
    bigdataDetail.tableColumns,
  );
  const [tableSampleList, setTableSampleList] = useState([]);
  const [detail, setDetail] = useState(bigdataDetail);
  const [accessDb, setAccessDb] = useState(bigdataDetail.accessDb);

  const downloadBigDataSampleListApi = () => {
    // eslint-disable-next-line no-shadow
    const columnNameArray = detail.tableColumns.map(item => item.colNameEn);
    const columnMapArray = detail.tableColumns.map(item => ({
      colNameEn: item.colNameEn,
      colNameKr: item.colNameKr,
    }));
    const data = {
      dataIdx,
      accessDb: detail?.accessDb,
      accessTable: detail?.accessTable,
      size: detail?.countSample,
      colNameList: columnNameArray,
      fileName: detail?.fileName,
      colMapList: columnMapArray,
    };
    // console.log('download request data::: ', data);
    try {
      apiDownloadBigDataSampleList(data).then(response => {
        // console.log('response data blob type::::', response.data.type);
        if (response.data.type !== 'application/json') {
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = downloadUrl;
          a.download = decodeURIComponent(
            response.headers['content-disposition'].split('filename=')[1],
          );
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (error) {
      handleError(error);
    }
  };

  // eslint-disable-next-line no-shadow
  const bigDataSampleListApi = () => {
    const columnNameArray = detail.tableColumns.map(item => item.colNameEn);
    const data = {
      accessDb: detail?.accessDb,
      accessTable: detail?.accessTable,
      size: detail?.countSample,
      colNameList: columnNameArray,
    };

    apiGetBigDataSampleList(data)
      .then(response => {
        setTableSampleList(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    // console.log('accessDbData:::: ', accessDb);
    if (accessDb && accessDb !== null) {
      bigDataSampleListApi(1);
    }
  }, [accessDb]);

  return (
    <div>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.bigdataDetail}
        // isSearch
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>데이터 상세</p>
            <div className="divider_title" />
          </div>

          <div className="datalab_list">
            <div className="item_data_no_button">
              <div className="item_img_icon">
                <img
                  src={
                    (detail?.dataClass === '교통' && images.mQuickIco04) ||
                    (detail?.dataClass === '인구' && images.mQuickIco01) ||
                    (detail?.dataClass === '부동산' && images.estate) ||
                    (detail?.dataClass === '상권' && images.mQuickIco06) ||
                    (detail?.dataClass === '관광' && images.mQuickIco07) ||
                    (detail?.dataClass === '지역화폐' && images.mQuickIco03) ||
                    (detail?.dataClass === '차량등록' && images.taxi) ||
                    (detail?.dataClass === '건축물' && images.mQuickIco05) ||
                    (detail?.dataClass === '유료도로' && images.road) ||
                    (detail?.dataClass === '유동인구' && images.chart) ||
                    (detail?.dataClass === '소비' && images.salesSlide) ||
                    (detail?.dataClass === '개인신용' && images.creditCard) ||
                    (detail?.dataClass === '기업신용' && images.enterprise) ||
                    (detail?.dataClass === 'test' && images.mQuickIco02)
                  }
                  alt="office"
                />
                <span>{detail.dataClass}</span>
              </div>
              <div className="item_content_detail">
                <div className="item_menu_detail">
                  {dataFormatArray?.map((item, index) => (
                    <div key={`bigdata_db_file_type_${index}`}>{item}</div>
                  ))}
                </div>
                <br />

                <div>
                  <div className="item_txt_001">{detail.dataName}</div>

                  <div className="item_txt_002">{detail.dataDesc}</div>
                  <br />
                </div>
              </div>
            </div>

            <table>
              <tr>
                <th>제공 기관</th>
                <td>{detail.provider}</td>
                <th>적재주기</th>
                <td>{detail.timeLoadPeriod}</td>
              </tr>
              <tr>
                <th>공간 범위</th>
                <td>{detail.geoMaxRange}</td>
                <th>시간범위</th>
                <td>
                  {detail.zdateFrom} ~ {detail.zdateTo}
                </td>
              </tr>
              <tr>
                <th>등록 일자</th>
                <td>{detail.regDate}</td>
                <th>수정 일자</th>
                <td>{detail.updDate}</td>
              </tr>
              <tr>
                <th rowSpan={3}>자료 목록</th>
                <td>{detail.fileName}</td>
                <td className="td_btn" colSpan={2}>
                  <div className="align_end">
                    <div
                      onClick={() => downloadBigDataSampleListApi()}
                      className="btn_table"
                    >
                      <span>샘플 데이터</span>
                      <img src={images.download} alt="download" />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div className="traffic_map">샘플데이터 미리보기</div>

          <div className="vertical-wheel-table">
            <table className="table_preview">
              <tr>
                <th className="title_preview" colSpan={tableColumnList?.length}>
                  {detail.dataName}
                </th>
              </tr>
              <tr>
                {tableColumnList?.length > 0 ? (
                  // eslint-disable-next-line no-undef
                  tableColumnList.map((item, index) => (
                    <th
                      key={`sample_column_${index}`}
                      style={{ width: '350px' }}
                    >
                      {item.colNameKr}
                    </th>
                  ))
                ) : (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <th>칼럼명</th>
                )}
              </tr>
              {tableSampleList?.length > 0 ? (
                tableSampleList.map((item, index) => (
                  <tr key={`sample_${index}`}>
                    {tableColumnList.length > 0 ? (
                      tableColumnList.map((columnItem, columnIndex) => (
                        <td key={`sample_${index}_${columnIndex}`}>
                          {item[columnItem.colNameEn]}
                        </td>
                      ))
                    ) : (
                      <td>-</td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={
                      Array.isArray(tableColumnList) &&
                      tableColumnList?.length > 0
                        ? tableColumnList?.length
                        : 1
                    }
                  >
                    <div className="centered-content each-row">
                      검색 결과가 없습니다.
                    </div>
                  </td>
                </tr>
              )}
            </table>
          </div>
          <br />

          <button
            type="button"
            onClick={() => navigate(-1)}
            className="btn_preview"
          >
            목록보기
          </button>
        </div>
      </section>
    </div>
  );
}

export default Detail;
