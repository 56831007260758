// eslint-disable camelcase

// icons
import iconLike from './ico_like.png';
import mQuickIco01 from './m_quick_ico01.png';
import mQuickIco02 from './m_quick_ico02.png';
import mQuickIco03 from './m_quick_ico03.png';
import mQuickIco04 from './m_quick_ico04.png';
import mQuickIco05 from './m_quick_ico05.png';
import mQuickIco06 from './m_quick_ico06.png';
import mQuickIco07 from './m_quick_ico07.png';
import mingcuteTime from './mingcute_time-line.svg';
import step2 from './step2.png';
// images
// eslint-disable-next-line camelcase
import logo from './logo.png';
import dxLogo from './dx_logo.svg';
import house from './house.png';
import busanLogo from './busan_logo.png';
import logo02 from './logo02.png';
import logo04 from './logo04.png';
import logo05 from './logo05.png';
import logo06 from './logo6.png';
import logoFoot from './logo_main.png';
import topButton from './topBtn.png';
import corporate from './free-icon-corporate.png';
import market from './free-icon-market.png';
import productivity from './free-icon-productivity.png';
import progressing from './free-icon-data-processing.png';
import sales from './free-icon-sales.png';
import innovation from './free-icon-innovation.png';
import business from './free-icon-business.png';
import nurturing from './free-icon-nurturing.png';
import teamwork from './free-icon-teamwork.png';
import location from './free-icon-location.png';
import trafficIcon from './traffic_icon.png';
import trafficIcon002 from './traffic_icon_2.png';
import faSolidHouse from './fa-solid_home.png';
import primePrint from './prime_print.png';
import map from './map.png';
import office from './free-icon-office.png';
import download from './free-icon-download.png';
import taxi from './free-icon-taxi.png';
import road from './free-icon-toll-road.png';
import salesSlide from './free-icon-sales-slide.png';
import enterprise from './free-icon-enterprise.png';
import creditCard from './free-icon-credit-card.png';
import chart from './free-icon-chart.png';
import estate from './free-icon-real-estate.png';
import analytics from './free-icon-data-analytics.png';
import analysis from './free-icon-analysis.png';
import scientist from './free-icon-data-scientist.png';
import startUp from './free-icon-start-up.png';
import storage from './free-icon-shared-storage.png';
import application from './free-icon-application.png';
import booking from './free-icon-booking.png';
import building from './free-icon-building.png';
import audit from './free-icon-audit.png';
import analyticsApp from './free-icon-analytics-app.png';
import activation from './activation.png';
import notice1 from './notice1.png';
import notice2 from './notice2.png';
import alertCircle from './eva_alert-circle.png';
import policyIcon from './policy_icon.png';
import moon from './line-md_moon.png';
import sunny from './line-md_sunny.png';
import file from './bx_file.png';
import iconClose from './icon-close.png';
import lockIcon from './ic_outline-lock.png';
import bigdataLogoBack from './bigdata_logo_bk.png';
import dxLogoBack from './dx_logo_bk.png';
import waveLogo from './data_wave_logo.svg';

import snsFb from './sns_fb.png';
import snsInsta from './sns_insta.png';
import snsYtb from './sns_ytb.png';
import snsCh from './sns_ch.png';
import dataLab01 from './data_lab_01.jpg';
import dataLab02 from './data_lab_02.jpg';
import dataLab03 from './data_lab_03.jpg';
import keyboardArrowDown from './keyboard_arrow_down.png';
import keyboardArrowUp from './keyboard_arrow_up.png';
import analysis1 from './analysis_Rstudio.png';
import analysis2 from './analysis_jupyter.png';
import analysis3 from './analysis_Tableau.png';
import analysis4 from './analysis_qgis.png';
import floatSubs from './floating_subscription.png';
import floatQna from './floating_question.png';
import floatNews from './floating_newsletter.png';
import floatNotice from './floating_notice.png';
import nextLinkIcon from './next_link_icon.png';
import facebook from './Facebook.png';
import instagram from './Instagram.png';
import ytbCircle from './youtube_social_circle_red.png';
import kakaochannel from './kakaochannel.png';
import logoMainFoot from './logo_main_foot.png';
import spaceSm01 from './space_sm01.jpg';
import spaceSm02 from './space_sm02.jpg';
import spaceSm03 from './space_sm03.jpg';
import spaceSm04 from './space_sm04.jpg';
import center1 from './center_1.png';
import center2 from './center_2.png';
import center3 from './center_3.png';
import center4 from './center_4.png';
import center5 from './center_5.jpg';
import center6 from './center_6.png';
import center7 from './center_7.png';
import center8 from './center_8.png';
import center9 from './center_9.png';
import center10 from './center_10.png';

import space from './space.jpg';

export default {
  iconLike,
  mQuickIco01,
  mQuickIco02,
  mQuickIco03,
  mQuickIco04,
  mQuickIco05,
  mQuickIco06,
  mQuickIco07,
  mingcuteTime,
  corporate,
  market,
  productivity,
  progressing,
  innovation,
  business,
  nurturing,
  teamwork,
  location,
  trafficIcon,
  trafficIcon002,

  analytics,
  analysis,
  scientist,
  startUp,
  storage,
  application,
  booking,
  building,
  audit,
  analyticsApp,
  alertCircle,
  moon,
  sunny,
  file,
  iconClose,
  lockIcon,
  bigdataLogoBack,
  dxLogoBack,
  snsFb,
  snsInsta,
  snsYtb,
  snsCh,
  keyboardArrowDown,
  keyboardArrowUp,
  step2,
  analysis1,
  analysis2,
  analysis3,
  analysis4,
  floatSubs,
  floatQna,
  floatNews,
  floatNotice,
  nextLinkIcon,
  facebook,
  instagram,
  ytbCircle,
  kakaochannel,

  // image
  logo,
  waveLogo,
  sales,
  dxLogo,
  house,
  busanLogo,
  logo02,
  logo04,
  logo05,
  logo06,
  logoFoot,
  topButton,
  faSolidHouse,
  primePrint,
  map,
  office,
  download,
  taxi,
  road,
  salesSlide,
  enterprise,
  creditCard,
  chart,
  estate,
  activation,
  notice1,
  notice2,
  policyIcon,
  dataLab01,
  dataLab02,
  dataLab03,
  space,
  logoMainFoot,
  spaceSm01,
  spaceSm02,
  spaceSm03,
  spaceSm04,
  center1,
  center2,
  center3,
  center4,
  center5,
  center6,
  center7,
  center8,
  center9,
  center10,
};
