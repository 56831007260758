import React, { useState } from 'react';
import Filter from '../../components/Filter';
import { images } from '../../assets';
import Modal from '../../components/UserCollectModal';
import { RouterPath } from '../../common/constants';
import {
  apiRemoveNewsLetterApply,
  apiSaveNewsLetterApply,
} from '../../api/RestAPI';
import { CustomSwal } from '../../components';
import { handleError } from '../../utils/HandleError';

function SubscribeNewsletter(props) {
  const [showModal, setShowModal] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const removeNewsLetterApply = () => {
    if (!userEmail.trim()) {
      CustomSwal.fire({
        title: '알림',
        text: '이메일을 입력해 주세요.',
      });
      return;
    }

    const data = {
      userEmail,
    };
    apiRemoveNewsLetterApply(data)
      .then(response => {
        CustomSwal.fire({
          title: '알림',
          text: '해당 이메일로 수신거부 되었습니다.',
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  const saveNewsLetterApply = () => {
    if (!userEmail.trim()) {
      CustomSwal.fire({
        title: '알림',
        text: '이메일을 입력해 주세요.',
      });
      return;
    }

    const data = {
      userEmail,
    };
    apiSaveNewsLetterApply(data)
      .then(response => {
        CustomSwal.fire({
          title: '알림',
          text: '해당 이메일로 구독 처리 되었습니다.',
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  const onClickCheck = () => {
    if (!isCheck) {
      setShowModal(true);
    } else {
      setIsCheck(false);
    }
  };

  const handleEmailChange = e => {
    if (!isCheck) {
      CustomSwal.fire({
        title: '알림',
        text: '개인정보 수집 및 이용 동의에 체크해 주세요.',
      });
    } else {
      setUserEmail(e.target.value);
    }
  };

  return (
    <div>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.newsList}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>뉴스레터 구독신청</p>
            <div className="divider_title" />
          </div>
          <form className="form_info_sub">
            <img src={images.notice2} alt="img" />
            <p className="content_form">
              부산 빅데이터 혁신센터 뉴스레터를 신청하세요.
            </p>
            <div className="item_form_1">
              <input
                type="email"
                placeholder="이메일 주소를 입력해 주세요."
                className="input_form_notice"
                value={userEmail}
                onChange={handleEmailChange}
                required
              />

              <div style={{ textAlign: 'center' }}>
                <button
                  type="button"
                  className="btn_form_notice"
                  onClick={saveNewsLetterApply}
                >
                  구독신청
                </button>
                {'  '}
                <button
                  type="button"
                  className="btn_form_notice"
                  onClick={removeNewsLetterApply}
                >
                  수신거부
                </button>
              </div>
            </div>
            <div className="item_form_2">
              <div className="item_field">
                <input
                  type="checkbox"
                  id="check_box"
                  className="checkbox_create"
                  checked={isCheck}
                  onChange={onClickCheck}
                />

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="check_box" className="label_checkbox_create">
                  [필수] 개인정보 수집 및 이용 동의
                </label>
              </div>
              <button
                type="button"
                className="btn_form_check_notice"
                onClick={() => setShowModal(true)}
              >
                내용보기
              </button>
            </div>
          </form>

          <p className="form_note">
            ※ 신청하신 이메일을 입력하시면 구독신청을 취소 하실 수 있습니다.
          </p>
        </div>
        {showModal && (
          <Modal
            setShowModal={setShowModal}
            setIsChecked={setIsCheck}
            isCheck={isCheck}
          />
        )}
      </section>
    </div>
  );
}

export default SubscribeNewsletter;
