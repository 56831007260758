import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { images } from '../assets';
import { RouterPath } from '../common/constants';

function Filter(props) {
  /* const defaultLoginList = [
    {
      name: '센터소개',
      subList: [
        { name: '센터소개', path: RouterPath.centerGuide },
        { name: '공간안내', path: RouterPath.spaceGuide },
        { name: '시설안내', path: RouterPath.facilityGuide },
        { name: '조직도', path: RouterPath.chartGuide },
        { name: '오시는 길', path: RouterPath.mapGuide },
      ],
    },
    {
      name: '지원사업',
      subList: [
        {
          name: '데이터 경제 기반의 디지털 전환 생태계 조성',
          path: RouterPath.supportLead,
        },
        {
          name: '공공 민간 데이터 활용 활성화 지원',
          path: RouterPath.supportCollabo,
        },
        {
          name: '실무 중심의 융합형 데이터 인재 양성',
          path: RouterPath.supportPractice,
        },
        {
          name: '데이터 산업 혁신성장 기반 강화',
          path: RouterPath.supportInnovation,
        },
      ],
    },
    {
      name: '데이터 오픈랩',
      subList: [
        { name: '데이터 오픈랩 소개', path: RouterPath.introDataLab },
        { name: '데이터 리스트', path: RouterPath.bigdataList },
        { name: '이용 및 운영안내', path: RouterPath.operation },
        { name: '분석환경 안내', path: RouterPath.analysis },
        { name: '이용신청', path: RouterPath.application },
        { name: '이용신청내역', path: RouterPath.appuselist },
      ],
    },
    {
      name: '활용사례',
      subList: [
        { name: '활용사례', path: RouterPath.useCase },
        { name: '자료실', path: RouterPath.referenceList },
      ],
    },
    {
      name: '알림마당',
      subList: [
        { name: '공지사항', path: RouterPath.noticeList },
        { name: '포토&뉴스', path: RouterPath.photoNewsList },
        { name: '뉴스레터', path: RouterPath.newsList },
        { name: '질의응답', path: RouterPath.qnaList },
        { name: '사업공고', path: RouterPath.businessList },
        { name: '보도자료', path: RouterPath.reportList },
      ],
    },
  ]; */

  const defaultList = [
    {
      name: '센터소개',
      subList: [
        { name: '센터소개', path: RouterPath.centerGuide },
        { name: '공간안내', path: RouterPath.spaceGuide },
        { name: '시설안내', path: RouterPath.facilityGuide },
        { name: '조직도', path: RouterPath.chartGuide },
        { name: '오시는 길', path: RouterPath.mapGuide },
      ],
    },
    {
      name: '지원사업',
      subList: [
        {
          name: '데이터 경제 기반의 디지털 전환 생태계 조성',
          path: RouterPath.supportLead,
        },
        {
          name: '공공 민간 데이터 활용 활성화 지원',
          path: RouterPath.supportCollabo,
        },
        {
          name: '실무 중심의 융합형 데이터 인재 양성',
          path: RouterPath.supportPractice,
        },
        {
          name: '데이터 산업 혁신성장 기반 강화',
          path: RouterPath.supportInnovation,
        },
      ],
    },
    {
      name: '데이터 오픈랩',
      subList: [
        { name: '데이터 오픈랩 소개', path: RouterPath.introDataLab },
        { name: '데이터 리스트', path: RouterPath.bigdataList },
        { name: '이용 및 운영안내', path: RouterPath.operation },
        { name: '분석환경 안내', path: RouterPath.analysis },
        { name: '이용신청', path: RouterPath.application },
      ],
    },
    {
      name: '활용사례',
      subList: [
        { name: '활용사례', path: RouterPath.useCase },
        { name: '자료실', path: RouterPath.referenceList },
      ],
    },
    {
      name: '알림마당',
      subList: [
        { name: '공지사항', path: RouterPath.noticeList },
        { name: '포토&뉴스', path: RouterPath.photoNewsList },
        { name: '뉴스레터', path: RouterPath.newsList },
        { name: '질의응답', path: RouterPath.qnaList },
        { name: '사업공고', path: RouterPath.businessList },
        { name: '보도자료', path: RouterPath.reportList },
      ],
    },
  ];
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(props?.keyword ? props?.keyword : '');

  const [dataClass, setDataClass] = useState(
    props?.dataClass ? props?.dataClass : '',
  );
  const [page, setPage] = useState(1);

  const [isLogin, setIsLogin] = useState(false);
  const [init, setInit] = useState(true);
  const [subListState, setSubListState] = useState([]);
  const [selectedMainMenu, setSelectedMainMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [filterList, setFilterList] = useState(
    /* localStorage.getItem('auth') ? defaultLoginList : */ defaultList,
  );

  const selectMainFilter = e => {
    const { value } = e.target;
    const selected = filterList?.find(filter => filter.name === value);
    setSubListState(selected.subList);
    setSelectedMainMenu(value);
  };

  const selectSubFilter = e => {
    const path = e.target.value;
    // console.log('Selected path::::', path);
    setSelectedSubMenu(path);
    if (path !== 'null') {
      navigate(path);
    }
  };

  useEffect(() => {
    setSelectedMainMenu(props?.mainMenu);
    const selected = filterList?.find(
      filter => filter.name === props?.mainMenu,
    );
    setSubListState(selected.subList);
  }, []);

  useEffect(() => {
    // console.log('init :::::: ', init);
    if (init && subListState.length > 0) {
      // console.log('subListState :::: ', subListState);
      setSelectedSubMenu(props?.subMenu);
      setInit(false);
    }
  }, [subListState]);

  /* useEffect(() => {
    if (localStorage.getItem('auth')) {
      setIsLogin(true);
      setFilterList(defaultLoginList);
    } else {
      setIsLogin(false);
      setFilterList(defaultList);
    }
  }, [localStorage.getItem('auth'), isLogin]); */

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>{props?.mainMenu}</p>
              <p className="copy_search_txt_001">{props?.title}</p>
            </div>
            {props?.isSearch && (
              <div className="">
                <div className="main_sch_box01">
                  <input
                    type="search"
                    id="keyword"
                    name="keyword"
                    title="검색어를 입력해 주세요"
                    placeholder="찾으시는 데이터를 검색해 보세요"
                    className="input_sch_center"
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        navigate(RouterPath.bigdataList, {
                          state: {
                            keyword,
                            dataClass,
                            page,
                          },
                        });
                      }
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    type="button"
                    className="btn_sch_center"
                    onClick={() =>
                      navigate(RouterPath.bigdataList, {
                        state: {
                          keyword,
                          dataClass,
                          page,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="main-quick_wrap_center mobile-hide">
        <div className="container">
          <div className="quick_wg_center">
            <div className="quick_wg_center_left">
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(RouterPath.slash)}
                className="bg_icon_center_001"
              >
                <img src={images.faSolidHouse} alt="solidHouse" />
              </div>
              <select
                onChange={e => selectMainFilter(e)}
                name="quick"
                className="quick_select"
                value={selectedMainMenu}
                disabled
              >
                {filterList?.map((filterItem, index) => (
                  <option
                    key={`main_filter_${index}`}
                    value={`${filterItem.name}`}
                  >
                    {filterItem.name}
                  </option>
                ))}
              </select>
              <div className="divider_right" />
              <select
                onChange={e => selectSubFilter(e)}
                name="quick"
                className="quick_select"
                value={selectedSubMenu}
              >
                {subListState.map((subFilterItem, index) => (
                  <option
                    key={`sub_filter_${index}`}
                    value={`${subFilterItem.path}`}
                  >
                    {subFilterItem.name}
                  </option>
                ))}
              </select>
              <div className="divider_right" />
            </div>

            {/* <div */}
            {/*  style={{ cursor: 'pointer' }} */}
            {/*  onClick={() => navigate(RouterPath.slash)} */}
            {/*  className="bg_icon_center_002" */}
            {/* > */}
            {/*  <img src={images.primePrint} alt="primePrint" /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

Filter.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
};

export default Filter;
