import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import { images } from '../../assets';
import { CustomSwal } from '../../components';
import { getDownloadFile } from '../../api/RestAPI';

function Operation() {
  const navigate = useNavigate();
  const storageToken = JSON.parse(localStorage.getItem('auth'));
  const [isDownloading, setIsDownloading] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      RenderUI(window.innerWidth);
      RenderUI2(window.innerWidth);
    };

    // Call handleResize directly so we can check the initial window size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const applyHandle = () => {
    // console.log('storage token :::: ', storageToken);
    if (
      storageToken &&
      storageToken.accessToken &&
      storageToken.grade === 'USER'
    )
      navigate(RouterPath.dataApply, { state: false });
    else
      CustomSwal.fire({
        title: '안내',
        html: `데이터 이용신청은 로그인 후에 이용하실 수 있습니다. <br> 로그인 페이지로 이동합니다.`,
        then: () => {
          navigate(RouterPath.login);
        },
      });
  };

  const outputTemplateFileDownloadApi = () => {
    setIsDownloading(true);

    const params = {
      filePath: '/pub/files/template/data_openlab_guide.pdf',
    };

    getDownloadFile(params)
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'data_openlab_output_guide.pdf';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setIsDownloading(false);
      })
      .catch(error => {
        CustomSwal.fire({
          title: '알림',
          text: '파일 다운로드에 실패했습니다.',
        });
        setIsDownloading(false);
      });
  };

  // eslint-disable-next-line react/no-unstable-nested-components,no-shadow
  function RenderUI(width) {
    if (width < 768) {
      return (
        <div className="box_application">
          <div className="step_box">
            <img src={images.application} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 01
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">이용신청</div>
              <div>
                회원가입
                <br />
                데이터 검색
                <br />
                이용 신청서 작성
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.booking} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 02
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">이용예약</div>
              <div>
                방문하고자 하는 일정
                <br />
                데이터 활용목적 기재
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.building} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 03
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">혁신센터 방문</div>
              <div>
                데이터와 솔루션을 사용하여
                <br />
                데이터 분석 및 처리 후 반출신청
              </div>
              <div>
                데이터 분석자원
                <br />
                (태블로, 파이썬, R, QGIS)
                <br />
                이용가능
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.audit} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 04
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">검토 및 심사</div>
              <div>
                미개방데이터 제공기관
                <br />
                (부산시 등)에 <br />
                심의의뢰 후 반출여부 결정
              </div>
            </div>
          </div>

          <div className="progress_step_column" />
        </div>
      );
    }
    return (
      <div className="box_application">
        <div className="step_box">
          <img src={images.application} alt="application" />
          <div className="step_txt_001">STEP 01</div>
          <div className="step_txt_002">이용신청</div>
          <div>
            회원가입
            <br />
            데이터 검색
            <br />
            이용 신청서 작성
          </div>
        </div>
        <div className="step_box">
          <img src={images.booking} alt="application" />
          <div className="step_txt_001">STEP 02</div>
          <div className="step_txt_002">이용예약</div>
          <div>
            방문하고자 하는 일정
            <br />
            데이터 활용목적 기재
          </div>
        </div>
        <div className="step_box">
          <img src={images.building} alt="application" />
          <div className="step_txt_001">STEP 03</div>
          <div className="step_txt_002">혁신센터 방문</div>
          <div>
            데이터와 솔루션을 사용하여
            <br />
            데이터 분석 및 처리 후 반출신청
          </div>
          <br />
          <div>
            데이터 분석자원
            <br />
            (태블로, 파이썬, R, QGIS)
            <br />
            이용가능
          </div>
        </div>
        <div className="step_box">
          <img src={images.audit} alt="application" />
          <div className="step_txt_001">STEP 04</div>
          <div className="step_txt_002">검토 및 심사</div>
          <div>
            미개방데이터 제공기관
            <br />
            (부산시 등)에 <br />
            심의의뢰 후 반출여부 결정
          </div>
        </div>
        <div className="progress_step" />
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components,no-shadow
  function RenderUI2(width) {
    if (width < 768) {
      return (
        <div className="box_application">
          <div className="step_box">
            <img src={images.application} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 01
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">반출지침확인</div>
              <div>
                하단의 [반출지침]을
                <br />
                다운로드 하여
                <br />
                가능 여부 확인
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.booking} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 02
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">반출신청서 작성</div>
              <div>
                오픈랩 방문 후<br />
                반출 신청서 작성
              </div>
            </div>
          </div>

          <div className="step_box">
            <img src={images.audit} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 03
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">반출승인여부 검토</div>
              <div>
                내부 담당자 승인 검투 시행
                <br />
                (최대 5일 소요), <br />
                필요 시 데이터 제공기관 <br />
                심의의뢰
              </div>
            </div>
          </div>
          <div className="step_box">
            <img src={images.analyticsApp} alt="application" />
            <div
              className="flex-column"
              style={{ textAlign: 'left', rowGap: 8 }}
            >
              <div className="step_txt_001">
                STEP 04
                <div className="dot_progress" />
              </div>
              <div className="step_txt_002">검출 결과 통보</div>
              <div>
                내부 심사 후<br />
                반출 결과 통보 및<br />
                승인 시 반출 파일 송부
                <br />
                (이메일 혹은 포털)
              </div>
            </div>
          </div>

          <div className="progress_step_column" style={{ height: '75%' }} />
        </div>
      );
    }
    if (width > 768) {
      return (
        <div className="box_application">
          <div className="step_box">
            <img src={images.application} alt="application" />
            <div className="step_txt_001">STEP 01</div>
            <div className="step_txt_002">이용신청</div>
            <div>
              회원가입
              <br />
              데이터 검색
              <br />
              이용 신청서 작성
            </div>
          </div>
          <div className="step_box">
            <img src={images.booking} alt="application" />
            <div className="step_txt_001">STEP 02</div>
            <div className="step_txt_002">이용예약</div>
            <div>
              방문하고자 하는 일정
              <br />
              데이터 활용목적 기재
            </div>
          </div>

          <div className="step_box">
            <img src={images.audit} alt="application" />
            <div className="step_txt_001">STEP 03</div>
            <div className="step_txt_002">검토 및 심사</div>
            <div>
              미개방데이터 제공기관
              <br />
              (부산시 등)에 <br />
              심의의뢰 후 반출여부 결정
            </div>
          </div>
          <div className="step_box">
            <img src={images.analyticsApp} alt="application" />
            <div className="step_txt_001">STEP 04</div>
            <div className="step_txt_002">결과반출</div>
            <div>
              승인된 분석결과물을
              <br />
              신청자에게 제공
            </div>
          </div>
          <div className="progress_step_002" />
        </div>
      );
    }
  }

  return (
    <div>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.operation}
        // isSearch
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>이용 및 운영안내</p>
            <div className="divider_title" />
          </div>

          <div>
            <div style={{ marginBottom: 24 }} className="traffic_map">
              이용안내
            </div>
            {RenderUI(width)}

            <button
              className="btn_operation"
              onClick={() => applyHandle()}
              type="button"
            >
              이용신청
            </button>

            <div style={{ marginBottom: 24 }} className="traffic_map">
              반출절차안내
            </div>

            {RenderUI2(width)}

            <button
              className="btn_operation"
              onClick={() => outputTemplateFileDownloadApi()}
              type="button"
            >
              반출 지침 다운로드
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Operation;
