import React from 'react';
import { images } from '../assets';
import Filter from '../components/Filter';
import { RouterPath } from '../common/constants';

function CenterGuide() {
  const onChange = e => {
    // console.log(e.target.value);
  };
  const onClick = () => {};
  return (
    <>
      <Filter
        onChange={onChange}
        title="Busan Big Data Innovation Center"
        onClick={onClick}
        mainMenu="센터소개"
        subMenu={RouterPath.centerGuide}
        isSearch={false}
      />

      <section className="center_info">
        <div className="container">
          <div className="center_title">
            <p>센터소개</p>
            <div className="divider_title" />
            <div className="center_title_002">
              부산 데이터 산업 생태계 기획, 데이터 사업 발굴, 기업 육성 등
              데이터산업 생태계 조성을 위한 거점 공간
            </div>
          </div>

          <div className="center_goal_box">
            <div className="center_goal_title">
              <p>Purpose</p>
            </div>
          </div>

          <div className="center_card_box">
            <div className="center_card_info">
              <img src={images.market} alt="coporate" />
              <div>
                부산지역 자생적 데이터산업 생태계 조성 및 데이터 기반 산업혁신을
                위한 거점 마련
              </div>
            </div>
            <div className="center_card_info">
              <img src={images.productivity} alt="coporate" />
              <div>
                데이터 공유 및 활용 체계 구축을 통한 지역내 데이터산업 경쟁력
                강화
              </div>
            </div>
            <div className="center_card_info">
              <img src={images.corporate} alt="coporate" />
              <div>
                데이터산업 기획, 사업 발굴 및 기업 육성을 통한 데이터산업의 촉진
              </div>
            </div>
          </div>

          <div className="center_goal_box_002">
            <div className="center_goal_title_002">
              <p>Direction</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container content_center">
          <div className="title_content_center">
            부산형 데이터 산업 혁신 생태계 구축
          </div>
          <div className="divider_title_content" />
          <div className="content_body_001">
            <div className="card_body_001">
              <img src={images.progressing} alt="progressing" />
              <div>
                데이터 산업 선순환
                <br />
                자생적 생태계 조성
              </div>
            </div>
            <div className="card_body_001">
              <img src={images.sales} alt="progressing" />
              <div>
                데이터 기반
                <br />
                새로운 가치 창출
              </div>
            </div>
          </div>
          <div className="content_body_002">
            <div className="card_body_002">
              <div className="card_body_title_002">
                <img src={images.innovation} alt="innovation" />
                <div>
                  데이터 산업 <br />
                  혁신 성장 기반 조성
                </div>
              </div>
              <div className="divider_card_body_002" />
              <div className="card_box_txt_002">
                <div className="card_txt_002">
                  데이터 생태계 조성을 위한 선도적
                  <br />
                  거점구축 및 기능 고도화
                </div>
                <div className="card_txt_002">
                  데이터 산업 육성을 위한 지산학 협력
                  <br />
                  거버넌스 구축
                </div>
              </div>
            </div>
            <div className="card_body_002">
              <div className="card_body_title_002">
                <img src={images.business} alt="business" />
                <div>
                  데이터 기반
                  <br />
                  전문 기업 육성
                </div>
              </div>
              <div className="divider_card_body_002" />
              <div className="card_box_txt_002">
                <div className="card_txt_002">
                  데이터 기반 상용 기술개발 및 특화
                  <br />
                  서비스 개발지원
                </div>
                <div className="card_txt_002">
                  공공데이터 활용 및 실증 서비스 개발
                  <br />
                  지원
                </div>
              </div>
            </div>
            <div className="card_body_002">
              <div className="card_body_title_002">
                <img src={images.nurturing} alt="nurture" />
                <div>
                  융합형 데이터
                  <br />
                  전문 인재 양성
                </div>
              </div>
              <div className="divider_card_body_002" />
              <div className="card_box_txt_002">
                <div className="card_txt_002">
                  지산학 협력 기반 데이터 전문 교육
                  <br />
                  협력체계 구축
                </div>
                <div className="card_txt_002">
                  수요맞춤형 데이터 전문
                  <br />
                  교육프로그램 운영
                </div>
              </div>
            </div>
            <div className="card_body_002">
              <div className="card_body_title_002">
                <img src={images.teamwork} alt="teamwork" />
                <div>
                  데이터 기반
                  <br />
                  협력 네트워크 구축
                </div>
              </div>
              <div className="divider_card_body_002" />
              <div className="card_box_txt_002">
                <div className="card_txt_002">
                  데이터 공유 및 활용을 위한 유관기관간의 협력 모델 구축
                  <br />
                </div>
                <div className="card_txt_002">
                  지역시민 및 기업 등의 데이터 활용 효율성 증대
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CenterGuide;
