import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  apiConfirmCertEmail,
  apiGetUserMyInfo,
  apiSendCertEmail,
} from '../../api/RestAPI';
import { handleError } from '../../utils/HandleError';
import CustomSwal from '../../components/CustomSwal';
import RouterPath from '../../common/constants/RouterPath';

function EmailCert() {
  const navigate = useNavigate();
  const location = useLocation();
  const props = location.state;
  const [showConfirm, setShowConfirm] = useState(false);
  const [certIdx, setCertIdx] = useState(null);
  const [id, setId] = useState(null);
  const [myEmail, setMyEmail] = useState('');
  const [name, setName] = useState('');
  const [certNo, setCertNo] = useState('');
  const getUserMyInfo = () => {
    apiGetUserMyInfo()
      .then(response => {
        setMyEmail(
          response.data.data?.userEmail ? response.data.data.userEmail : '',
        );
        setName(
          response.data.data?.userName ? response.data.data.userName : '',
        );
        setId(
          response.data.data?.userLoginId ? response.data.data.userLoginId : '',
        );
      })
      .catch(error => {
        handleError(error);
      });
  };

  const sendUserCertEmail = () => {
    const data = {
      email: myEmail,
      userName: name,
      certType: 'JOIN',
    };

    apiSendCertEmail(data)
      .then(response => {
        setCertIdx(response.data.data.certIdx);
        setShowConfirm(true);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const confirmUserCertEmail = () => {
    const data = {
      certIdx,
      certCode: certNo,
      certMethod: 'EMAIL',
      certType: 'JOIN',
      certTarget: myEmail,
      loginId: id,
    };

    apiConfirmCertEmail(data)
      .then(response => {
        CustomSwal.fire({
          title: '이메일 인증',
          html: `이메일 인증 완료 되었습니다. <br> 다시 로그인 해주세요.`,
          then: () => {
            localStorage.removeItem('auth');
            localStorage.removeItem('access_token');
            navigate(RouterPath.slash, { replace: true });
          },
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (!props) getUserMyInfo();
    else {
      setMyEmail(props.email);
      setName(props.name);
      setId(props.id);
    }
  }, [props]);

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg_login">
        <div className="container">
          <div style={{ marginBottom: 40 }} className="center_title">
            <p>e-mail 인증</p>
            <div className="divider_title" />

            <div className="intro_txt_001">
              회원가입 후 사이트를 이용하시려면 이메일 인증이 필수입니다.
            </div>
          </div>

          <div className="box_login">
            <div className="tap_email_cert">
              <input
                type="email"
                placeholder="이메일(email)을 입력해주세요"
                className="inp_pw"
                value={myEmail}
                disabled
                onChange={e => setMyEmail(e.target.value)}
              />
              <input
                type="email"
                placeholder="이름을 입력해주세요"
                className="inp_pw"
                value={name}
                disabled
                onChange={e => setName(e.target.value)}
              />

              <button
                disabled={showConfirm}
                onClick={() => sendUserCertEmail()}
                className={!showConfirm ? 'btn_login' : 'btn_login_disabled'}
                type="button"
              >
                인증번호 전송
              </button>

              {showConfirm && (
                <>
                  <input
                    style={{ marginTop: 20 }}
                    type="email"
                    placeholder="인증번호 입력"
                    className="inp_pw"
                    value={certNo}
                    onChange={e => setCertNo(e.target.value)}
                  />

                  <button
                    type="button"
                    onClick={() => confirmUserCertEmail()}
                    className="btn_confirm"
                  >
                    인증번호 확인
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailCert;
