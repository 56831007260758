import React, { useEffect } from 'react';

function CustomMapComponent() {
  // useEffect(() => {
  //   const loadScript = src => {
  //     return new Promise((resolve, reject) => {
  //       const script = document.createElement('script');
  //       script.src = src;
  //       script.charset = 'UTF-8';
  //       script.onload = () => resolve(script);
  //       script.onerror = () =>
  //         reject(new Error(`Script load error for ${src}`));
  //       document.head.appendChild(script);
  //     });
  //   };
  //
  //   loadScript('https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js')
  //     .then(data => {
  //       console.log(data);
  //       // eslint-disable-next-line no-undef
  //       new daum.roughmap.Lander({
  //         timestamp: '1679644232271',
  //         key: '2e7pe',
  //         mapHeight: '360',
  //         mapWidth: '100%', // 추가: 지도의 너비 설정
  //       }).render(document.getElementById('bigdata_kakao_map')); // 변경: 대상 div 지정
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  //
  //   // Cleanup function to remove the script when the component unmounts
  //   return () => {
  //     const script = document.querySelector(
  //       'script[src="https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js"]',
  //     );
  //     if (script) {
  //       document.head.removeChild(script);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // Load the Daum map script
    const script = document.createElement('script');
    script.charset = 'UTF-8';
    script.src = 'https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js';
    script.onload = data => {
      // console.log(data);
      // eslint-disable-next-line no-undef
      new daum.roughmap.Lander({
        timestamp: '1679644232271',
        key: '2e7pe',
        mapHeight: '720',
      }).render();
    };
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    // <div id="bigdata_kakao_map" style={{ height: '360px', width: '100%' }} />

    <div id="map">
      <div
        id="daumRoughmapContainer1679644232271"
        className="root_daum_roughmap root_daum_roughmap_landing"
        style={{ width: '100%' }}
      />
    </div>
  );
}

export default CustomMapComponent;
