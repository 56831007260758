import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BannerSlider from '../components/BannerSlider';
import { images } from '../assets';
import BannerSLider02 from '../components/BannerSLider02';
import BannerSlider03 from '../components/BannerSlide03';
import BannerSlide04 from '../components/BannerSlide04';
import {
  apiGetBigdataList,
  apiGetBigdataTypesInfo,
  apiGetFavoriteBigdataClass,
  apiGetFloatList,
  apiGetLibraryList,
  apiGetMainPageObject,
  apiGetNewsLetterList,
  apiGetNoticeList,
  apiGetPhotoNewsList,
  apiGetPopupList,
  apiGetSocialList,
  apiGetUseCaseList,
} from '../api/RestAPI';
import { handleError } from '../utils/HandleError';
import Utils from '../utils/Utils';
import { RouterPath } from '../common/constants';
import SwiperLogo from '../components/SwiperLogo';
import PopupModal from '../components/Modal/PopupModal';
import SwiperSNS from '../components/SwiperSNS';
import ApiUtils from '../utils/ApiUtils';

function Home() {
  const navigate = useNavigate();
  const today = new Date();
  const popupToday = new Date().toISOString().split('T')[0];
  const [searchBigdataKeyword, setSearchBigdataKeyword] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const tabContents = [1, 2];
  const [mainPageObject, setMainPageObject] = useState(null);
  const [bigdataTypesInfo, setBigdataTypesInfo] = useState([]);
  const [recentBigdataList, setRecentBigdataList] = useState([]);
  const [favoriteBigdataList, setFavoriteBigdataList] = useState([]);

  const [tableActiveTab, setTableActiveTab] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [newsletterList, setNewsletterList] = useState([]);
  const [libraryList, setLibraryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [popupList, setPopupList] = useState([]);
  const [activePopups, setActivePopups] = useState([]);
  const [popupPositions, setPopupPositions] = useState({});

  const [floatMenu, setFloatMenus] = useState([]);

  const [photoNewsList, setPhotoNewsList] = useState([]);
  const [photoNewsImageList, setPhotoNewsImageList] = useState([]);

  const [socialList, setSocialList] = useState([]);
  const [socialImageList, setSocialImageList] = useState([]);

  const [useCaseList, setUseCaseList] = useState([]);
  const [useCaseImageList, setUseCaseImageList] = useState([]);

  /* ======================================================================== */
  const getMainPageObject = () => {
    apiGetMainPageObject()
      .then(response => {
        setMainPageObject(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getBigdataTypesInfo = () => {
    apiGetBigdataTypesInfo()
      .then(response => {
        setBigdataTypesInfo(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getPhotoNewsList = () => {
    const data = {
      page: 1,
      size: 5,
    };

    apiGetPhotoNewsList(data)
      .then(response => {
        setPhotoNewsList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getNoticeList = () => {
    const data = {
      page: 1,
      size: 5,
    };

    apiGetNoticeList(data)
      .then(response => {
        setNoticeList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getNewsLetterList = () => {
    const data = {
      page: 1,
      size: 5,
    };

    apiGetNewsLetterList(data)
      .then(response => {
        setNewsletterList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getFavoriteBigdataClassList = () => {
    apiGetFavoriteBigdataClass()
      .then(response => {
        setTagList(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getLibraryList = () => {
    const data = {
      page: 1,
      size: 5,
    };

    apiGetLibraryList(data)
      .then(response => {
        setLibraryList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });
  };
  const getRecentAndFavoriteBigdataList = () => {
    const recentData = {
      searchType: 'RECENT_BIGDATA',
      page: 1,
      size: 3,
    };
    const favoriteData = {
      searchType: 'FAVORITE_BIGDATA',
      page: 1,
      size: 3,
    };

    apiGetBigdataList(recentData)
      .then(response => {
        setRecentBigdataList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });

    apiGetBigdataList(favoriteData)
      .then(response => {
        setFavoriteBigdataList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getSocialList = () => {
    apiGetSocialList()
      .then(response => {
        setSocialList(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getUseCaseList = () => {
    const data = {
      keyword: null,
      page: 1,
      size: 5,
      topYn: 'Y',
    };

    apiGetUseCaseList(data)
      .then(response => {
        setUseCaseList(response.data.data.list);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getFloatList = () => {
    apiGetFloatList()
      .then(response => {
        setFloatMenus(response.data.data);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const getPopupList = () => {
    apiGetPopupList()
      .then(response => {
        const popups = response.data.data;

        const disabledList = localStorage.getItem('popup_disable');
        if (disabledList) {
          const disabledPopups = JSON.parse(disabledList);
          const enabledPopups = popups.filter(
            popup =>
              !disabledPopups.some(
                disabled =>
                  disabled.idx === popup.popupIdx &&
                  disabled.date === popupToday,
              ),
          );
          setPopupList(enabledPopups);
        } else {
          setPopupList(popups);
        }
      })
      .catch(error => {
        handleError(error);
      });
  };
  /* ======================================================================== */

  const handleTabClick = index => {
    setActiveTab(index);
  };

  const handleTableTabClick = index => {
    setTableActiveTab(index);
  };

  const closePopup = idx => {
    setActivePopups(prevActivePopups =>
      prevActivePopups.filter((_, i) => i !== idx),
    );
  };

  const disablePopup = idx => {
    const disabledList = localStorage.getItem('popup_disable');
    const updatedDisabledList = disabledList ? JSON.parse(disabledList) : [];

    updatedDisabledList.push({
      idx: activePopups[idx].popupIdx,
      date: popupToday,
    });
    localStorage.setItem('popup_disable', JSON.stringify(updatedDisabledList));

    setActivePopups(prevActivePopups =>
      prevActivePopups.filter((_, i) => i !== idx),
    );
  };

  const calculatePopupPositions = popups => {
    let xOffset = 30;
    const positions = {};

    popups.forEach((popup, index) => {
      positions[popup.popupIdx] = { x: xOffset, y: 10 };

      if (window.innerWidth > 768) {
        xOffset += popup.pcWidth + 30;
      }
    });

    return positions;
  };

  /* ======================================================================== */

  useEffect(() => {
    getMainPageObject();
    getBigdataTypesInfo();
    getRecentAndFavoriteBigdataList();
    getPhotoNewsList();
    getNoticeList();
    getNewsLetterList();
    getLibraryList();
    getFavoriteBigdataClassList();
    getPopupList();
    getFloatList();
    getSocialList();
    getUseCaseList();
  }, []);

  useEffect(() => {
    Utils.truncateText('.contentTitle', 32, 440);
  }, [activeTab]);

  /* ======================================================================== */
  useEffect(() => {
    const loadPopups = async () => {
      if (popupList.length > 0) {
        const popupPromises = popupList.map(async (popup, index) => {
          if (!activePopups.some(ap => ap.popupIdx === popup.popupIdx)) {
            const path = await ApiUtils.fetchImage(popup.thumbFileUrl);

            // console.log(path);
            if (!path) {
              return null;
            }
            return {
              actionLink: popup.actionLink,
              imagePath: path,
              mobHeight: popup.mobHeight,
              mobWidth: popup.mobWidth,
              pcHeight: popup.pcHeight,
              pcWidth: popup.pcWidth,
              popupIdx: popup.popupIdx,
            };
          }
          return null;
        });

        const results = await Promise.all(popupPromises);
        const newPopups = results.filter(popup => popup !== null);

        const positions = calculatePopupPositions(newPopups);

        setActivePopups(prevActivePopups => [
          ...prevActivePopups,
          ...newPopups,
        ]);
        setPopupPositions(positions);
      }
    };

    loadPopups();
  }, [popupList]);

  useEffect(() => {
    photoNewsList.forEach(item => {
      if (!photoNewsImageList[item.thumbFileUrl]) {
        ApiUtils.fetchImageToList(item.thumbFileUrl, setPhotoNewsImageList);
      }
    });
  }, [photoNewsList]);

  useEffect(() => {
    socialList.forEach(item => {
      if (!socialImageList[item.thumbFileUrl]) {
        ApiUtils.fetchImageToList(item.thumbFileUrl, setSocialImageList);
      }
    });
  }, [socialList]);

  useEffect(() => {
    useCaseList.forEach(item => {
      if (!useCaseImageList[item.thumbFileUrl]) {
        ApiUtils.fetchImageToList(item.thumbFileUrl, setUseCaseImageList);
      }
    });
  }, [useCaseList]);
  /* ======================================================================== */
  return (
    <>
      <div id="menuArea">
        <div className="menuList">
          <h1>
            <a href="./" className="menu_logo">
              <img src="./assets/img/logo_up.png" alt="logo" />
            </a>
          </h1>
          `
          <ul className="list">
            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                센터소개
              </a>
              <ul className="sMenu">
                <li>
                  <a href="#none">센터소개</a>
                </li>
                <li>
                  <a href="#none">인프라안내</a>
                </li>
                <li>
                  <a href="#none">조직도</a>
                </li>
                <li>
                  <a href="#none">오시는 길</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                지원사업
              </a>
              <ul className="sMenu">
                <li>
                  <a href="#none">데이터경제 선도 전문기업 육성</a>
                </li>
                <li>
                  <a href="#none">지산학 협력 거버넌스 구축</a>
                </li>
                <li>
                  <a href="#none">공공민간 데이터 이용 활성화</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                데이터 오픈랩
              </a>
              <ul className="sMenu">
                <li>
                  <a href="#none">데이터 오픈랩 소개</a>
                </li>
                <li>
                  <a href="#none">데이터 리스트</a>
                </li>
                <li>
                  <a href="#none">이용 및 운영안내</a>
                </li>
                <li>
                  <a href="#none">분석환경 안내</a>
                </li>
                <li>
                  <a href="#none">이용신청</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                활용사례
              </a>
              <ul className="sMenu">
                <li>
                  <a href="#none">활용사례</a>
                </li>
                <li>
                  <a href="#none">자료실</a>
                </li>
              </ul>
            </li>

            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                알림마당
              </a>
              <ul className="sMenu">
                <li>
                  <a href="#none">공지사항</a>
                </li>
                <li>
                  <a href="#none">포토&뉴스</a>
                </li>
                <li>
                  <a href="#none">뉴스레터</a>
                </li>
                <li>
                  <a href="#none">질의 응답</a>
                </li>
              </ul>
            </li>
            <li className="icon01">
              <a className="dropdown-toggle" href="#none" id="menu_close">
                연계센터
              </a>
              <ul className="sMenu">
                <li>
                  <a href="#none">연계센터</a>
                </li>
              </ul>
            </li>
          </ul>
          <button type="button" className="btnMenu_mClose" title="close">
            Close
          </button>
        </div>
      </div>
      {floatMenu && floatMenu.length > 0 && (
        <div className="floating-menu">
          <ul>
            {floatMenu.map((item, index) => {
              let imgSrc = '';

              switch (item.codeName) {
                case RouterPath.application:
                  imgSrc = images.floatSubs;
                  break;
                case RouterPath.qnaList:
                  imgSrc = images.floatQna;
                  break;
                case RouterPath.newsList:
                  imgSrc = images.floatNews;
                  break;
                case RouterPath.noticeList:
                  imgSrc = images.floatNotice;
                  break;
                default:
                  imgSrc = '';
                  break;
              }

              const borderRadiusStyle =
                floatMenu.length === 1
                  ? {
                      borderRadius: '10px',
                    }
                  : {
                      borderTopLeftRadius: index === 0 ? '10px' : '0px',
                      borderTopRightRadius: index === 0 ? '10px' : '0px',
                      borderBottomLeftRadius:
                        index === floatMenu.length - 1 ? '10px' : '0px',
                      borderBottomRightRadius:
                        index === floatMenu.length - 1 ? '10px' : '0px',
                    };

              return (
                <React.Fragment key={index}>
                  <li
                    className="selector"
                    style={borderRadiusStyle}
                    onClick={() => navigate(item.codeName)}
                  >
                    {imgSrc && (
                      <div>
                        <img src={imgSrc} alt={item.codeName} />
                      </div>
                    )}
                    <div>{item.subCode}</div>
                  </li>
                  {index < floatMenu.length - 1 && (
                    <div className="floating-divider" />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      )}

      <div id="main_vs">
        <div className="container">
          <div className="vs_cont_wg">
            <div className="data_search">
              <div className="sg_wg">
                <div className="copy_txt01">{mainPageObject?.mainTitle}</div>
                <div className="copy_txt02">부산 빅데이터 혁신센터</div>
              </div>

              <div className="sch_wg mobile-hide">
                <div className="scpit_txt">
                  다양한 키워드로 쉽게 데이터를 검색하고 활용할 수 있습니다.
                </div>

                <form
                  id="searchdata"
                  method="post"
                  name="searchdata"
                  className="mobile-hide"
                >
                  <div className="main_sch_box01">
                    <input
                      type="search"
                      id="keyword"
                      name="keyword"
                      title="검색어를 입력해 주세요"
                      placeholder="찾으시는 데이터를 검색해 보세요"
                      className="input-sch"
                      value={searchBigdataKeyword}
                      onChange={e => setSearchBigdataKeyword(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          navigate(RouterPath.bigdataList, {
                            state: {
                              keyword: searchBigdataKeyword,
                            },
                          });
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="btn_sch"
                      onClick={() =>
                        navigate(RouterPath.bigdataList, {
                          state: {
                            keyword: searchBigdataKeyword,
                          },
                        })
                      }
                    >
                      검색하기
                    </button>
                  </div>
                </form>
                <div className="keywords mobile-hide">
                  <strong className="key_head mobile-hide">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={images.iconLike} />
                  </strong>
                  <ul className="key_list">
                    {tagList.map((item, index) => (
                      <li
                        key={`tags_${index}`}
                        onClick={() =>
                          navigate(RouterPath.bigdataList, {
                            state: {
                              dataClass: item.dataClass,
                            },
                          })
                        }
                      >
                        <span>{`#${item.dataClass}`}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <BannerSlider detail={mainPageObject} />
          </div>
        </div>
      </div>
      <div className="main-quick_wrap">
        <div className="container">
          <div className="quick_wg">
            <h2 className="t_type01 tit_simbol">
              <span>분야별</span>
              <br />
              데이터
            </h2>

            <div className="quick_btn">
              <BannerSLider02 list={bigdataTypesInfo} />
            </div>
          </div>
        </div>
      </div>
      <section className="sec_data">
        <div className="container">
          <article id="cont" className="tabList">
            <nav>
              <ul className="tabMenu">
                <li
                  className={activeTab === 0 ? 'on' : ''}
                  onClick={() => handleTabClick(0)}
                >
                  <h2 className="t_tab01">
                    <span>최신</span>데이터
                  </h2>
                </li>
                <li
                  className={activeTab === 1 ? 'on' : ''}
                  onClick={() => handleTabClick(1)}
                >
                  <h2 className="t_tab01">
                    <span>인기</span>데이터
                  </h2>
                </li>
              </ul>
            </nav>
            <article id="tabContent">
              <div>
                {tabContents.map((content, index) => (
                  <article
                    className="tabContentBox"
                    key={index}
                    style={{
                      display: activeTab === index ? 'flex' : 'none',
                    }}
                  >
                    {activeTab === 0 &&
                      recentBigdataList?.map((recentBigdata, recentIndex) => (
                        <div
                          key={`recent_bigdata_list_${recentIndex}`}
                          className="cardMenu"
                          onClick={() =>
                            navigate(
                              `${RouterPath.bigdataDetail}/${recentBigdata.dataIdx}`,
                              {
                                state: recentBigdata,
                              },
                            )
                          }
                        >
                          <div className="cardHeader">
                            <div className="badgeCard">
                              {recentBigdata.dataClass}
                            </div>
                            <img src={images.house} alt="" />
                            <p className="textTruncate">
                              {Utils.shortenText(recentBigdata.dataName, 22)}
                            </p>
                          </div>
                          <div className="cardContent">
                            <p className="contentTitle">
                              {Utils.shortenText(recentBigdata.dataDesc, 32)}
                            </p>
                            <div className="footerContent">
                              <div className="itemBox">
                                <div className="itemFooter">
                                  <span className="badgeCardFooter">
                                    수집기간
                                  </span>
                                  <span>
                                    {recentBigdata.zdateFrom &&
                                      Utils.strDateToFormatDate(
                                        recentBigdata.zdateFrom,
                                      )}
                                    ~
                                    {recentBigdata.zdateTo &&
                                      Utils.strDateToFormatDate(
                                        recentBigdata.zdateTo,
                                      )}
                                  </span>
                                </div>
                                <div className="itemFooter">
                                  <span className="badgeCardFooter">
                                    공간단위
                                  </span>
                                  <span>{recentBigdata.geoRes}</span>
                                </div>
                              </div>
                              <div className="itemBox">
                                <div className="itemFooter">
                                  <span className="badgeCardFooter">
                                    시간단위
                                  </span>
                                  <span>{recentBigdata.timeRes}</span>
                                </div>
                                <div className="itemFooter">
                                  <span className="badgeCardFooter">
                                    적재주기
                                  </span>
                                  <span>{recentBigdata.timeLoadPeriod}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {activeTab === 1 &&
                      favoriteBigdataList?.map(
                        (favoriteBigdata, favoriteIndex) => (
                          <div
                            key={`favorite_bigdata_list_${favoriteIndex}`}
                            className="cardMenu"
                            onClick={() =>
                              navigate(
                                `${RouterPath.bigdataDetail}/${favoriteBigdata.dataIdx}`,
                                {
                                  state: favoriteBigdata,
                                },
                              )
                            }
                          >
                            <div className="cardHeader">
                              <div className="badgeCard">
                                {favoriteBigdata.dataClass}
                              </div>
                              <img src={images.house} alt="" />
                              <p className="textTruncate">
                                {Utils.shortenText(
                                  favoriteBigdata.dataName,
                                  22,
                                )}
                              </p>
                            </div>
                            <div className="cardContent">
                              <p className="contentTitle">
                                {Utils.shortenText(
                                  favoriteBigdata.dataName,
                                  32,
                                )}
                              </p>
                              <div className="footerContent">
                                <div className="itemBox">
                                  <div className="itemFooter">
                                    <span className="badgeCardFooter">
                                      수집기간
                                    </span>
                                    <span>
                                      {favoriteBigdata.zdateFrom &&
                                        Utils.strDateToFormatDate(
                                          favoriteBigdata.zdateFrom,
                                        )}
                                      ~
                                      {favoriteBigdata.zdateTo &&
                                        Utils.strDateToFormatDate(
                                          favoriteBigdata.zdateTo,
                                        )}
                                    </span>
                                  </div>
                                  <div className="itemFooter">
                                    <span className="badgeCardFooter">
                                      공간단위
                                    </span>
                                    <span>{favoriteBigdata.geoRes}</span>
                                  </div>
                                </div>
                                <div className="itemBox">
                                  <div className="itemFooter">
                                    <span className="badgeCardFooter">
                                      시간단위
                                    </span>
                                    <span>{favoriteBigdata.timeRes}</span>
                                  </div>
                                  <div className="itemFooter">
                                    <span className="badgeCardFooter">
                                      적재주기
                                    </span>
                                    <span>
                                      {favoriteBigdata.timeLoadPeriod}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                  </article>
                ))}
              </div>
            </article>
          </article>
        </div>
      </section>
      <section id="news">
        <div className="container">
          <article id="cont" className="tabList">
            <nav>
              <ul className="tabMenu">
                <li className="on">
                  <h2 className="t_tab01" style={{ cursor: 'default' }}>
                    <span>포토</span>&뉴스
                  </h2>
                </li>
              </ul>
            </nav>
          </article>
          <BannerSlider03
            photoNewsList={photoNewsList}
            photoNewsImageList={photoNewsImageList}
          />
        </div>
      </section>
      <section className="notification">
        <div className="container">
          <div className="notificationContainer">
            <div
              className="flex-column"
              style={{ width: '70%', rowGap: 32, margin: '56px 0' }}
            >
              <div style={{ margin: 0 }} className="center_title">
                <p>
                  <span>부산 빅데이터 혁신센터</span> 소식
                </p>
              </div>
              <div className="tabListTable">
                <nav>
                  <ul className="tabMenuTable">
                    <li
                      className={tableActiveTab === 0 ? 'activeTap' : ''}
                      onClick={() => handleTableTabClick(0)}
                    >
                      공지사항
                    </li>
                    <li
                      className={tableActiveTab === 1 ? 'activeTap' : ''}
                      onClick={() => handleTableTabClick(1)}
                    >
                      뉴스레터
                    </li>
                    <li
                      className={tableActiveTab === 2 ? 'activeTap' : ''}
                      onClick={() => handleTableTabClick(2)}
                    >
                      자료실
                    </li>
                  </ul>
                </nav>
                <article className="contentTable">
                  {tableActiveTab === 0 &&
                    noticeList.map((item, index) => (
                      <div
                        key={`notice_${index}`}
                        className="itemContent pointer"
                        onClick={() =>
                          navigate(
                            `${RouterPath.noticeDetail}/${item.boardIdx}`,
                          )
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          <button
                            type="button"
                            className={
                              Utils.isWithinAWeek(item.regDate)
                                ? 'btn_table_blue'
                                : 'btn_table_blue hidden'
                            }
                          >
                            new
                          </button>
                          {new Date(item.startDate) > today ? (
                            <span type="button" className="badgeOld">
                              예정
                            </span>
                          ) : (
                            <span
                              type="button"
                              className={
                                Utils.isWithinDateRange(
                                  item.startDate,
                                  item.endDate,
                                )
                                  ? 'badgeNew'
                                  : 'badgeOld'
                              }
                            >
                              {Utils.isWithinDateRange(
                                item.startDate,
                                item.endDate,
                              )
                                ? '공지'
                                : '완료'}
                            </span>
                          )}
                          <span className="contentList">
                            {item.noticeTitle}
                          </span>
                        </div>

                        <div className="time">
                          {Utils.formatDate(item.regDate)}
                        </div>
                      </div>
                    ))}
                  {tableActiveTab === 1 &&
                    newsletterList.map((item, index) => (
                      <div
                        key={`newsletter_${index}`}
                        className="itemContent pointer"
                        onClick={() =>
                          navigate(`${RouterPath.newsDetail}/${item.boardIdx}`)
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          <button
                            type="button"
                            className={
                              Utils.isWithinAWeek(item.regDate)
                                ? 'btn_table_blue'
                                : 'btn_table_blue hidden'
                            }
                          >
                            new
                          </button>
                          <span className="contentList">
                            {item.newsLetterTitle}
                          </span>
                        </div>
                        <div className="time">
                          {Utils.formatDate(item.regDate)}
                        </div>
                      </div>
                    ))}
                  {tableActiveTab === 2 &&
                    libraryList.map((item, index) => (
                      <div
                        key={`library_${index}`}
                        className="itemContent pointer"
                        onClick={() =>
                          navigate(
                            `${RouterPath.referenceDetail}/${item.boardIdx}`,
                          )
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          <button
                            type="button"
                            className={
                              Utils.isWithinAWeek(item.regDate)
                                ? 'btn_table_blue'
                                : 'btn_table_blue hidden'
                            }
                          >
                            new
                          </button>
                          <span className="contentList">
                            {item.libraryTitle}
                          </span>
                        </div>
                        <div className="time">
                          {Utils.formatDate(item.regDate)}
                        </div>
                      </div>
                    ))}
                </article>
                <div
                  className="content_table_foot f_400_18"
                  onClick={() =>
                    (tableActiveTab === 0 && navigate(RouterPath.noticeList)) ||
                    (tableActiveTab === 1 && navigate(RouterPath.newsList)) ||
                    (tableActiveTab === 2 && navigate(RouterPath.referenceList))
                  }
                >
                  <p>더보기</p>

                  <img src={images.nextLinkIcon} alt="" />
                </div>
              </div>
            </div>

            <div
              className="center_slide_home"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div
                className="flex-column"
                style={{ rowGap: 32, margin: '56px 0' }}
              >
                <div style={{ margin: 0 }} className="center_title">
                  <p>
                    <span>부산 빅데이터 혁신센터 </span>소통
                  </p>
                </div>

                <div
                  className="flex align-center"
                  style={{
                    position: 'relative',
                  }}
                >
                  <div className="ar_w05">
                    <div className="swiper-button-sns-prev" />
                    <div className="swiper-button-sns-next" />
                  </div>
                  <div className="sns_logo flex">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Bigdata-Busan/100090523700534/"
                        target="_blank"
                        title="새창열림"
                        className="facebook"
                        rel="noreferrer"
                      >
                        <span class="blind">
                          <img src={images.facebook} alt="SNS" />{' '}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/bigdata_in_busan/"
                        target="_blank"
                        title="새창열림"
                        className="insta"
                        rel="noreferrer"
                      >
                        <span class="blind">
                          <img src={images.instagram} alt="SNS" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@busanbigdata"
                        target="_blank"
                        title="새창열림"
                        className="youtube"
                        rel="noreferrer"
                      >
                        <span class="blind">
                          <img src={images.ytbCircle} alt="SNS" />
                        </span>
                      </a>
                    </li>
                  </div>
                </div>

                <SwiperSNS
                  socialList={socialList}
                  socialImageList={socialImageList}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dataAnalysis">
        <div className="container">
          <div className="headerContent">
            <h3>DATA ANALYSIS</h3>
            <h1>분석 및 활용사례</h1>
            <h2>
              빅데이터로 바라본 부산 프로젝트는 데이터를 통해 부산의 다양한
              문제를 진단하고해법을 모색하기 위해 기획되었습니다.
            </h2>
          </div>
        </div>
        <BannerSlide04
          useCaseList={useCaseList}
          useCaseImageList={useCaseImageList}
        />
      </section>
      <SwiperLogo />

      {activePopups.map((data, index) => (
        <PopupModal
          key={index}
          popupData={data}
          position={popupPositions[data.popupIdx]}
          onClose={() => closePopup(index)}
          onDisable={() => disablePopup(index)}
          activePopupIndex={index}
        />
      ))}
    </>
  );
}

export default Home;
