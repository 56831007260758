import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiChangePassword } from '../../api/RestAPI';
import { RESULT_CODE } from '../../common';
import { handleError } from '../../utils/HandleError';
import CustomSwal from '../../components/CustomSwal';
import NullCheckUtils from '../../utils/NullCheckUtils';

function ChangePassword() {
  const navigate = useNavigate();
  const [prevPassword, setPrevPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConf, setNewPasswordConf] = useState('');

  const changePassword = () => {
    if (newPassword !== newPasswordConf) {
      CustomSwal.fire({
        title: '확인',
        text: '비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      });
      return;
    }
    const data = {
      prevPw: prevPassword,
      newPw: newPasswordConf,
    };
    apiChangePassword(data)
      .then(response => {
        if (response.data.message === RESULT_CODE.CODE_SUCCESS) {
          CustomSwal.fire({
            title: '비밀번호 변경',
            text: '비밀번호 변경 완료 되었습니다.',
          });
          navigate(-1);
        }
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg_login"
        style={{
          background: '#F8FBFF',
        }}
      >
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>통합 계정 회원 정보</p>
            <div className="divider_title" />
          </div>
          <div className="traffic_map">비밀번호 변경</div>

          <div className="box_edit_member">
            <div className="flex align-center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>현재 비밀번호</label>
              <input
                type="password"
                className="inp_id"
                value={prevPassword}
                onChange={e => {
                  const inputText = e.target.value;

                  if (
                    !NullCheckUtils.isLimitAndDisplaySwal({
                      state: inputText,
                      message: '비밀번호는 15자까지만 입력할 수 있습니다.',
                      size: 15,
                    })
                  ) {
                    setPrevPassword(inputText);
                  }
                }}
              />
            </div>
            <div className="flex align-center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>새 비밀번호</label>
              <input
                type="password"
                className="inp_id"
                value={newPassword}
                onChange={e => {
                  const inputText = e.target.value;

                  if (
                    !NullCheckUtils.isLimitAndDisplaySwal({
                      state: inputText,
                      message: '비밀번호는 15자까지만 입력할 수 있습니다.',
                      size: 15,
                    })
                  ) {
                    setNewPassword(inputText);
                  }
                }}
              />
            </div>
            <div className="flex align-center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label style={{ width: 150 }}>비밀번호 확인</label>
              <input
                type="password"
                className="inp_id"
                value={newPasswordConf}
                onChange={e => {
                  const inputText = e.target.value;

                  if (
                    !NullCheckUtils.isLimitAndDisplaySwal({
                      state: inputText,
                      message: '비밀번호는 15자까지만 입력할 수 있습니다.',
                      size: 15,
                    })
                  ) {
                    setNewPasswordConf(inputText);
                  }
                }}
              />
            </div>
          </div>

          <div className="edit_btn">
            <button
              type="button"
              className="btn_login"
              onClick={() => changePassword()}
            >
              회원정보수정
            </button>

            <button
              type="button"
              className="btn_login"
              onClick={() => navigate(-1)}
            >
              취소
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
