import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RouterPath } from '../common';
import { images } from '../assets';

function HeaderMobile({ isLogin, handleShowMenu, userInfo }) {
  const [checkLogin, setCheckLogin] = useState(false);
  const [menuActive, setMenuActive] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleActiveMenu = menu => {
    setMenuActive(prevMenu => (prevMenu === menu ? null : menu));
  };

  const handleLogOut = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('access_token');
    navigate(0);
  };
  // useEffect(() => {
  //   console.log('userInfo:::: ', userInfo);
  // }, [userInfo]);

  return (
    <div className="menuMobileBase">
      <div className="menuMobile">
        {!isLogin && (
          <div
            className="f_20"
            style={{ margin: '24px 16px 16px 16px', textAlign: 'left' }}
          >
            로그인 해주세요
          </div>
        )}
        {isLogin && (
          <div
            className="f_20"
            style={{ margin: '24px 16px 16px 16px', textAlign: 'left' }}
          >
            {userInfo.userNm} 님 반갑습니다.
          </div>
        )}
        <div className="header_top_mobile">
          <ul className="member" style={{ marginLeft: 16 }}>
            {!isLogin && (
              <>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      navigate(RouterPath.login);
                      handleShowMenu(false);
                    }}
                    className="login f_pre_700_18"
                  >
                    로그인
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      navigate(RouterPath.register, { state: false });
                      handleShowMenu(false);
                    }}
                    className="f_pre_700_18"
                  >
                    회원가입
                  </a>
                </li>
              </>
            )}
            {isLogin && (
              <>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      handleLogOut();
                      handleShowMenu(false);
                    }}
                    className="logout f_pre_700_18"
                  >
                    로그아웃
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      navigate(RouterPath.userModify, { state: false });
                      handleShowMenu(false);
                    }}
                    className="f_pre_700_18"
                  >
                    마이페이지
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className="flex-column">
            <div className="box_nav_header f_20">
              <div
                onClick={() => handleActiveMenu('CENTER')}
                className="flex align-center"
              >
                <p>센터소개</p>
                <img
                  src={
                    menuActive === 'CENTER'
                      ? images.keyboardArrowUp
                      : images.keyboardArrowDown
                  }
                  alt="down"
                />
              </div>

              {menuActive === 'CENTER' && (
                <div className="box-expand">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.centerGuide);
                          handleShowMenu(false);
                        }}
                      >
                        센터소개
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.spaceGuide);
                          handleShowMenu(false);
                        }}
                      >
                        공간안내
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.facilityGuide);
                          handleShowMenu(false);
                        }}
                      >
                        시설안내
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.chartGuide);
                          handleShowMenu(false);
                        }}
                      >
                        조직도
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.mapGuide);
                          handleShowMenu(false);
                        }}
                      >
                        오시는 길
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="box_nav_header f_20">
              <div
                onClick={() => handleActiveMenu('SUPPORT')}
                className="flex align-center"
              >
                <p>지원사업</p>
                <img
                  src={
                    menuActive === 'SUPPORT'
                      ? images.keyboardArrowUp
                      : images.keyboardArrowDown
                  }
                  alt="down"
                />
              </div>

              {menuActive === 'SUPPORT' && (
                <div className="box-expand">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.supportLead);
                          handleShowMenu(false);
                        }}
                      >
                        데이터 경제 기반의 디지털 전환 생태계 조성
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.supportCollabo);
                          handleShowMenu(false);
                        }}
                      >
                        공공 민간 데이터 활용 활성화 지원
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.supportPractice);
                          handleShowMenu(false);
                        }}
                      >
                        실무 중심의 융합형 데이터 인재 양성
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.supportInnovation);
                          handleShowMenu(false);
                        }}
                      >
                        데이터 산업 혁신 성장 기반 강화
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="box_nav_header f_20">
              <div
                onClick={() => handleActiveMenu('LAB')}
                className="flex align-center"
              >
                <p>데이터 오픈랩</p>
                <img
                  src={
                    menuActive === 'LAB'
                      ? images.keyboardArrowUp
                      : images.keyboardArrowDown
                  }
                  alt="down"
                />
              </div>

              {menuActive === 'LAB' && (
                <div className="box-expand">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.introDataLab);
                          handleShowMenu(false);
                        }}
                      >
                        데이터 오픈랩 소개
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.bigdataList);
                          handleShowMenu(false);
                        }}
                      >
                        데이터 리스트
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.operation);
                          handleShowMenu(false);
                        }}
                      >
                        이용 및 운영안내
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.analysis);
                          handleShowMenu(false);
                        }}
                      >
                        분석환경 안내
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.application);
                          handleShowMenu(false);
                        }}
                      >
                        이용신청
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="box_nav_header f_20">
              <div
                onClick={() => handleActiveMenu('USE')}
                className="flex align-center"
              >
                <p>활용사례</p>
                <img
                  src={
                    menuActive === 'USE'
                      ? images.keyboardArrowUp
                      : images.keyboardArrowDown
                  }
                  alt="down"
                />
              </div>

              {menuActive === 'USE' && (
                <div className="box-expand">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.useCase);
                          handleShowMenu(false);
                        }}
                      >
                        활용사례
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.referenceList);
                          handleShowMenu(false);
                        }}
                      >
                        자료실
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="box_nav_header f_20">
              <div
                onClick={() => handleActiveMenu('NOTIFICATION')}
                className="flex align-center"
              >
                <p>알림마당</p>
                <img
                  src={
                    menuActive === 'NOTIFICATION'
                      ? images.keyboardArrowUp
                      : images.keyboardArrowDown
                  }
                  alt="down"
                />
              </div>

              {menuActive === 'NOTIFICATION' && (
                <div className="box-expand">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.noticeList);
                          handleShowMenu(false);
                        }}
                      >
                        공지사항
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.photoNewsList);
                          handleShowMenu(false);
                        }}
                      >
                        포토&뉴스
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.newsList);
                          handleShowMenu(false);
                        }}
                      >
                        뉴스레터
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.qnaList);
                          handleShowMenu(false);
                        }}
                      >
                        질의응답
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.businessList);
                          handleShowMenu(false);
                        }}
                      >
                        사업공고
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,react/jsx-no-script-url,no-script-url */}
                      <a
                        onClick={() => {
                          navigate(RouterPath.reportList);
                          handleShowMenu(false);
                        }}
                      >
                        보도자료
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobile;
