const SOCIAL_TYPE = {
  INSTA: {
    code: 'INSTA',
    desc: '인스타그램',
  },
  FACEBOOK: {
    code: 'FACEBOOK',
    desc: '페이스북',
  },
  YOUTUBE: {
    code: 'YOUTUBE',
    desc: '유튜브',
  },
};

export default SOCIAL_TYPE;
