import React from 'react';
import Filter from '../../components/Filter';
import { images } from '../../assets';
import { RouterPath } from '../../common/constants';

function DataLab() {
  return (
    <>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.introDataLab}
        // isSearch
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>데이터 오픈랩 소개</p>
            <div className="divider_title" />

            <div className="intro_txt_001">
              기업, 대학생, 연구원, 중앙기관, 지자체, 공공기관, 시민 등이 이용
              가능한 공공·민간데이터 연계 <br />
              (부산시 미개방 공공데이터 및 카드·통신 등 민간 데이터) 하여
              데이터를 쉽고 안전하게 분석·활용할 수 있는 환경제공
            </div>
          </div>

          <div>
            <div className="intro_data_box">
              <div style={{ width: '100%' }}>
                <div className="traffic_map">목적</div>
                <div className="box_item_001">
                  <img src={images.analytics} alt="analysis" />
                  <div>
                    기업, 대학생, 연구원, 중앙기관, 지자체, 공공기관, 시민 등이
                    이용 가능한
                    <br />
                    공공·민간데이터 연계 (부산시 미개방 공공데이터 및 카드·통신
                    등
                    <br />
                    민간데이터)하여 데이터를 쉽고 안전하게 분석·활용 할 수 있는
                    환경제공
                  </div>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className="traffic_map">제공서비스</div>
                <div className="box_item_002">
                  <div>
                    <img src={images.analysis} alt="analysis" />
                    <div>
                      데이터분석
                      <br />
                      환경제공
                    </div>
                  </div>
                  <div>
                    <img src={images.scientist} alt="scientist" />
                    <div>
                      데이터분석 활용지원 <br />
                      (빅데이터 분석전문가 상주)
                    </div>
                  </div>
                  <div>
                    <img src={images.startUp} alt="start-up" />
                    <div>
                      비즈니스 모델 개발지원
                      <br />
                      (기업지원 사업 연계)
                    </div>
                  </div>
                  <div>
                    <img src={images.storage} alt="storage" />
                    <div>
                      다양한 <br />
                      분석사례 공유
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="traffic_map">시설안내</div>
              <div className="box_operation">
                <div className="badge_operation_001">
                  <span>위치</span>
                  <div>
                    부산광역시 해운대구 센텀동로 45, CENTAP 5층 (데이터오픈랩)
                  </div>
                </div>
                <div className="badge_operation_001">
                  <span>문의</span>
                  <div>051-744-8496</div>
                </div>
                <div className="badge_operation_001">
                  <span>운영시간</span>
                  <div>
                    연간 상시 운영 - 평일 09:00 ~ 18:00 (토요일, 일요일, 공휴일
                    휴무) <br />
                    <p>※ 점심시간 이용 불가(11:30 ~ 13:00)</p>
                  </div>
                </div>
                <div className="badge_operation_001">
                  <span>수용인원</span>
                  <div>5개실 (최대 3인 사용가능, 분석용 PC 1대)</div>
                </div>
              </div>
            </div>
            <div>
              <div className="traffic_map">시설사진</div>
              <div className="box_operation">
                <div className="image_operation_001">
                  <img src={images.dataLab01} alt="storage" />
                  <img src={images.dataLab02} alt="storage" />
                  <img src={images.dataLab03} alt="storage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DataLab;
