import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common';

function Pledge() {
  const navigate = useNavigate();
  const [pledge, setPledge] = useState();
  const [affirmation, setAffirmation] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.application}
        // isSearch
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>이용 신청</p>
            <div className="divider_title" />
            <div className="intro_txt_001">
              보안서약서 및 보안확약서의 내용에 동의하셔야 이용신청 하실 수
              있습니다.
            </div>
          </div>

          <div className="box_pledge">
            <div className="traffic_map">보안서약서</div>

            <div className="pledge_txt_box">
              <div>
                본인은 부산 데이터활용·서비스개발 플랫폼을 이용함에 있어
                다음사항을 준수할 것을 엄숙히 서약합니다.
                <br />
                <br />
                1. 본인은 신청서에 기재된 목적으로만 부산 데이터활용·서비스개발
                플랫폼의 시스템을 활용한다.
                <br />
                2. 본인은 부산 데이터활용·서비스개발 플랫폼의 시스템 이용시 관련
                규정을 준수하고 이를 어김으로서 발생 하는 모든 법적, 재정적
                손해에 대해 배상한다.
                <br />
                3. 본인은 부산 데이터활용·서비스개발 플랫폼의 분석실 사용 시
                타인의 정보에 대한 불법적인 접근을 하지 않으며 이를 어기는 경우
                부산 데이터활용·서비스개발 플랫폼에서 즉시 퇴출되며 사용자의
                데이터를 즉시 파기하는데 동의한다.
                <br />
                4. 기타 부산 데이터활용·서비스개발 플랫폼의 규정 위반 시에는
                관련 법령 등에 따라 어떠한 처벌 및 불이 익도 감수한다.
              </div>
            </div>
            <div className="input_radio_check">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="check_01">보안서약서에 동의합니다. (필수)</label>
              <input
                id="check_01"
                type="checkbox"
                onChange={e => setPledge(e.target.checked)}
              />
            </div>

            <div className="traffic_map">보안확약서</div>

            <div className="pledge_txt_box">
              <div>
                본인은 부산 데이터활용·서비스개발 플랫폼을 이용함에 있어
                다음사항을 준수할 것을 엄숙히 서약합니다.
                <br />
                1. 본인은 부산 데이터활용·서비스개발 플랫폼의 이용과 관련된
                업무가 기밀(개인정보 포함) 사항임을 인정 하고 제반 보안관계 규정
                및 지침을 성실히 준수한다
                <br />
                2. 본인은 이 기밀(개인정보 포함)을 누설함이 이적 및 불법
                행위임을 명심하고 부산 데이터활용·서비스개 발 플랫폼의 이용 중은
                물론 이용 후에도 알게 된 모든 기밀사항을 일절 타인에게 누설하지
                아니한다.
                <br />
                3. 본인은 반드시 부산 데이터활용·서비스개발 플랫폼의 모든 시설을
                신청 목적 외 사용하지 않고, 관련 정보에 대해
                분실·도난·유출·변조·훼손되지 않도록 안전하게 관리한다
                <br />
                4. 본인이 이 기밀(개인정보 포함)을 누설하거나 관련 규정을 위반한
                때에는 관련 법령 및 계약에 따라 어떠한 처벌 및 불이익도
                감수한다.
              </div>
            </div>
            <div className="input_radio_check">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="check_02">보안확약서에 동의합니다. (필수)</label>
              <input
                id="check_02"
                type="checkbox"
                onChange={e => setAffirmation(e.target.checked)}
              />
            </div>

            <button
              type="button"
              onClick={() => {
                navigate(RouterPath.dataApply, { state: true });
              }}
              style={{ width: 300, marginTop: 80 }}
              className={
                pledge && affirmation
                  ? 'btn_login btn_center'
                  : 'btn_login_disabled btn_center'
              }
              disabled={!(pledge && affirmation)}
            >
              동의 후 신청하기
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pledge;
