import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import Pagination from '../../components/Pagination';
import { RouterPath } from '../../common/constants';
import { apiGetLibraryList } from '../../api/RestAPI';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

function ReferenceRoom() {
  const navigate = useNavigate();
  const location = useLocation();

  const pageSize = 10;
  const initPage = location.state ? location.state.currentPage : 1;

  const [totalPage, setTotalPage] = useState(0);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(initPage);
  const getReferenceList = currentPage => {
    const data = {
      page: currentPage,
      size: pageSize,
    };

    apiGetLibraryList(data)
      .then(response => {
        setList(response.data.data.list);
        setTotalCount(response.data.data.totalCnt);
        setTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const navigateList = currentPage => {
    navigate(RouterPath.referenceList, {
      state: { currentPage },
    });
  };

  const navigateDetail = boardIdx => {
    navigate(`${RouterPath.referenceDetail}/${boardIdx}`);
  };

  useEffect(() => {
    getReferenceList(initPage);
    setPage(initPage);
  }, [location]);

  return (
    <>
      <Filter
        title="Data Room"
        mainMenu="활용사례"
        subMenu={RouterPath.referenceList}
      />

      <section className="reference-detail">
        <div className="container">
          <div className="center_title">
            <p>자료실</p>
            <div className="divider_title" />
          </div>

          <table
            className="table_notice mobile-hide"
            style={{ marginTop: '49px', marginBottom: '66px' }}
          >
            <tr className="title_preview">
              <th className="c-1">번호</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th className="c-1" />
              <th className="c-auto">제목</th>
              <th className="c-1">등록일</th>
              <th className="c-1">조회수</th>
            </tr>
            {list?.map((item, index) => (
              <tr
                className="selector"
                key={`reference_item_${index}`}
                onClick={() => navigateDetail(item.boardIdx)}
              >
                <td>{item.rowNum}</td>
                <td>
                  {Utils.isWithinAWeek(item.regDate) && (
                    <button type="button" className="btn_table_blue">
                      new
                    </button>
                  )}
                </td>
                <td style={{ textAlign: 'left' }}>{item.libraryTitle}</td>
                <td>{Utils.formatDate(item.regDate)}</td>
                <td>{item.countView}</td>
              </tr>
            ))}
          </table>
          {list?.map((item, index) => (
            <div className="list_data_notice mobile-only">
              <div
                className="item_notice selector"
                onClick={() => navigateDetail(item.boardIdx)}
              >
                {Utils.isWithinAWeek(item.regDate) && (
                  <button type="button" className="btn_table_blue">
                    new
                  </button>
                )}

                <div
                  className="f_pre_400_18 text_overflow_ellipsis_2"
                  style={{ margin: '8px 0 16px 0' }}
                >
                  {item.libraryTitle}
                </div>
                <div className="flex" style={{ gap: 12 }}>
                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">등록일</span>
                    <span className="f_pre_400_18">
                      {Utils.formatDate(item.regDate)}
                    </span>
                  </div>
                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">조회수</span>
                    <span className="f_pre_400_18">{item.countView}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Pagination
            pageCount={totalPage}
            pageNum={page}
            setPage={setPage}
            onPageChangeCallback={navigateList}
          />
        </div>
      </section>
    </>
  );
}

export default ReferenceRoom;
