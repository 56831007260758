import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { useNavigate } from 'react-router-dom';
import ClockImage from '../assets/images/mingcute_time-line.svg';
import { RouterPath } from '../common/constants';
import Utils from '../utils/Utils';

function BannerSlider03({ photoNewsList, photoNewsImageList }) {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (photoNewsList && sliderRef?.current) {
      const swiper = new Swiper(sliderRef.current, {
        autoplay: false,
        slidesPerView: 4,
        spaceBetween: 30,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next03',
          prevEl: '.swiper-button-prev03',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
          },
          765: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          568: {
            slidesPerView: 1,
          },
          465: {
            slidesPerView: 1,
          },
          0: {
            slidesPerView: 1,
          },
        },
      });
      return () => {
        swiper.destroy();
      };
    }
  }, [photoNewsList]);

  return (
    <div id="swiper-news" className="slide_type03">
      <div ref={sliderRef} className="slider_item03">
        <div className="swiper-wrapper">
          {photoNewsList?.length > 0 &&
            photoNewsList?.map((item, index) => (
              <div key={{ index }} className="swiper-slide">
                <div
                  className="item pointer"
                  onClick={() =>
                    navigate(`${RouterPath.photoNewsDetail}/${item.boardIdx}`)
                  }
                >
                  <div className="itemSlide">
                    <img
                      className="newsImage"
                      src={photoNewsImageList[item.thumbFileUrl]}
                      alt="placeholder"
                    />
                    <div className="content">
                      <div className="bannerh1">
                        {Utils.shortenText(item.photoTitle, 26)}
                      </div>
                      <div className="clockTime">
                        <ClockImage />
                        {item.regDate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* Repeat the rest of your swiper-slide components */}
        </div>
      </div>
      {/* Add Arrows */}
      <div className="ar_w03">
        <div className="swiper-button-next swiper-button-next03" />
        <div className="swiper-button-prev swiper-button-prev03" />
      </div>
    </div>
  );
}

export default BannerSlider03;
