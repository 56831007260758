import React from 'react';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import { images } from '../../assets';

function Analysis() {
  return (
    <>
      <Filter
        title="Data OpenLab"
        mainMenu="데이터 오픈랩"
        subMenu={RouterPath.analysis}
        // isSearch
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>분석도구</p>
            <div className="divider_title" />
          </div>

          <div className="traffic_map">분석 도구</div>
          <br />
          <div className="operation_txt_001">
            라이브러리는 이용 신청 시 개인 반입 요청
          </div>
          <div className="intro_data_box">
            <div style={{ width: '100%' }}>
              <div className="box_analysis_001">
                <div className="box_analysis_image_container">
                  <img src={images.analysis1} alt="R studio" />
                </div>
                <div className="divider_analysis" />
                <div>
                  <p>R studio</p>
                  <div>
                    R 프로그래밍 언어 기반의 오픈소스 소프트웨어로 통계 분석에
                    보다 특화된 라이브러리를 활용한 데이터 분석 등에 유용
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div className="box_analysis_001">
                <div className="box_analysis_image_container">
                  <img src={images.analysis2} alt="Jupyter" />
                </div>
                <div className="divider_analysis" />
                <div>
                  <p>Jupyter</p>
                  <div>
                    Python 프로그래밍 언어 기반의 오픈소스 소프트웨어로서 각종
                    라이브러리를 활용한 데이터분석 및 머신러닝 등에 유용
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div className="box_analysis_001">
                <div className="box_analysis_image_container">
                  <img src={images.analysis3} alt="Tableau" />
                </div>
                <div className="divider_analysis" />
                <div>
                  <p>Tableau</p>
                  <div>
                    빠르게 정보를 탐색하여 즉각적인 통찰을 얻을 수 있으며,
                    전문적인 데이터 분석 기술 없이도 직접 고품질 데이터 시각화
                    활용 가능
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div className="box_analysis_001">
                <div className="box_analysis_image_container">
                  <img src={images.analysis4} alt="QGIS" />
                </div>
                <div className="divider_analysis" />
                <div>
                  <p>QGIS</p>
                  <div>
                    대표적인 데스크탑 지리정보체계(GIS) 분석가능한 오픈소스
                    소프트웨어로서 각종 플러그인을 활용한 데이터 뷰, 편집, 분석
                    등에 유용
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="traffic_map">기타 인프라 현황</div>

          <table className="table_intro_data">
            <tr>
              <th>구분</th>
              <th>명칭</th>
              <th>버전</th>
              <th>설명</th>
            </tr>

            {/* <tr> */}
            {/*  <th rowSpan={6}>서버</th> */}
            {/*  <td>CentOS 64bit</td> */}
            {/*  <td>7.4</td> */}
            {/*  <td>리눅스 배포판</td> */}
            {/* </tr> */}
            {/* <tr> */}
            {/*  <td>Maria DBMS</td> */}
            {/*  <td>15.1</td> */}
            {/*  <td>관계형 DBMS</td> */}
            {/* </tr> */}
            {/* <tr> */}
            {/*  <td>BaseX</td> */}
            {/*  <td>9.7.3</td> */}
            {/*  <td>대용량 XML DBMS</td> */}
            {/* </tr> */}
            {/* <tr> */}
            {/*  <td>ARCHYS</td> */}
            {/*  <td>5.4</td> */}
            {/*  <td>In-Memory DBMS</td> */}
            {/* </tr> */}
            {/* <tr> */}
            {/*  <td>Apache NiFi</td> */}
            {/*  <td>1.16.0</td> */}
            {/*  <td>데이터 연계 및 가공</td> */}
            {/* </tr> */}
            {/* <tr> */}
            {/*  <td>API AutoFlow</td> */}
            {/*  <td>10.3.5.1</td> */}
            {/*  <td>데이터 수집 및 연계</td> */}
            {/* </tr> */}
            <tr>
              <th rowSpan={12}>단말</th>
              <td>Dbeaver</td>
              <td>22.1</td>
              <td>DB접속 및 데이터 확인</td>
            </tr>
            <tr>
              <td>Putty</td>
              <td>0.76</td>
              <td>SSH 접속</td>
            </tr>
            <tr>
              <td>FileZilla</td>
              <td>3.59</td>
              <td>SFTP 접속</td>
            </tr>
            <tr>
              <td>MS Office(Word)</td>
              <td>2022</td>
              <td>워드</td>
            </tr>
            <tr>
              <td>MS Office(Excel)</td>
              <td>2022</td>
              <td>엑셀</td>
            </tr>
            <tr>
              <td>MS Office(Powerpoint)</td>
              <td>2022</td>
              <td>파워포인트</td>
            </tr>
            <tr>
              <td>R for Windows 64bit</td>
              <td>4.2.1</td>
              <td>분석 프로그램</td>
            </tr>
            <tr>
              <td>Rstudio</td>
              <td>2022.07.1+554</td>
              <td>분석 프로그램</td>
            </tr>
            <tr>
              <td>Python</td>
              <td>3.9</td>
              <td>Python</td>
            </tr>
            <tr>
              <td>Anaconda</td>
              <td>22.05</td>
              <td>Python 실행환경</td>
            </tr>
            <tr>
              <td>7Zip</td>
              <td>22.01</td>
              <td>압축</td>
            </tr>
            <tr>
              <td>gvim</td>
              <td>9</td>
              <td>텍스트 편집기</td>
            </tr>
          </table>
        </div>
      </section>
    </>
  );
}

export default Analysis;
