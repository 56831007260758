import { getDownloadFile } from '../api/RestAPI';

const ApiUtils = {
  fetchImage(fileUrl) {
    if (!fileUrl)
      return Promise.reject(new Error('서버에 파일이 존재하지않습니다.'));

    const parts = fileUrl.split('/files');
    const targetUrl = parts[1];
    const completeUrl = `/images${targetUrl}`;
    return completeUrl;

    // return getDownloadFile({ filePath: fileUrl })
    //   .then(response => {
    //     const blob = new Blob([response.data], {
    //       type: response.headers['content-type'],
    //     });
    //     return window.URL.createObjectURL(blob);
    //   })
    //   .catch(error => {});
  },
  fetchImageToList(fileUrl, setListCallBack) {
    if (!fileUrl) return;

    const parts = fileUrl.split('/files');
    const targetUrl = parts[1];
    const completeUrl = `/images${targetUrl}`;

    setListCallBack(prevState => ({
      ...prevState,
      [fileUrl]: completeUrl,
    }));

    // const params = {
    //   filePath: fileUrl,
    // };
    //
    // getDownloadFile(params)
    //   .then(response => {
    //     const blob = new Blob([response.data], {
    //       type: response.headers['content-type'],
    //     });
    //     const imageUrl = window.URL.createObjectURL(blob);
    //
    //     // 상태 업데이트
    //     setListCallBack(prevState => ({
    //       ...prevState,
    //       [fileUrl]: imageUrl,
    //     }));
    //   })
    //   .catch(error => {});
  },
};

export default ApiUtils;
