import React from 'react';

/* eslint-disable */
function TermOfUse() {
  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>이용약관</p>
              <p className="copy_search_txt_001">Terms of Use</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg_login"
        style={{ background: '#F8FBFF', paddingBottom: 100 }}
      >
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>이용약관</p>
            <div className="divider_title" />
          </div>

          <div className="box_policy">
            <div>이용약관</div>
            <div>
              특정 서비스의 이용조건과 절차 등에 관한 사항을 규정하고 이를
              명시한 문서입니다. <br />
              서비스 이용자에게 공시함으로써 효력이 발생하며, 영업상 중요 사유가
              있을 시 변경할 수 있습니다.
            </div>
          </div>

          <div className="traffic_map">제1조(목적)</div>
          <div className="policy_txt_02">
            1. 이 약관은 재단법인 부산테크노파크가 운영하는 부산 빅데이터
            혁신센터(이하 “센터”라고 합니다) 웹서비스의 이용조건 및 절차에 관한
            사항을 명시합니다.
            <br />
            2. 회원가입에 대한 내용을 충분히 숙지하신 후 가입하여 주시고, 이
            약관은 본 센터에서 운영하는 모든 웹 서비스를 이용하는 모든 회원에게
            적용되며 이를 읽고 이해하는 것은 귀하의 책임입니다.
          </div>

          <div className="traffic_map">제2조(약관의 공지 및 효력)</div>
          <div className="policy_txt_02">
            1. 이 약관의 내용은 웹서비스 화면에 게시하거나 기타의 방법으로
            회원에게 공지함으로써 효력을 발생합니다.
            <br />
            2. 센터는 이 약관을 변경할 수 있으며, 변경된 약관은 제1항과 같은
            방법으로 공지함으로써 효력을 발생합니다.
            <br />
            3. 회원가입이란 센터에서 제공하는 웹서비스에 가입하는 것으로
            웹서비스의 조건과 규정에 합의함을 센터의미합니다.
          </div>

          <div className="traffic_map">제3조 (개인정보의 처리 및 보유기간)</div>
          <div className="policy_txt_02">
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          </div>
          <div className="policy_txt_02">
            1. 회원 : 센터의 웹서비스 이용에 관한 계약을 체결한 자<br />
            2. 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이
            선정하고 센터가 승인하는 문자와 숫자의 조합
            <br />
            3. 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한
            문자와 숫자의 조합
            <br />
            4. 탈퇴 : 회원이 서비스 이용 이후 그 이용계약을 종료시키는
            의사표시센터
          </div>

          <div className="traffic_map">제4조(이용 계약의 성립)</div>
          <div className="policy_txt_02">
            1. 서비스 가입 신청 시 본 약관을 읽고 "동의함" 버튼을 누르면 이
            약관에 동의하는 것으로 간주됩니다.
            <br />
            2. 이용계약은 회원 정보 입력 후 완료를 선택하게 되면 성립합니다.
            <br />
            3. 회원에 가입하여 서비스를 이용하고자 하는 희망자는 센터에서
            요청하는 개인정보를 제공해야 합니다.
            <br />
            4. 이용계약은 회원 1인당 1개의 ID로 체결하는 것을 원칙으로 합니다.
          </div>

          <div className="traffic_map">제5조(이용신청의 불 승낙)</div>
          <div className="policy_txt_02">
            1. 센터는 다음에 해당하는 이용신청에 대하여 등록을 거부하거나 등록
            후에라도 회원에게 고지하지 않고 회원정보를 수정 또는 삭제할 수
            있습니다.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1) 만14세 미만인 경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2) 다른 사람의 명의를 사용하여 신청하였을
            경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3) 이용 신청 시 필요내용을 허위로 기재하여
            신청하였을 경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;4) 사회의 안녕질서 및 미풍양속을 저해할
            목적으로 신청하였을 경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;5) 신용정보의 이용과 보호에 관한 법률에 의거
            신용불량자로 등록되어 있는 경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;6) 기타 이용신청자의 귀책사유로 이용승낙이
            곤란한 경우
            <br />
            <br />
            2. 센터는 다음에 해당하는 이용신청에 대하여 승낙 제한 사유가 해소될
            때까지 승낙을 하지 않을 수 있습니다.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1) 센터 설비의 여유가 없는 경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2) 센터 기술상 지장이 있는 경우
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3) 기타 센터가 필요하다고 인정되는 경우
            <br />
            <br />
            3. 센터는 이용신청이 불 승낙 되거나 승낙을 제한하는 경우에는 이를
            이용신청자에게 전자우편 또는 문자메시지로 그 사실을 알려야 합니다.
          </div>

          <div className="traffic_map">제9조(개인정보의 보호)</div>
          <div className="policy_txt_02">
            센터는 회원의 개인정보를 수집 등 처리하는 경우 개인정보 보호법을
            준수하며, 센터의 개인정보처리방침이 적용됩니다.
          </div>

          <div className="traffic_map">제10조(서비스 이용)</div>
          <div className="policy_txt_02">
            서비스 이용은 센터의 업무상 또는 기술상 특별한 지장이 없는 한
            연중무휴, 1일 24시간을 원칙으로 합니다. 단, 정기점검 등 서비스
            개선을 위하여 센터가 필요하다고 인정되는 때에는 미리 공지한 후
            서비스가 일시 중지될 수 있습니다.
          </div>

          <div className="traffic_map">제11조(서비스 제공의 중지 등)</div>
          <div className="policy_txt_02">
            센터는 다음 항에 해당하는 경우 서비스의 제공을 일시중지 또는 중단할
            수 있습니다.
          </div>
          <div className="policy_txt_02">
            1. 설비의 보수 등을 위하여 부득이한 경우
            <br />
            2. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를
            중지하는 경우
            <br />
            3. 기타 센터가 서비스를 제공할 수 없는 사유가 발생한 경우
            <br />
            4. 본 조의 사유가 발생한 경우 센터는 웹사이트 초기화면의 연결링크
            또는 팝업창 등 잘 보이는 곳에 미리 공지함을 원칙으로 하며, 부득이한
            경우 사후 공지합니다.
          </div>

          <div className="traffic_map">제12조(서비스 사용 제한)</div>
          <div className="policy_txt_02">
            1. 회원은 서비스의 사용에 있어서 다음 각 호에 해당되지 않도록 하여야
            하며, 이에 해당하는 경우 서비스 사용을 제한할 수 있습니다.
          </div>
          <div className="policy_txt_02">
            &nbsp;&nbsp;&nbsp;&nbsp;1) 다른 회원의 아이디(ID)를 부정 사용하는
            행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2) 범죄행위를 목적으로 하거나 기타
            범죄행위와 관련된 행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3) 선량한 풍속, 기타 사회질서를 해하는 행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;4) 타인의 명예를 훼손하거나 모욕하는 행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;5) 타인의 지적재산권 등의 권리를 침해하는
            행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;6) 해킹행위 또는 컴퓨터바이러스의 유포행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;7) 타인의 의사에 반하여 광고성 정보 등
            일정한 내용을 지속적으로 전송하는 행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;8) 서비스의 안전적인 운영에 지장을 주거나 줄
            우려가 있는 일제의 행위
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;9) 기타 관계법령에 위배되는 행위
          </div>

          <div className="traffic_map">제13조(계약 해지)</div>
          <div className="policy_txt_02">
            1. 회원이 이용계약을 해지하고자 하는 때에는 본인이 서비스 또는
            전자우편을 통하여 해지하고자 하는 날의 1일전까지(단, 해지일이
            법정공휴일인 경우 공휴일 개시 2일전까지) 이를 센터에 신청하여야
            합니다.
            <br />
            2. 센터는 회원이 제4장 제1조의 내용을 위반하고, 센터 소정의 기간
            이내에 이를 해소하지 아니하는 경우 서비스 이용계약을 해지할 수
            있습니다.
            <br />
            3. 센터는 제2항에 의해 해지된 회원이 다시 이용신청을 하는 경우
            일정기간 그 승낙을 제한할 수 있습니다.
          </div>

          <div className="traffic_map">제14조(회원의 의무)</div>
          <div className="policy_txt_02">
            1. 회원아이디(ID) 및 비밀번호에 관한 모든 관리의 책임은 회원에게
            있습니다.
            <br />
            2. 회원아이디(ID) 및 비밀번호는 센터의 사전승낙 없이는 다른 사람에게
            양도, 임대, 대여할 수 없습니다.
            <br />
            3. 자신의 회원아이디(ID)가 부정하게 사용된 경우, 회원은 반드시
            센터에 그 사실을 통보해야 합니다.
            <br />
            4. 회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우 서비스를
            통하여 그 내용을 센터에 통지하여야 합니다.
            <br />
            5. 회원은 이 약관 및 관계법령에서 규정한 사항을 준수해야 합니다.
          </div>

          <div className="traffic_map">제15조(센터의 의무)</div>
          <div className="policy_txt_02">
            1. 센터는 제3장 제1조 및 제2조에서 정한 경우를 제외하고 이 약관에서
            정한 바에 따라 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수
            있도록 합니다.
            <br />
            2. 센터는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를
            제공할 의무가 있습니다.
            <br />
            3. 센터는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정되는
            경우에는 즉시 처리해야 합니다. 다만 즉시 처리가 곤란한 경우에는
            회원에게 그 사유와 처리일정을 통보하여야 합니다.
          </div>

          <div className="traffic_map">제16조(손해배상)</div>
          <div className="policy_txt_02">
            센터는 서비스 이용과 관련하여 센터의 귀책사유로 회원에게 손해가
            발생한 경우, 민법 등 관련법령에 따라 손해를 배상합니다.
          </div>

          <div className="traffic_map">제17조(면책)</div>
          <div className="policy_txt_02">
            1. 센터는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
            제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
            <br />
            2. 센터는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을
            지지 않습니다.
            <br />
            3. 센터는 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여
            얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
            <br />
            4. 센터는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성
            등 내용에 관하여는 책임을 지지 않습니다.
            <br />
            5. 회원 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여
            발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두
            회원에게 있습니다.
            <br />
            6. 회원이 제4장 제1조, 기타 이 약관의 규정을 위반함으로 인하여
            센터가 회원 또는 제3자에 대하여 책임을 부담하게 되고, 이로써
            센터에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 센터에게
            발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 센터를
            면책시켜야 합니다.
          </div>

          <div className="traffic_map">제18조(약관의 변동)</div>
          <div className="policy_txt_02">
            센터는 본 약관을 변경하는 경우 제2조에 따라 변경 및 공지합니다.
          </div>

          <div className="traffic_map">제19조(M분쟁의 해결)</div>
          <div className="policy_txt_02">
            1. 센터와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기
            위하여 필요한 모든 노력을 하여야 합니다.
            <br />
            2. 제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우
            민사소송법상의 관할법원을 전속적 관할 법원으로 합니다.
          </div>
        </div>
      </section>
    </>
  );
}

export default TermOfUse;
