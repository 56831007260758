import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiCallFindId,
  apiConfirmCertEmail,
  apiSendCertEmail,
} from '../api/RestAPI';
import { handleError } from '../utils/HandleError';

function Findid() {
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [myName, setMyName] = useState('');
  const [myEmail, setMyEmail] = useState('');
  const [certIdx, setCertIdx] = useState(null);
  const [certNo, setCertNo] = useState('');
  const [foundIdList, setFoundIdList] = useState(null);

  const sendUserCertEmail = () => {
    const data = {
      email: myEmail,
      userName: myName,
      certType: 'FIND_ID',
    };

    apiSendCertEmail(data)
      .then(response => {
        setCertIdx(response.data.data.certIdx);
        setShowConfirm(true);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const confirmUserCertEmail = () => {
    const data = {
      certIdx,
      certCode: certNo,
      certMethod: 'EMAIL',
      certType: 'FIND_ID',
      certTarget: myEmail,
    };

    apiConfirmCertEmail(data)
      .then(response => {
        callFindId();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const callFindId = () => {
    const data = {
      certIdx,
      certCode: certNo,
      userName: myName,
      email: myEmail,
    };

    apiCallFindId(data)
      .then(response => {
        setFoundIdList(response.data.data.userLoginIdList);
      })
      .catch(error => {
        handleError(error);
      });
  };

  // useEffect(() => {
  //   console.log('found id list ::::: ', foundIdList);
  // }, [foundIdList]);

  return (
    <div className="tap_find_id">
      {foundIdList && foundIdList.length > 0 ? (
        <>
          <div>당신의 ID는</div>
          <br />
          {foundIdList.map((item, index) => (
            <div key={`found_id_${index}`}>{item}</div>
          ))}
          <br />
          <div>입니다.</div>
        </>
      ) : (
        <>
          <input
            type="text"
            placeholder="이름을 입력해주세요"
            className="inp_id"
            value={myName}
            onChange={e => setMyName(e.target.value)}
          />
          <input
            type="email"
            placeholder="이메일(email)을 입력해주세요"
            className="inp_pw"
            value={myEmail}
            onChange={e => setMyEmail(e.target.value)}
          />

          <button
            type="button"
            disabled={showConfirm}
            onClick={() => sendUserCertEmail()}
            className={!showConfirm ? 'btn_login' : 'btn_login_disabled'}
          >
            인증번호 전송
          </button>

          {showConfirm && (
            <>
              <input
                style={{ marginTop: 20 }}
                type="email"
                placeholder="인증번호 입력"
                className="inp_pw"
                value={certNo}
                onChange={e => setCertNo(e.target.value)}
              />

              <button
                type="button"
                className="btn_confirm"
                onClick={() => confirmUserCertEmail()}
              >
                인증번호 확인
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Findid;
