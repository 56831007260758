import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import { apiGetUseCaseDetail, apiUseCaseViewCount } from '../../api/RestAPI';
import { CustomSwal } from '../../components';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

function CaseDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [detail, setDetail] = useState({});

  const { boardIdx } = useParams();

  useEffect(() => {
    getUseCaseDetail();
  }, []);

  const getUseCaseDetail = () => {
    apiGetUseCaseDetail(boardIdx)
      .then(response => {
        const useCaseData = response.data.data;
        setDetail(useCaseData);
        useCaseViewCount();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const useCaseViewCount = () => {
    apiUseCaseViewCount(boardIdx).catch(error => {
      CustomSwal.fire({
        title: '알림',
        text: error.message,
      });
    });
  };

  return (
    <div>
      <Filter
        title="Use cases"
        mainMenu="활용사례"
        subMenu={RouterPath.useCase}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>활용사례 상세보기</p>
            <div className="divider_title" />
          </div>

          <div className="detail_notice_content">
            <div className="section1">
              <div className="th1">
                <p className="content1">{detail.useCaseTitle}</p>
                <div className="content1_right">
                  <div className="item_right">
                    <div className="label">등록일</div>
                    <div className="value">
                      {Utils.formatDate(detail.regDate)}
                    </div>
                  </div>
                  <div className="item_right">
                    <div className="label">조회수</div>
                    <div className="value">{detail.countView}</div>
                  </div>
                </div>
              </div>
            </div>
            {detail.linkUrl && (
              <div className="detail_notice_box">
                <div className="notice_detail">
                  <span>링크</span>
                  <a
                    href={detail.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {detail.linkUrl}
                  </a>
                </div>
              </div>
            )}
            <div className="section2">
              <div
                className="content1"
                dangerouslySetInnerHTML={{
                  __html: Utils.shortenText(detail.useCaseBody),
                }}
              />
            </div>
            <div className="section3" />
          </div>

          <button
            type="button"
            className="btn_preview"
            onClick={() => navigate(-1)}
          >
            목록보기
          </button>
        </div>
      </section>
    </div>
  );
}

export default CaseDetail;
