import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from '../../common';

function TermCondition() {
  const navigate = useNavigate();
  const [termConfirm, setTermConfirm] = useState(false);
  const [individualConfirm, setIndividualConfirm] = useState(false);
  const [mature, setMature] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   console.log('termConfirm:::: ', termConfirm);
  // }, [termConfirm]);
  //
  // useEffect(() => {
  //   console.log('individualConfirm:::: ', individualConfirm);
  // }, [individualConfirm]);
  // useEffect(() => {
  //   console.log('mature:::: ', mature);
  // }, [mature]);

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg_login" style={{ background: '#F8FBFF' }}>
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>회원가입</p>
            <div className="divider_title" />
            <div className="company_txt_001">
              이용약관 및 개인정보 수집 및 이용에 동의하셔야 회원가입 하실 수
              있습니다.
            </div>
          </div>
          <div className="term_condition_box">
            <div className="traffic_map">이용약관</div>
            <div className="tern_content">
              <div>■ 통합 회원(혁신, 가명, 서비스) 이용약관</div>
              <br />
              <div>
                제1조(목적)
                <br />
                1. 이 약관은 재단법인 부산테크노파크가 운영하는 부산 빅데이터
                혁신센터(이하 “센터”라고 합니다) 웹서비스의 이용조건 및 절차에
                관한 사항을 명시합니다.
                <br />
                2. 회원가입에 대한 내용을 충분히 숙지하신 후 가입하여 주시고, 이
                약관은 본 센터에서 운영하는 모든 웹 서비스를 이용하는 모든
                회원에게 적용되며 이를 읽고 이해하는 것은 귀하의 책임입니다.
              </div>
              <br />
              <div>
                제2조(약관의 공지 및 효력)
                <br />
                1. 이 약관의 내용은 웹서비스 화면에 게시하거나 기타의 방법으로
                회원에게 공지함으로써 효력을 발생합니다.
                <br />
                2. 센터는 이 약관을 변경할 수 있으며, 변경된 약관은 제1항과 같은
                방법으로 공지함으로써 효력을 발생합니다.
                <br />
                3. 회원가입이란 센터에서 제공하는 웹서비스에 가입하는 것으로
                웹서비스의 조건과 규정에 합의함을 센터의미합니다.
              </div>
              <br />
              <div>
                제3조(용어의 정의) <br />이 약관에서 사용하는 용어의 정의는
                다음과 같습니다
              </div>
              <br />
              <div>
                1. 회원 : 센터의 웹서비스 이용에 관한 계약을 체결한 자 <br />
                2. 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이
                선정하고 센터가 승인하는 문자와 숫자의 조합 <br />
                3. 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한
                문자와 숫자의 조합 <br />
                4. 탈퇴 : 회원이 서비스 이용 이후 그 이용계약을 종료시키는
                의사표시 센터
              </div>
            </div>
            <div className="input_radio_check">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="check_01">이용약관에 동의합니다. (필수)</label>
              <input
                id="check_01"
                type="checkbox"
                // checked={termConfirm}
                onChange={e => setTermConfirm(e.target.checked)}
              />
            </div>

            <div className="traffic_map">개인정보 수집 및 이용동의</div>

            <table className="table_tern_conditions">
              <tr>
                <th>명칭</th>
                <th>항목</th>
                <th>보유기간</th>
              </tr>
              <tbody>
                <tr>
                  <td>이용자 식별 및 본인여부 확인</td>
                  <td>아이디, 이름, 비밀번호</td>
                  <td>회원 탈퇴 시까지</td>
                </tr>
                <tr>
                  <td>
                    고객서비스 이용에 관한 통지, CS대응을 위한 이용자 식별
                  </td>
                  <td>연락처(이메일,휴대전화번호)</td>
                  <td>회원 탈퇴 시까지</td>
                </tr>
              </tbody>
            </table>

            <div className="input_radio_check">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="check_02">
                개인정보 수집 및 이용에 동의합니다. (필수)
              </label>
              <input
                id="check_02"
                type="checkbox"
                onChange={e => setIndividualConfirm(e.target.checked)}
              />
            </div>

            <div className="divider_intro" />

            <div className="input_radio_check">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="check_03" htmlFor="check_03">
                만 14세 이상입니다.
              </label>
              <input
                id="check_03"
                type="checkbox"
                onChange={e => setMature(e.target.checked)}
              />
            </div>

            <div style={{ marginTop: 16 }}>
              ※ 귀하는 위 개인정보의 수집 이용 동의를 거절할 수 있습니다. 단,
              거절하는 경우 회원가입이 불가능합니다.
            </div>

            <button
              type="button"
              className={
                mature && termConfirm && individualConfirm
                  ? 'btn_tern'
                  : 'btn_tern_disabled'
              }
              onClick={() => navigate(RouterPath.register, { state: true })}
              disabled={!(mature && termConfirm && individualConfirm)}
            >
              동의 후 회원가입
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermCondition;
