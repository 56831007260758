import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import {
  apiGetPhotoNewsDetail,
  apiPhotoViewCount,
  getDownloadFile,
} from '../../api/RestAPI';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

function Detail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { boardIdx } = useParams();

  const [detail, setDetail] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const getPhotoNewsDetail = idx => {
    apiGetPhotoNewsDetail(idx)
      .then(response => {
        const responseData = response.data.data;
        setDetail(responseData);
        photoViewCount(boardIdx);
        responseData.bodyFiles.forEach(item => {
          const imageUrl = `${item.uploadedFilePath}${item.storageFileName}`;
          fetchImage(imageUrl);
        });
      })
      .catch(error => {
        handleError(error);
      });
  };

  const photoViewCount = photoIdx => {
    apiPhotoViewCount(photoIdx)
      .then(response => {})
      .catch(error => {
        handleError(error);
      });
  };

  useEffect(() => {
    getPhotoNewsDetail(boardIdx);
  }, []);

  // eslint-disable-next-line consistent-return
  const fetchImage = fileUrl => {
    if (!fileUrl) {
      return Promise.resolve('');
    }

    const parts = fileUrl.split('/files');
    const targetUrl = parts[1];
    const completeUrl = `/images${targetUrl}`;

    setImageUrls(prevState => [...prevState, completeUrl]);

    // const params = {
    //   filePath: fileUrl,
    // };
    //
    // return getDownloadFile(params)
    //   .then(response => {
    //     const imageUrl = window.URL.createObjectURL(
    //       new Blob([response.data], {
    //         type: response.headers['content-type'],
    //       }),
    //     );
    //     setImageUrls(prevState => [...prevState, imageUrl]);
    //     return imageUrl;
    //   })
    //   .catch(error => {
    //     return null;
    //   });
  };

  return (
    <div>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.photoNewsList}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>포토&뉴스 상세보기</p>
            <div className="divider_title" />
          </div>

          <div className="detail_notice_content">
            <div className="section1">
              <div className="th1">
                <p className="content1">{detail.photoTitle}</p>
                <div className="content1_right">
                  <div className="item_right">
                    <div className="label">등록일</div>
                    <div className="value">
                      {Utils.formatDate(detail.regDate)}
                    </div>
                  </div>
                  <div className="item_right">
                    <div className="label">조회수</div>
                    <div className="value">{detail.countView}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section2">
              {imageUrls.length > 0 &&
                imageUrls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt="본문이미지"
                    style={{ width: '60%', height: 'auto' }}
                  />
                ))}

              <div className="content1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail.photoBody,
                  }}
                />
              </div>
            </div>
            <div className="section3" />
          </div>

          <button
            type="button"
            className="btn_preview"
            onClick={() => navigate(-1)}
          >
            목록보기
          </button>
        </div>
      </section>
    </div>
  );
}

export default Detail;
