import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import Pagination from '../../components/Pagination';
import { RouterPath } from '../../common/constants';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';
import { apiGetQnaList } from '../../api/RestAPI';
import { CustomSwal } from '../../components';
import { images } from '../../assets';

function QAList() {
  const navigate = useNavigate();
  const location = useLocation();
  const storageToken = JSON.parse(localStorage.getItem('auth'));

  const pageSize = 10;
  const initPage = location.state ? location.state.currentPage : 1;

  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(initPage);

  const getQnaList = currentPage => {
    const data = {
      page: currentPage,
      size: pageSize,

      qnaStatus: null,
      token: storageToken?.accessToken,
    };

    apiGetQnaList(data)
      .then(response => {
        setList(response.data.data.list);
        setTotalCount(response.data.data.totalCnt);
        setTotalPage(response.data.data.totalPages);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const navigateList = currentPage => {
    navigate(RouterPath.qnaList, {
      state: { currentPage },
    });
  };

  const navigateWrite = () => {
    // navigate(RouterPath.qnaWrite);
    if (
      storageToken &&
      storageToken.accessToken &&
      storageToken.grade === 'USER'
    )
      navigate(RouterPath.qnaWrite);
    else
      CustomSwal.fire({
        title: '안내',
        html: `1:1 질문은 로그인 후에 등록하실 수 있습니다.`,
        then: () => {
          // navigate(RouterPath.login);
        },
      });
  };

  useEffect(() => {
    getQnaList(initPage);
    setPage(initPage);
  }, [location]);

  return (
    <>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.qnaList}
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>1:1질의 응답</p>
            <div className="divider_title" />
          </div>

          <table className="table_notice mobile-hide">
            <thead>
              <tr className="title_preview">
                <th className="c-1">번호</th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="c-1" />
                <th className="c-auto">제목</th>
                <th className="c-2">등록일</th>
                <th className="c-1">조회수</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => (
                <tr
                  key={`qna_item_${index}`}
                  className={`${item.accessYn === 'Y' ? 'selector' : ''}`}
                  onClick={() =>
                    item.accessYn === 'Y' &&
                    navigate(RouterPath.qnaDetail, {
                      state: item.boardIdx,
                    })
                  }
                >
                  <td>{item.rowNum}</td>
                  <td>
                    {item.accessYn === 'N' && (
                      <img src={images.lockIcon} alt="비공개여부" />
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    <button
                      type="button"
                      className={
                        (item.qnaStatus === 'ANSWERED' && 'btn_table_gray') ||
                        (item.qnaStatus === 'PENDING' && 'btn_table_blue')
                      }
                    >
                      {(item.qnaStatus === 'ANSWERED' && '답변완료') ||
                        (item.qnaStatus === 'PENDING' && '답변대기')}
                    </button>
                    <span>{item.qnaTitle}</span>
                  </td>
                  <td>{Utils.formatDate(item.regDate)}</td>
                  <td>{item.countView}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="box_btn">
            <button
              type="button"
              className="btn_notice_red"
              onClick={() => navigateWrite()}
            >
              글쓰기
            </button>
          </div>

          {list?.map((item, index) => (
            <div className="list_data_notice mobile-only">
              <div
                className={`${
                  item.accessYn === 'Y' ? 'item_notice selector' : 'item_notice'
                }`}
                onClick={() =>
                  item.accessYn === 'Y' &&
                  navigate(RouterPath.qnaDetail, {
                    state: item.boardIdx,
                  })
                }
              >
                {item.accessYn === 'N' && (
                  <img src={images.lockIcon} alt="비공개여부" />
                )}
                {Utils.isWithinAWeek(item.regDate) && (
                  <button type="button" className="btn_table_blue">
                    new
                  </button>
                )}
                <div
                  className="f_pre_400_18 text_overflow_ellipsis_2"
                  style={{ margin: '8px 0 16px 0' }}
                >
                  {item.qnaTitle}
                </div>
                <div className="flex" style={{ gap: 12 }}>
                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">등록일</span>
                    <span className="f_pre_400_18">{item.regDate}</span>
                  </div>

                  <div className="flex align-center" style={{ gap: 6 }}>
                    <span className="f_pre_500_18">조회수</span>
                    <span className="f_pre_400_18">{item.countView}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <Pagination
            pageCount={totalPage}
            pageNum={page}
            setPage={setPage}
            onPageChangeCallback={navigateList}
          />
        </div>
      </section>
    </>
  );
}

export default QAList;
