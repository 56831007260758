import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import images from '../../assets/images';

function PopupModal({
  popupData,
  position,
  onClose,
  onDisable,
  activePopupIndex,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const handleDisablePopup = e => {
    const { checked } = e.target;
    setIsChecked(checked);
    if (checked) {
      onDisable(activePopupIndex);
    }
  };

  useEffect(() => {
    setIsChecked(false);
  }, [popupData]);

  if (!popupData) return null;

  const { pcWidth, pcHeight, mobWidth, mobHeight, imagePath, actionLink } =
    popupData;

  if (!position) return null;

  const positionStyle = {
    top: `${position.y}%`,
    left: `${position.x}px`,
  };

  return (
    <Container
      style={{
        width: window.innerWidth < 768 ? mobWidth || '90%' : pcWidth || '50%',
        height:
          window.innerWidth < 768 ? mobHeight || '80%' : pcHeight || '60%',
        ...positionStyle,
      }}
    >
      <Content>
        {imagePath && (
          <Image
            className="pointer"
            src={imagePath}
            alt="Popup"
            style={{
              width: '100%',
              height: '90%',
              objectFit: 'fill',
            }}
            onClick={() => window.open(actionLink, '_blank')}
          />
        )}
        <Footer>
          <CheckboxContainer>
            <input
              type="checkbox"
              id={`disablePopup-${activePopupIndex}`}
              checked={isChecked}
              onChange={handleDisablePopup}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`disablePopup-${activePopupIndex}`}>
              오늘 하루 보지 않기
            </label>
          </CheckboxContainer>
          <CloseButton onClick={onClose}>
            <img style={{ width: '25px' }} src={images.iconClose} alt="Close" />
          </CloseButton>
        </Footer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 10px;
`;

const Content = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 90%;
  object-fit: fill;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 10%;
  color: #777; // 텍스트 색상 변경
  font-size: 0.9em; // 글자 크기 조정
`;

const Label = styled.label`
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777; // 레이블 색상 변경
  font-size: 0.9em; // 글자 크기 조정
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const CloseButton = styled.div`
  cursor: pointer;
  z-index: 1010;
`;

export default PopupModal;
