/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { ThreeDots } from 'react-loader-spinner';

export default function Loading() {
  return (
    <div css={loading}>
      <div className="background">
        <ThreeDots
          visible="true"
          height="80"
          width="80"
          color="#F5D0A9"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  );
}

const loading = css`
  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    // background: #000;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
