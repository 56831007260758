import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../assets';
import { RouterPath } from '../common/constants';

function Footer() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer id="foot" className="">
      <div className="container">
        <div className="footTop">
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(RouterPath.privacyPolicy)}
          >
            개인정보처리방침
          </span>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(RouterPath.termUse)}
          >
            이용약관
          </span>
        </div>
        <div className="divider" />
        <div className="footwg">
          <div className="footw01">
            <div className="logo">
              <img src={images.logoMainFoot} alt="" />
            </div>
            <div className="copyright flex-column" style={{ rowGap: 0 }}>
              <div className="flex" style={{ columnGap: 14 }}>
                <span style={{ width: 65 }}>TEL</span>
                <span>
                  {' '}
                  051-744-8496(데이터오픈랩 신청 및 방문) <br />{' '}
                  051-747-6352(부산 빅데이터 혁신센터){' '}
                </span>
              </div>

              <div className="flex" style={{ columnGap: 14 }}>
                <span style={{ width: 65 }}>FAX</span>
                <span> 051-747-6833 </span>
              </div>
              <div className="flex foot_address" style={{ columnGap: 8 }}>
                <span style={{ minWidth: 65 }}>Address </span>
                <span>
                  부산광역시 해운대구 센텀동로 45, CENTAP 5층(데이터오픈랩),
                  7층(부산 빅데이터혁신센터)
                </span>
              </div>

              <div className="copy_txt_foot">
                COPYRIGHTⓒ부산 빅데이터 혁신센터. 2024 ALL RIGHTS RESERVED.
              </div>
            </div>
            <div className="sns_logo">
              <li>
                <a
                  href="https://www.instagram.com/bigdata_in_busan/"
                  target="_blank"
                  title="새창열림"
                  className="insta"
                  rel="noreferrer"
                >
                  <span class="blind">
                    {' '}
                    <img src={images.snsInsta} alt="SNS" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/people/Bigdata-Busan/100090523700534/"
                  target="_blank"
                  title="새창열림"
                  className="facebook"
                  rel="noreferrer"
                >
                  <span class="blind">
                    {' '}
                    <img src={images.snsFb} alt="SNS" />
                  </span>
                </a>
              </li>
              {/* <li> */}
              {/*  <a */}
              {/*    href="https://pf.kakao.com/_WxjBgb" */}
              {/*    target="_blank" */}
              {/*    title="새창열림" */}
              {/*    className="twitter" */}
              {/*    rel="noreferrer" */}
              {/*  > */}
              {/*    <span class="blind"> */}
              {/*      {' '} */}
              {/*      <img src={images.snsCh} alt="SNS" /> */}
              {/*    </span> */}
              {/*  </a> */}
              {/* </li> */}
              <li>
                <a
                  href="https://www.youtube.com/@busanbigdata"
                  target="_blank"
                  title="새창열림"
                  className="youtube"
                  rel="noreferrer"
                >
                  <span class="blind">
                    {' '}
                    <img src={images.snsYtb} alt="SNS" />
                  </span>
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
      {/* --퀵메뉴 버튼, 탑 스크롤 버튼-----*/}
      <div id="quick" className={isVisible ? 'on' : ''}>
        <ul>
          {/* <li className="onlineBtn"> */}
          {/*  <a */}
          {/*    href="https://present.do/documents/64a62e81bdaf2923f6ba559c" */}
          {/*    target="_blank" */}
          {/*    rel="noreferrer" */}
          {/*  > */}
          {/*    <img src="../assets/images/ico_pdf.png" alt="백서" /> */}
          {/*    <span> */}
          {/*      회사소개서 */}
          {/*      <span /> */}
          {/*    </span> */}
          {/*  </a> */}
          {/* </li> */}
          <li />
          <li
            className="top_btn"
            onClick={scrollToTop}
            style={{ display: isVisible ? 'block' : 'none' }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" className="top_btn">
              <img src={images.topButton} alt="topBtn" />
            </a>
          </li>
        </ul>
      </div>
      {/* --퀵메뉴 버튼, 탑 스크롤 버튼 끝-----*/}
      {/* --//퀵메뉴 버튼, 탑 스크롤 버튼 끝-----*/}
    </footer>
  );
}

export default Footer;
