import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import DataUseApply from './DataUseApply';
import Pledge from './Pledge';
import { RouterPath } from '../../common';

function DataApply() {
  const navigate = useNavigate();
  const location = useLocation();
  const certification = location.state;
  const storageToken = JSON.parse(localStorage.getItem('auth'));

  useEffect(() => {
    // console.log('storage token :::: ', storageToken);
    if (
      !(
        storageToken &&
        storageToken.accessToken &&
        storageToken.grade === 'USER'
      )
    )
      navigate(RouterPath.slash, { replace: true });
  }, [storageToken]);

  return (
    <>
      {storageToken && !certification && <Pledge />}
      {storageToken && certification && <DataUseApply />}
    </>
  );
}

export default DataApply;
