// import authReducer from './authSlice';
import infoReducer from './infoSlice';
import modalReducer from './modalSlice';
import languageReducer from './languageSlice';
import loadingAction from './lodingSlice';

// const auth = authReducer;
const info = infoReducer;
const modal = modalReducer;
const language = languageReducer;
const loading = loadingAction;

export default {
  // auth,
  info,
  modal,
  language,
  loading,
};
