import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiConfirmCertEmail,
  apiResetPassword,
  apiSendCertEmail,
} from '../api/RestAPI';
import { handleError } from '../utils/HandleError';
import CustomSwal from './CustomSwal';

function FindPw() {
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [myName, setMyName] = useState('');
  const [myLoginId, setMyLoginId] = useState('');
  const [myEmail, setMyEmail] = useState('');
  const [certIdx, setCertIdx] = useState(null);
  const [certNo, setCertNo] = useState('');
  const [certificated, setCertificated] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConf, setNewPasswordConf] = useState('');

  const sendUserCertEmail = () => {
    const data = {
      email: myEmail,
      userName: myName,
      certType: 'MODIFY_PW',
    };

    apiSendCertEmail(data)
      .then(response => {
        setCertIdx(response.data.data.certIdx);
        setShowConfirm(true);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const confirmUserCertEmail = () => {
    const data = {
      certIdx,
      certCode: certNo,
      certMethod: 'EMAIL',
      certType: 'MODIFY_PW',
      certTarget: myEmail,
    };

    apiConfirmCertEmail(data)
      .then(response => {
        setCertificated(true);
      })
      .catch(error => {
        handleError(error);
      });
  };

  const resetPw = () => {
    if (newPassword !== newPasswordConf) {
      CustomSwal.fire({
        title: '확인',
        text: '비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      });
      return;
    }
    const data = {
      certIdx,
      certCode: certNo,
      userLoginId: myLoginId,
      newPw: newPasswordConf,
      email: myEmail,
    };

    apiResetPassword(data)
      .then(response => {
        CustomSwal.fire({
          title: '비밀번호 수정',
          text: '비밀번호가 수정되었습니다.',
        });
        navigate(-1);
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <div className="tap_find_id">
      {certificated ? (
        <>
          <input
            type="password"
            placeholder="새 비밀번호를 입력해주세요"
            className="inp_id"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
          <input
            style={{ marginBottom: 10 }}
            type="password"
            placeholder="새 비밀번호를 다시 입력해주세요"
            className="inp_pw"
            value={newPasswordConf}
            onChange={e => setNewPasswordConf(e.target.value)}
          />

          <button type="button" onClick={() => resetPw()} className="btn_login">
            비밀번호 변경
          </button>
        </>
      ) : (
        <>
          <input
            type="text"
            placeholder="이름을 입력해주세요"
            className="inp_id"
            value={myName}
            onChange={e => setMyName(e.target.value)}
          />
          <input
            style={{ marginBottom: 10 }}
            type="text"
            placeholder="아이디를 입력해주세요"
            className="inp_pw"
            value={myLoginId}
            onChange={e => setMyLoginId(e.target.value)}
          />
          <input
            style={{ marginTop: 0 }}
            type="email"
            placeholder="인증 이메일(email)을 입력해주세요"
            className="inp_pw"
            value={myEmail}
            onChange={e => setMyEmail(e.target.value)}
          />

          <button
            type="button"
            disabled={showConfirm}
            onClick={() => sendUserCertEmail()}
            className={!showConfirm ? 'btn_login' : 'btn_login_disabled'}
          >
            인증번호 전송
          </button>
          {showConfirm && (
            <>
              <input
                style={{ marginTop: 20 }}
                type="email"
                placeholder="인증번호 입력"
                className="inp_pw"
                value={certNo}
                onChange={e => setCertNo(e.target.value)}
              />

              <button
                type="button"
                className="btn_confirm"
                onClick={() => confirmUserCertEmail()}
              >
                인증번호 확인
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FindPw;
