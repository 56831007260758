import React, { useEffect, useState } from 'react';
import Filter from '../components/Filter';
import { RouterPath } from '../common/constants';
import { CustomSwal } from '../components';
import {
  apiGetChartDetail,
  apiGetChartList,
  getDownloadFile,
} from '../api/RestAPI';
import { handleError } from '../utils/HandleError';

function ChartGuide() {
  const [chartImage, setChartImage] = useState('');

  useEffect(() => {
    getChartList();
  }, []);

  const getChartList = () => {
    const data = {
      page: 1,
      size: 1,
    };

    apiGetChartList(data)
      .then(async response => {
        const chartist = response.data.data.list;
        // console.log(chartist);

        if (chartist && chartist[0]) {
          const chart = await getChartDetail(chartist[0].chartIdx);
          const file = chart.chartFile;
          const url = file.uploadedFilePath;
          const parts = url.split('/');

          const targetUrl = `${parts[3]}`;

          // fetchImage(`${file.uploadedFilePath}${file.storageFileName}`);
          setChartImage(`/images/${targetUrl}/${file.storageFileName}`);
        }
      })
      .catch(error => {
        CustomSwal.fire({
          title: '알림',
          text: '등록된 조직도가 없습니다.',
        });
      });
  };

  const getChartDetail = async idx => {
    try {
      const response = await apiGetChartDetail(idx);
      return response.data.data;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  const fetchImage = fileUrl => {
    if (!fileUrl) {
      return;
    }
    const params = {
      filePath: fileUrl,
    };

    getDownloadFile(params)
      .then(response => {
        const imageUrl = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
        );
        setChartImage(imageUrl);
      })
      .catch(error => {
        CustomSwal.fire({
          title: '알림',
          text: '이미지 로드 중 오류가 발생했습니다.',
        });
      });
  };

  return (
    <div>
      <Filter
        title="Busan Big Data Innovation Center"
        mainMenu="센터소개"
        subMenu={RouterPath.chartGuide}
      />
      <section className="center_info">
        <div className="container">
          <div className="center_title">
            <p>조직도</p>
            <div className="divider_title" />
          </div>
          <img
            src={chartImage}
            alt="Chart"
            style={{
              display: 'block',
              background: `url(${chartImage}) no-repeat center`,
              backgroundSize: 'contain',
              width: '100%',
              height: '100%',
              margin: '4.563rem 0 443px 0',
            }}
          />
        </div>

        {/* <div className="analysis_bg" /> */}
      </section>
    </div>
  );
}

export default ChartGuide;
