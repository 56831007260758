import React, { useState } from 'react';
import Filter from '../components/Filter';
import { RouterPath } from '../common/constants';
import SwiperDataCenter from '../components/SwiperDataCenter';
import SwiperDataCenter02 from '../components/SwiperDataCenter02';
import { images } from '../assets';

function FacilityGuide() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabTable, setActiveTabTable] = useState(0);
  const activeTabHandle = item => {
    setActiveTabTable(item);
  };

  return (
    <div>
      <Filter
        title="Busan Big Data Innovation Center"
        mainMenu="센터소개"
        subMenu={RouterPath.facilityGuide}
      />
      <section className="center_info">
        <div className="container">
          <div className="center_title">
            <p>인프라안내</p>
            <div className="divider_title" />
            <div className="center_title_002">
              부산 빅데이터 혁신센터 시설을 소개 합니다.
            </div>
          </div>
        </div>

        <div className="box_data_001">
          <div className="container">
            <div className="f_title_30">부산 빅데이터 혁신센터</div>

            <div
              // className="detail_support_box"
              style={{
                background: '#FFFFFF',
                margin: '16px 0 32px 0',
                padding: '16px',
              }}
            >
              <div className="badge_support_detail">
                <span>위치</span>
                <div>부산광역시 해운대구 센텀동로 45, CENTAP 7층</div>
              </div>
              <div className="badge_support_detail">
                <span>이용가능</span>
                <div>
                  협업공간(메인홀), 오픈키친, 미팅룸2개(화상회의실1), 코워킹룸
                  2개, 부트캠프 운영공간(모니터 20대)
                </div>
              </div>
              <div className="badge_support_detail">
                <span>기능</span>
                <div>
                  DX 챌린지 프로그램(DX캠프, DX포럼, 빅데이터분석해커톤 등)
                  참여자의 활동 공간
                </div>
              </div>
            </div>

            <div className="box_data_002">
              <div className="flex-column-mobileRow">
                <nav className="data-content-left">
                  <ul
                    className="tabMenuTable-vertical"
                    // style={{ flexDirection: 'column' }}
                  >
                    <li
                      onClick={() => activeTabHandle(0)}
                      className={activeTabTable === 0 ? 'activeTap' : ''}
                    >
                      공간구성
                    </li>
                    <li
                      onClick={() => activeTabHandle(1)}
                      className={activeTabTable === 1 ? 'activeTap' : ''}
                    >
                      협업공간
                    </li>
                    <li
                      onClick={() => activeTabHandle(2)}
                      className={activeTabTable === 2 ? 'activeTap' : ''}
                    >
                      미팅룸
                    </li>
                    <li
                      onClick={() => activeTabHandle(3)}
                      className={activeTabTable === 3 ? 'activeTap' : ''}
                    >
                      코워킹룸
                    </li>
                    <li
                      onClick={() => activeTabHandle(4)}
                      className={activeTabTable === 4 ? 'activeTap' : ''}
                    >
                      부트캠프 운영공간
                    </li>
                  </ul>
                </nav>

                <div className="data-content-right">
                  <div className="f_28">
                    {activeTabTable === 0
                      ? '공간구성'
                      : activeTabTable === 1
                      ? '협업공간'
                      : activeTabTable === 2
                      ? '미팅룸'
                      : activeTabTable === 3
                      ? '코워킹룸'
                      : activeTabTable === 4
                      ? '부트캠프 운영공간'
                      : ''}
                  </div>

                  <div className="slide_center">
                    <SwiperDataCenter selectedTab={activeTabTable} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box_data_003">
          <div className="container">
            <div className="f_title_30">데이터 오픈랩(DATA OPENLAB)</div>
            <div
              className="detail_support_box"
              style={{ background: '#FFFFFF', margin: '16px 0 32px 0' }}
            >
              <div className="badge_support_detail">
                <span>위치</span>
                <div>부산광역시 해운대구 센텀동로 45, CENTAP 7층</div>
              </div>
              <div className="badge_support_detail">
                <span>이용가능</span>
                <div>5개실 (최대 3인 사용가능, 분석용 PC 1대)</div>
              </div>
              <div className="badge_support_detail">
                <span>기능</span>
                <div>
                  부산시 미개방 공공데이터 및 카드·통신 등 민간 데이터 분석 공간
                </div>
              </div>
            </div>

            <div
              className="slide_center"
              style={{
                border: '1px solid #CCCCCC',
                borderRadius: 30,
                padding: 40,
              }}
            >
              <SwiperDataCenter02 />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FacilityGuide;
