import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import { apiGetSupportList, getDownloadFile } from '../../api/RestAPI';
import SUPPORT_TYPE from '../../common/constants/SupportType';
import { Utils } from '../../common';
import { handleError } from '../../utils/HandleError';

function SupportItem({ support, imageUrls }) {
  const navigate = useNavigate();

  const navigateDetail = boardIdx => {
    navigate(`${RouterPath.supportDetail}/${boardIdx}`);
  };

  return (
    <div
      className="company_box_content selector"
      onClick={() => navigateDetail(support.boardIdx)}
    >
      <img
        className="supportImage"
        src={imageUrls[support.boardIdx]}
        alt="company-data"
      />
      <div>
        <div className="company_txt_002">{support.supportTitle}</div>
        <div
          className="company_txt_003"
          dangerouslySetInnerHTML={{
            __html: Utils.shortenText(
              Utils.stripHtmlTags(support.supportBody),
              155,
            ),
          }}
        />

        <div className="content_company_foot">
          {support.purpose && (
            <div className="badge_company_001">
              <span>지원목적</span>
              <div>{Utils.shortenText(support.purpose, 200)}</div>
            </div>
          )}

          {support.target && (
            <div className="badge_company_001">
              <span>참가대상</span>
              <div>{Utils.shortenText(support.target, 200)}</div>
            </div>
          )}

          {support.period && (
            <div className="badge_company_001">
              <span>사업일정</span>
              <div>{support.period}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SupportLead() {
  const [page, setPage] = useState(1);
  const [supportList, setSupportList] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const loadMoreData = () => {
      if (!loading && hasMore) {
        const { scrollTop } = document.documentElement;
        const { scrollHeight } = document.documentElement;
        const { clientHeight } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - clientHeight / 8) {
          setPage(prevPage => prevPage + 1);
        }
      }
    };

    window.addEventListener('scroll', loadMoreData);
    return () => window.removeEventListener('scroll', loadMoreData);
  }, [loading, hasMore]);

  useEffect(() => {
    setLoading(true);
    apiGetSupportList({
      size: 4,
      page,
      supportType: SUPPORT_TYPE.LEAD.code,
    })
      .then(response => {
        const data = response.data.data.list;
        setSupportList(prevList => [...prevList, ...data]);
        setLoading(false);
        setHasMore(data.length > 0);
      })
      .catch(error => {
        handleError(error);
      });
  }, [page]);

  // eslint-disable-next-line consistent-return
  const fetchImage = (fileUrl, supportId) => {
    if (!fileUrl) {
      return Promise.resolve('');
    }

    const parts = fileUrl.split('/files');
    const targetUrl = parts[1];
    const completeUrl = `/images${targetUrl}`;

    setImageUrls(prevState => ({
      ...prevState,
      [supportId]: completeUrl,
    }));

    // const params = {
    //   filePath: fileUrl,
    // };
    //
    // return getDownloadFile(params)
    //   .then(response => {
    //     const imageUrl = window.URL.createObjectURL(
    //       new Blob([response.data], {
    //         type: response.headers['content-type'],
    //       }),
    //     );
    //     setImageUrls(prevState => ({
    //       ...prevState,
    //       [supportId]: imageUrl,
    //     }));
    //     return imageUrl;
    //   })
    //   .catch(error => {
    //     return null;
    //   });
  };

  useEffect(() => {
    const loadInitialImages = () => {
      if (supportList.length > 0) {
        supportList.forEach(support => {
          fetchImage(support.thumbFileUrl, support.boardIdx);
        });
      }
    };

    loadInitialImages();
  }, []);

  useEffect(() => {
    if (supportList.length > 0) {
      const newSupports = supportList.slice(-4);
      newSupports.forEach(support => {
        if (!(support.boardIdx in imageUrls)) {
          fetchImage(support.thumbFileUrl, support.boardIdx);
        }
      });
    }
  }, [supportList]);

  return (
    <>
      <Filter
        title="Support Business"
        mainMenu="지원사업"
        subMenu={RouterPath.supportLead}
        isSearch={false}
      />

      <section>
        <div className="container">
          <div className="center_title">
            <p>데이터 경제 기반의 디지털 전환 생태계 조성</p>
            <div className="divider_title" />
            <div className="company_txt_001">
              DIVE 2024 등 글로벌 해커톤 및 부트캠프, 디지털(DX) 챌린지 등
              행사를 통한 부산지역 데이터 생태계 조성확산
            </div>
          </div>

          {supportList.map((support, index) => (
            <SupportItem
              key={support.boardIdx}
              support={support}
              imageUrls={imageUrls}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default SupportLead;
