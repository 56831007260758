import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RouterPath } from '../common';
import Layout from './Layout';

// 페이지 컴포넌트 임포트
import Error from '../pages/Errors';
// import Landing from '../pages/HomeGuide';
// eslint-disable-next-line import/extensions,import/no-duplicates
import BigdataList from '../pages/Bigdata/DataList';
import BigdataDetail from '../pages/Bigdata/Detail';
import DataLab from '../pages/Bigdata/DataLab';
import Operation from '../pages/Bigdata/Operation';
import Analysis from '../pages/Bigdata/Analysis';
import Application from '../pages/Bigdata/Application';
import Pledge from '../pages/Bigdata/Pledge';
import DataApply from '../pages/Bigdata/DataApply';
import AppUseList from '../pages/Bigdata/AppUseList';

import SupportInnovation from '../pages/support/SupportInnovation';
import SupportCollabo from '../pages/support/SupportCollabo';
import SupportLead from '../pages/support/SupportLead';

import NoticeList from '../pages/board/NoticeList';
import NewsList from '../pages/board/NewsletterList';
import QnaList from '../pages/board/QAList';
import BusinessList from '../pages/board/BusinessList';
import PhotoNewsList from '../pages/board/PhotoNewsList';
import NoticeDetail from '../pages/board/NoticeDetail';
import NewsDetail from '../pages/board/NewsletterDetail';
import QnaDetail from '../pages/board/QADetail';
import BusinessDetail from '../pages/board/BusinessDetail';
import PhotoNewsDetail from '../pages/board/PhotoNewsDetail';
import CasePage from '../pages/useCase/CasePage';
import SupportPractice from '../pages/support/SupportPractice';
import NewsletterSubscribe from '../pages/board/NewsletterSubscribe';

import QnaWrite from '../pages/board/QAWrite';
import BusinessApply from '../pages/board/BusinessApply';

import ReferenceList from '../pages/useCase/ReferenceRoom';
import ReferenceDetail from '../pages/useCase/ReferenceRoomDetail';
import SupportDetail from '../pages/support/SupportDetail';
import ReportList from '../pages/board/ReportList';

import Login from '../pages/UserAuth/Login';
import FindID from '../pages/UserAuth/FindID';
import Register from '../pages/UserAuth/Register';
import UserModify from '../pages/UserAuth/EditMember';
import ChangePassword from '../pages/UserAuth/ChangePassword';
import TermCondition from '../pages/UserAuth/TermCondition';
import EmailCert from '../pages/UserAuth/EmailCert';

import PrivacyPolicy from '../pages/UserAuth/PrivacyPolicy';
import TermUse from '../pages/UserAuth/TermOfUse';
import ReportDetail from '../pages/board/ReportDetail';
import CenterGuide from '../pages/CenterGuide';
import FacilityGuide from '../pages/FacilityGuide';
import SpaceGuide from '../pages/SpaceGuide';
import ChartGuide from '../pages/ChartGuide';
import MapGuide from '../pages/MapGuide';
import Home from '../pages/Home';
import CaseDetail from '../pages/useCase/CaseDetail';

const routeConfig = [
  {
    path: RouterPath.slash,
    element: <Home />,
  },
  {
    path: RouterPath.login,
    element: <Login />,
  },
  {
    path: RouterPath.findId,
    element: <FindID />,
  },
  {
    path: RouterPath.register,
    element: <Register />,
  },
  {
    path: RouterPath.emailCert,
    element: <EmailCert />,
  },
  {
    path: RouterPath.userModify,
    element: <UserModify />,
  },
  {
    path: RouterPath.changePassword,
    element: <ChangePassword />,
  },

  {
    path: RouterPath.termCondition,
    element: <TermCondition />,
  },
  {
    path: RouterPath.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  {
    path: RouterPath.termUse,
    element: <TermUse />,
  },
  {
    path: RouterPath.centerGuide,
    element: <CenterGuide />,
  },
  {
    path: RouterPath.spaceGuide,
    element: <SpaceGuide />,
  },
  {
    path: RouterPath.facilityGuide,
    element: <FacilityGuide />,
  },
  {
    path: RouterPath.chartGuide,
    element: <ChartGuide />,
  },
  {
    path: RouterPath.mapGuide,
    element: <MapGuide />,
  },
  {
    path: RouterPath.introDataLab,
    element: <DataLab />,
  },

  {
    path: RouterPath.bigdataList,
    element: <BigdataList />,
  },

  {
    path: `${RouterPath.bigdataDetail}/:dataIdx`,
    element: <BigdataDetail />,
  },

  {
    path: RouterPath.operation,
    element: <Operation />,
  },
  {
    path: RouterPath.analysis,
    element: <Analysis />,
  },
  {
    path: RouterPath.application,
    element: <Application />,
  },
  {
    path: RouterPath.pledge,
    element: <Pledge />,
  },
  {
    path: RouterPath.dataApply,
    element: <DataApply />,
  },
  {
    path: RouterPath.appuselist,
    element: <AppUseList />,
  },
  {
    path: RouterPath.supportLead,
    element: <SupportLead />,
  },
  {
    path: RouterPath.supportCollabo,
    element: <SupportCollabo />,
  },
  {
    path: RouterPath.supportPractice,
    element: <SupportPractice />,
  },
  {
    path: RouterPath.supportInnovation,
    element: <SupportInnovation />,
  },
  {
    path: `${RouterPath.supportDetail}/:boardIdx`,
    element: <SupportDetail />,
  },
  {
    path: RouterPath.noticeList,
    element: <NoticeList />,
  },
  {
    path: `${RouterPath.noticeDetail}/:boardIdx`,
    element: <NoticeDetail />,
  },
  {
    path: RouterPath.photoNewsList,
    element: <PhotoNewsList />,
  },
  {
    path: `${RouterPath.photoNewsDetail}/:boardIdx`,
    element: <PhotoNewsDetail />,
  },
  {
    path: RouterPath.newsList,
    element: <NewsList />,
  },
  {
    path: `${RouterPath.newsDetail}/:boardIdx`,
    element: <NewsDetail />,
  },
  {
    path: RouterPath.subscribeNewsletter,
    element: <NewsletterSubscribe />,
  },
  {
    path: RouterPath.qnaList,
    element: <QnaList />,
  },
  {
    path: RouterPath.qnaDetail,
    element: <QnaDetail />,
  },
  {
    path: RouterPath.qnaWrite,
    element: <QnaWrite />,
  },
  {
    path: RouterPath.businessList,
    element: <BusinessList />,
  },
  {
    path: `${RouterPath.businessDetail}/:boardIdx`,
    element: <BusinessDetail />,
  },
  {
    path: RouterPath.businessApply,
    element: <BusinessApply />,
  },
  {
    path: RouterPath.useCase,
    element: <CasePage />,
  },
  {
    path: `${RouterPath.useCaseDetail}/:boardIdx`,
    element: <CaseDetail />,
  },
  {
    path: RouterPath.referenceList,
    element: <ReferenceList />,
  },
  {
    path: `${RouterPath.referenceDetail}/:boardIdx`,
    element: <ReferenceDetail />,
  },
  {
    path: RouterPath.reportList,
    element: <ReportList />,
  },
  {
    path: `${RouterPath.reportDetail}/:boardIdx`,
    element: <ReportDetail />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

function Router() {
  const authInfo =
    localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth'));
  // console.log('auth information ::::: ', authInfo);
  return (
    <Routes>
      {authInfo && !authInfo.emailCert ? (
        <>
          <Route
            key="*"
            path="*"
            element={
              <Layout>
                <Navigate to={RouterPath.emailCert} />
              </Layout>
            }
          />
          <Route
            key={RouterPath.emailCert}
            path={RouterPath.emailCert}
            element={
              <Layout>
                <EmailCert />
              </Layout>
            }
          />
        </>
      ) : (
        routeConfig.map(({ path, element, auth = false }) => {
          return (
            <Route
              key={path}
              path={path}
              element={<Layout>{element}</Layout>}
            />
          );
        })
      )}
    </Routes>
  );
}

export default Router;
