import React, { useState } from 'react';
import { getDownloadFile } from '../../api/RestAPI';
import CustomSwal from '../CustomSwal';

function FileDownloadLocalText({ fileNm, fileUrl, hideFileName }) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    setIsDownloading(true);

    const params = {
      filePath: fileUrl,
    };

    getDownloadFile(params)
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileNm;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setIsDownloading(false);
      })
      .catch(error => {
        CustomSwal.fire({
          title: '알림',
          text: '파일 다운로드에 실패했습니다.',
        });
        setIsDownloading(false);
      });
  };

  return (
    <span
      style={{
        height: '46px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={handleDownload}
      disabled={isDownloading}
    >
      {isDownloading ? '다운로드 중...' : hideFileName ? '다운로드' : fileNm}
    </span>
  );
}

export default FileDownloadLocalText;
