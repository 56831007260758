import React, { useState } from 'react';
import Findid from '../../components/Findid';
import FindPw from '../../components/FindPw';

function FindID() {
  const [tapActive, setTapActive] = useState(0);

  return (
    <>
      <section className="main_ct">
        <div className="container">
          <div className="search_wg">
            <div className="copy_search_txt">
              <p>회원 서비스</p>
              <p className="copy_search_txt_001">Member Service</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg_login">
        <div className="container">
          <div style={{ margin: 0 }} className="center_title">
            <p>아이디/비밀번호 찾기</p>
            <div className="divider_title" />
          </div>

          <div className="box_login">
            <div className="tap_box">
              <button
                type="button"
                onClick={() => setTapActive(0)}
                className={`tap_find ${tapActive === 0 ? 'active_tap' : ''}`}
              >
                아이디 찾기
              </button>

              <button
                type="button"
                onClick={() => setTapActive(1)}
                className={`tap_find ${tapActive === 1 ? 'active_tap' : ''}`}
              >
                비밀번호 찾기
              </button>
            </div>

            {tapActive === 0 ? <Findid /> : <FindPw />}
          </div>
        </div>
      </section>
    </>
  );
}

export default FindID;
