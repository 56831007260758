import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { useNavigate } from 'react-router-dom';
import { Utils } from '../common';
import { RouterPath } from '../common/constants';

function BannerSlide04({ useCaseList, useCaseImageList }) {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (useCaseImageList && sliderRef.current) {
      const swiper = new Swiper(sliderRef.current, {
        slidesPerView: 2,
        spaceBetween: 200,
        speed: 500,
        loop: true,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next04',
          prevEl: '.swiper-button-prev04',
        },
        breakpoints: {
          1024: { slidesPerView: 1 },
          765: { slidesPerView: 1 },
          640: { slidesPerView: 1 },
          568: { slidesPerView: 1 },
          465: { slidesPerView: 1 },
          0: { slidesPerView: 1 },
        },
      });

      return () => {
        swiper.destroy();
      };
    }
  }, [useCaseList]);

  return (
    <div className="slide_type04">
      <div ref={sliderRef} className="slider_item04">
        <div className="swiper-wrapper">
          {useCaseList &&
            useCaseList.length > 0 &&
            useCaseList.map((useCase, index) => (
              <div className="swiper-slide" key={`useCase_list${index}`}>
                <div className="item">
                  <div className="itemData">
                    <img
                      className="newsImageData"
                      src={useCaseImageList[useCase.thumbFileUrl]}
                      alt="Placeholder"
                    />
                    <div className="contentData">
                      <div>활용사례</div>
                      <h2>{useCase.useCaseTitle}</h2>
                      <p>
                        {Utils.shortenText(
                          Utils.stripHtmlTags(useCase.useCaseBody),
                          75,
                        )}
                      </p>
                      <button
                        type="button"
                        onClick={() =>
                          navigate(
                            `${RouterPath.useCaseDetail}/${useCase.boardIdx}`,
                          )
                        }
                      >
                        자세히 보기
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Navigation Buttons */}
      <div className="swiper-button-next swiper-button-next04" />
      <div className="swiper-button-prev swiper-button-prev04" />
    </div>
  );
}

export default BannerSlide04;
