import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter';
import { RouterPath } from '../../common/constants';
import CustomSwal from '../../components/CustomSwal';
import { apiCheckBusinessApply, apiSaveBusinessApply } from '../../api/RestAPI';
import NullCheckUtils from '../../utils/NullCheckUtils';
import { handleError } from '../../utils/HandleError';
import { images } from '../../assets';

function Upload(props) {
  return null;
}

function BusinessApply() {
  const navigate = useNavigate();
  const location = useLocation();

  const businessIdx = location.state ? location.state.businessIdx : 1;
  const businessName = location.state ? location.state.businessName : '';

  const allowedExtensions = ['hwp', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx'];
  const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB
  const storageToken = JSON.parse(localStorage.getItem('auth'));

  const [fileList, setFileList] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [managerName, setManagerName] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!businessIdx || !businessName) {
      CustomSwal.fire({
        title: '확인',
        text: '잘못된 접근입니다.',
        then: () => {
          navigate(-1);
        },
      });
    }
  }, []);

  const handleChangeUpload = e => {
    const { files } = e.target;
    if (!files) return;

    const newFileList = [...fileList];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name, size } = file;
      const fileExtension = name.split('.').pop().toLowerCase();

      if (newFileList.length >= 5) {
        CustomSwal.fire({
          title: '확인',
          html: `파일 갯수는 5개까지 허용됩니다.<br/>5개 파일만 리스트에 등록합니다.`,
        });
        break;
      }

      if (size > MAX_FILE_SIZE) {
        CustomSwal.fire({
          title: '확인',
          text: '파일 크기는 최대 30MB까지 허용됩니다.',
        });
        return;
      }

      if (!allowedExtensions.includes(fileExtension)) {
        CustomSwal.fire({
          title: '확인',
          html: `허용되지 않은 파일 형식입니다.<br/>(허용된 형식: xls, xlsx, pdf, 이미지 파일)`,
        });
        return;
      }
      newFileList.push(file);
    }

    setFileList([...newFileList]);
    e.target.value = null;
  };

  const handleCloseClick = file => {
    // console.log('deleting file :::: ', file);
    setFileList(prev => prev.filter(item => item !== file));
  };

  const saveBusinessApply = () => {
    const checkField = [
      { state: companyName, message: '신청기업명을 입력해주세요.' },
      { state: fileList, message: '하나 이상의 파일을 업로드해주세요.' },
    ];

    if (NullCheckUtils.isNullAndDisplaySwal(checkField)) {
      return;
    }

    if (!isChecked) {
      CustomSwal.fire({
        title: '확인',
        text: '개인정보취급방침에 동의하셔야 합니다.',
      });
      return;
    }

    const data = {
      businessIdx,
      companyName: companyName || null,
      managerName: managerName || null,
      email: email || null,
      telNo: tel || null,
    };
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob([JSON.stringify(data)], { type: 'application/json' }),
    );

    if (fileList.length > 0)
      fileList.forEach((file, index) => {
        formData.append(`files`, file);
      });

    CustomSwal.fire({
      title: '해당 내용으로 신청하시겠습니까?',
      cancel: true,
      then: () => {
        apiSaveBusinessApply(formData)
          .then(response => {
            CustomSwal.fire({
              title: '알림',
              text: '신청되었습니다.',
              finally: () => {
                navigate(-1);
              },
            });
          })
          .catch(error => {
            handleError(error);
          });
      },
    });
  };

  useEffect(() => {
    if (
      !(
        storageToken &&
        storageToken.accessToken &&
        storageToken.grade === 'USER'
      )
    )
      navigate(RouterPath.slash, { replace: true });
  }, [storageToken]);

  const checkApply = () => {
    apiCheckBusinessApply(businessIdx)
      .then(response => {})
      .catch(error => {
        handleError(error);
        navigate(-1);
      });
  };

  useEffect(() => {
    if (businessIdx) checkApply();
  }, []);

  return (
    <>
      <Filter
        title="Notice Board"
        mainMenu="알림마당"
        subMenu={RouterPath.businessList}
        isSearch={false}
      />
      <section>
        <div className="container">
          <div className="center_title">
            <p>사업공고 지원신청</p>
            <div className="divider_title" />
          </div>
          <div className="form_box" style={{ padding: '72px 77px' }}>
            <div className="txt_spt_001">
              <p>개인정보 수집 및 이용 동의서</p>
              <p>
                부산 빅데이터혁신센터는 지원사업 신청과 관련하여 아래 사항과
                같이 개인정보를 수집 및 이용하고자 합니다. 내용을 자세히 읽으신
                후 동의 여부를 결정하여 주시기 바랍니다.
              </p>
              <p>1. 개인정보의 수집 및 이용 목적</p>
              <ul>
                <li>지원사업 신청의 처리 및 관리</li>
                <li>지원사업 관련 통지 및 안내</li>
                <li>지원사업 참가자의 성명, 연락처 등의 확인 및 관리</li>
                <li>법적 요구사항 준수</li>
              </ul>
            </div>
            <div className="input_radio_check">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="check_01">
                개인정보취급방침 내용을 읽었으며 동의합니다.
              </label>
              <input
                id="check_01"
                type="checkbox"
                checked={isChecked}
                onChange={e => setIsChecked(e.target.checked)}
              />
            </div>
            <div className="flex align-center">
              <span className="label_inp">공고명</span>
              <div className="pre_001">{businessName}</div>
            </div>
            <div className="flex align-center">
              <span className="label_inp">신청기업명</span>
              <input
                type="text"
                className="inp_id"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </div>
            <div className="flex align-center">
              <span className="label_inp">담당자명</span>
              <input
                type="text"
                className="inp_id"
                value={managerName}
                onChange={e => setManagerName(e.target.value)}
              />
            </div>
            <div className="flex align-center">
              <span className="label_inp">연락처</span>
              <input
                type="text"
                className="inp_id"
                value={tel}
                onChange={e => setTel(e.target.value)}
              />
            </div>
            <div className="flex align-center">
              <span className="label_inp">E-mail</span>
              <input
                type="text"
                className="inp_id"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="flex" style={{ margin: 0, alignItems: 'baseline' }}>
              <span className="label_inp">신청서 첨부</span>
              <div className="flex-column">
                <div className="box_clone box_clone_2500" style={{ margin: 0 }}>
                  {fileList &&
                    fileList.length > 0 &&
                    fileList?.map((file, index) => (
                      <div
                        key={`upload_file_${file.name}_${file.lastModified}_${index}`}
                        className="file_upload"
                      >
                        <img src={images.file} alt="file" />
                        {file.name}
                        <img
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleCloseClick(file)}
                          src={images.iconClose}
                          alt="close"
                        />
                      </div>
                    ))}
                  <div
                    className="flex align-center inp_upload_2500"
                    style={{ columnGap: '1rem', margin: 0 }}
                  >
                    <div
                      className="inp_upload selector"
                      onClick={() =>
                        document.getElementById('inp_file_2500').click()
                      }
                    >
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="inp_file_2500">파일선택</label>
                      <input
                        onChange={e => handleChangeUpload(e)}
                        id="inp_file_2500"
                        hidden
                        type="file"
                        multiple
                      />
                    </div>

                    <span className="pre_001">
                      확장자 xls, xlsx, pdf 파일 및 이미지 파일을 업로드
                      해주세요. <br /> 최대 파일 5개까지 첨부 가능합니다. <br />{' '}
                      최대 30MB
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: 233 }} className="edit_btn">
            <button
              type="button"
              style={{ width: 117 }}
              className="btn_login"
              onClick={() => saveBusinessApply()}
            >
              신청
            </button>

            <button
              type="button"
              className="btn_login"
              onClick={() => navigate(-1)}
            >
              취소
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default BusinessApply;
