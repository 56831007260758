import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TermCondition from './TermCondition';
import SignUp from './SignUp';

function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const certification = location?.state;
  return (
    <>
      {!certification && <TermCondition />}
      {certification && <SignUp />}
    </>
  );
}

export default Register;
